import { Box, CircularProgress, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { s3baseUrl } from "src/config/config";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { UPCommingEventsAll } from "src/DAL/MissionLevels/MissionLevels";
import { handle_change_page } from "src/utils/constants";
import { htmlDecode } from "src/utils/convertHtml";
import { useFirebase } from "src/Hooks/FirebaseContaxt";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function UpComingEventsAllScreen() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { logPageOnFirebase } = useFirebase();
  const [isLoading, setIsLoading] = useState(true);
  const [upcoming_events_array, set_upcoming_events_array] = useState([]);

  const UPCommingEventsAllList = async () => {
    const result = await UPCommingEventsAll();
    if (result.code === 200) {
      set_upcoming_events_array(result.data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    logPageOnFirebase({ event: "mcUpcomingEvents" });
    UPCommingEventsAllList();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container mission-levels mb-3">
      <div className="d-flex mb-2">
        <span>
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(`/mission-control`)}
          >
            <ArrowBackIcon />
          </IconButton>
        </span>

        <h2 style={{ fontSize: "500" }}>Upcoming Events</h2>
      </div>{" "}
      <div
        className={`profile-cards  community-card-title`}
        style={{
          background: "transparent",
        }}
      >
        <div className="row">
          {upcoming_events_array?.map((upcoming_events, index) => (
            <div className="col-lg-4 col-md-4 col-sm-6 mb-3" key={index}>
              <div
                className="card h-100"
                style={{
                  position: "relative",
                  paddingBottom: "50px",
                }}
              >
                <img
                  className="card-img-top"
                  src={
                    s3baseUrl +
                    upcoming_events?.mission_control_settings?.banner_image
                  }
                  alt="event"
                  // style={{ maxHeight: "150px", objectFit: "cover" }}
                  onClick={() =>
                    navigate(`/mission-control/Upcoming-events/detail`, {
                      state: { upcoming_events },
                    })
                  }
                />

                <div className="card-body pt-2">
                  <p className="card-text mb-1 upcomingTitile">
                    {htmlDecode(
                      upcoming_events?.mission_control_settings?.title
                    )}
                  </p>
                  <p
                    className="card-title upcomingdes"
                    dangerouslySetInnerHTML={{
                      __html:
                        upcoming_events?.mission_control_settings
                          ?.short_description,
                    }}
                  ></p>
                  <Box
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      position: "absolute",
                      bottom: "10px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      width: "90%",
                    }}
                  >
                    <button
                      className="small-contained-button text-nowrap"
                      disabled={upcoming_events.is_already_purchase}
                      style={{
                        width: "100%",
                        padding: "10px 15px",
                        fontSize: "14px",
                      }}
                      onClick={
                        upcoming_events.is_already_purchase
                          ? undefined
                          : () => handle_change_page(upcoming_events.page_url)
                      }
                    >
                      {upcoming_events.is_already_purchase
                        ? "Already Subscribed"
                        : htmlDecode(
                            upcoming_events?.mission_control_settings
                              ?.button_text
                          )}
                    </button>
                  </Box>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
