import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { TextField } from "@mui/material";

export default function MuiTimePicker(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        renderInput={(params) => (
          <TextField {...params} required={true} size="small" />
        )}
        {...props}
      />
    </LocalizationProvider>
  );
}
