import { Avatar } from "@mui/material";
import React from "react";

const CustomAvatarBadgeColor = ({ alt, src, colorCode, width, height }) => {
  return (
    <>
      <Avatar
        className="avatar-adjust"
        sx={{
          width: width ?? 40,
          height: height ?? 40,
          border: `3px solid ${colorCode}`,
        }}
        alt={alt}
        src={src}
      />
    </>
  );
};

export default CustomAvatarBadgeColor;
