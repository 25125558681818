import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { MomentDetailApi } from "src/DAL/Memories/Memories";
import { CircularProgress, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import GoalGallerySlider from "src/components/GoalStatement/GoalGallerySlider";
import { useFirebase } from "src/Hooks/FirebaseContaxt";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function MemoriesDetail() {
  const navigate = useNavigate();
  const params = useParams();
  const { logPageOnFirebase } = useFirebase();
  const [memoriesData, setMemoriesData] = useState([]);
  const [sliderData, setSliderData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const getMemoryDetail = async () => {
    const result = await MomentDetailApi(params.memory_slug);
    if (result.code === 200) {
      setMemoriesData(result.magicMoment);
      let galleryArray = [];
      let galleryObject = {};
      result.magicMoment.moment_image.map((gallery, index) => {
        galleryObject = {
          original: s3baseUrl + gallery.thumbnail_1,
          thumbnail: s3baseUrl + gallery.thumbnail_2,
        };
        galleryArray.push(galleryObject);
      });
      setSliderData(galleryArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/memories`);
    }
  };

  useEffect(() => {
    logPageOnFirebase({ event: "magicMemoryDetail" });
    getMemoryDetail();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate("/memories")}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row section-space">
        <div className="col-sm-12 col-md-8 col-lg-10">
          <h1>{htmlDecode(memoriesData.moment_title)}</h1>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-2 memories-date">
          <p className="text-md-end">{memoriesData.moment_date}</p>
        </div>
      </div>
      <div className="row media-margin">
        <div className="col-12">
          <GoalGallerySlider data={sliderData} />
        </div>
        <div className="col-12 section-space">
          <p>{htmlDecode(memoriesData.moment_description)}</p>
        </div>
      </div>
    </div>
  );
}
