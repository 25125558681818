import { Checkbox, CircularProgress, FormControlLabel } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import AttitudeAssessmentQuestions from "src/components/AttitudeAssessment/AttitudeAssessmentQuestions";
import {
  _get_member_attitude_assessment,
  _update_attitude_assessment_result,
} from "src/DAL/AttitudeAssessment/AttitudeAssessment";
import { ReactVideoPlayer } from "src/components";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import AttitudeAssessmentFiller from "src/components/AttitudeAssessment/AttitudeAssessmentFiller";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import { cointlogo } from "src/assets";
import { dd_date_format } from "src/utils/constants";
import { firebaseEventsObj } from "src/utils/firebase_utils";
import { useFirebase } from "src/Hooks/FirebaseContaxt";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function AttitudeAssessment() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { logOnFirebase } = useFirebase();
  const [isLoading, setIsLoading] = useState(true);
  const [allowAbleStatus, setAllowAbleStatus] = useState(true);
  const [isCompleted, setIsCompleted] = useState(false);
  const [hideVideo, setHideVideo] = useState(
    localStorage.getItem("hide_attitude_assessment_video")
  );
  const [activeSection, setActiveSection] = useState(1);

  const [thoughtsList, setThoughtsList] = useState([]);
  const [thoughtsTotalMarks, setThoughtsTotalMarks] = useState(0);
  const [thoughtsGainedMarks, setThoughtsGainedMarks] = useState(0);

  const [feelingList, setFeelingList] = useState([]);
  const [feelingsTotalMarks, setFeelingsTotalMarks] = useState(0);
  const [feelingsGainedMarks, setFeelingsGainedMarks] = useState(0);

  const [actionsList, setActionsList] = useState([]);
  const [actionsTotalMarks, setActionsTotalMarks] = useState(0);
  const [actionsGainedMarks, setActionsGainedMarks] = useState(0);
  const [coinsCount, setCoinsCount] = useState(0);

  const [assessmentSetting, setAssessmentSetting] = useState({});
  const { contentSettingData, userInfo } = useContentSetting();
  const [openHide, setOpenHide] = useState(false);

  const handleLogFirebase = (tab) => {
    logOnFirebase({
      event: firebaseEventsObj.attitudeAssessmentTab,
      replacementArr: [["{tab_title}", tab]],
    });
  };

  const handleThoughtsChange = () => {
    const thoughts_list_with_no_answer = thoughtsList.filter(
      (list) => !list.answer
    );
    if (thoughts_list_with_no_answer.length > 0) {
      enqueueSnackbar("Please answer all the questions", { variant: "error" });
    } else {
      handleLogFirebase(assessmentSetting.feeling_info.button_title);
      setActiveSection(2);
    }
  };

  const handleFeelingsChange = () => {
    const feelings_list_with_no_answer = feelingList.filter(
      (list) => !list.answer
    );
    if (feelings_list_with_no_answer.length > 0) {
      enqueueSnackbar("Please answer all the questions", { variant: "error" });
    } else {
      handleLogFirebase(assessmentSetting.action_info.button_title);
      setActiveSection(3);
    }
  };

  const getAttitudeAssessmentSetting = async () => {
    const result = await _get_member_attitude_assessment();
    if (result.code === 200) {
      setThoughtsList(result.thought_attitude_assessment_array);
      setFeelingList(result.feeling_attitude_assessment_array);
      setActionsList(result.action_attitude_assessment_array);
      setAllowAbleStatus(result.allowable_status);
      setIsCompleted(result.is_completed_attitude_assessment);
      if (result.allowable_status && !result.is_completed_attitude_assessment) {
        handleLogFirebase(result.assessment_setting.thought_info.button_title);
      }
      setCoinsCount(result.attitude_assessment_coins_count);
      // countning all marks in every question
      const total_thought_attitude_marks =
        result.thought_attitude_assessment_array.reduce(
          (total, obj) => obj.max + total,
          0
        );
      setThoughtsTotalMarks(total_thought_attitude_marks);
      const total_feeling_attitude_marks =
        result.feeling_attitude_assessment_array.reduce(
          (total, obj) => obj.max + total,
          0
        );
      setFeelingsTotalMarks(total_feeling_attitude_marks);
      const total_action_attitude_marks =
        result.action_attitude_assessment_array.reduce(
          (total, obj) => obj.max + total,
          0
        );
      setActionsTotalMarks(total_action_attitude_marks);

      setAssessmentSetting(result.assessment_setting);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAnswerThoughts = (index, value) => {
    let temp_state = [...thoughtsList];
    let temp_element = { ...temp_state[index] };
    temp_element.answer = value;
    temp_state[index] = temp_element;
    setThoughtsList(temp_state);
  };
  const handleAnswerFeelings = (index, value) => {
    let temp_state = [...feelingList];
    let temp_element = { ...temp_state[index] };
    temp_element.answer = value;
    temp_state[index] = temp_element;
    setFeelingList(temp_state);
  };
  const handleAnswerActions = (index, value) => {
    let temp_state = [...actionsList];
    let temp_element = { ...temp_state[index] };
    temp_element.answer = value;
    temp_state[index] = temp_element;
    setActionsList(temp_state);
  };

  const handleSubmit = async () => {
    const actions_list_with_no_answer = actionsList.filter(
      (list) => !list.answer
    );
    if (actions_list_with_no_answer.length > 0) {
      enqueueSnackbar("Please answer all the questions", { variant: "error" });
    } else {
      setIsLoading(true);
      let total_scores =
        thoughtsTotalMarks + feelingsTotalMarks + actionsTotalMarks;
      let get_scores =
        thoughtsGainedMarks + feelingsGainedMarks + actionsGainedMarks;

      const percentage = (get_scores / total_scores) * 100;
      let active_result = "slot_3";
      if (percentage <= assessmentSetting.general_info.slot_1_percentage) {
        active_result = "slot_1";
      } else if (
        percentage <= assessmentSetting.general_info.slot_2_percentage &&
        percentage > assessmentSetting.general_info.slot_1_percentage
      ) {
        active_result = "slot_2";
      }

      const postData = {
        assessment_results: {
          thought_result: thoughtsList,
          feeling_result: feelingList,
          action_result: actionsList,
          attitude_assessment_result: active_result,
        },
      };

      const result = await _update_attitude_assessment_result(postData);
      if (result.code === 200) {
        logOnFirebase({
          event: firebaseEventsObj.attitudeAssessmentCompleted,
          replacementArr: [["{slot}", active_result]],
        });
        enqueueSnackbar(result.message, { variant: "success" });
        navigate(`/assessment/results`, {
          state: {
            active_result: active_result,
            assessmentSetting: assessmentSetting,
            percentage: percentage,
            get_scores: get_scores,
            total_scores: total_scores,
            thoughtsGainedMarks,
            thoughtsTotalMarks,
            feelingsGainedMarks,
            feelingsTotalMarks,
            actionsGainedMarks,
            actionsTotalMarks,
            attitudeAssessmentCoinsCount:
              coinsCount +
              result.attitude_assessment_activity
                .attitude_assessment_coins_count,
          },
        });
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const handleAgreeHide = (value) => {
    setOpenHide(true);
  };

  const handleHideVideo = () => {
    localStorage.setItem("hide_attitude_assessment_video", true);
    setHideVideo(true);
    setOpenHide(false);
  };
  const handleShowVideo = () => {
    localStorage.setItem("hide_attitude_assessment_video", false);
    setHideVideo(false);
  };
  const replacedString = (string, unlock_date) => {
    if (!string) return;
    return string.replace(
      /{upcoming_assessment_date}/g,
      dd_date_format(unlock_date)
    );
  };

  useEffect(() => {
    getAttitudeAssessmentSetting();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="assessment-wrapper">
        {isCompleted ? (
          <>
            <div
              className="dashboard_description"
              dangerouslySetInnerHTML={{
                __html: replacedString(
                  assessmentSetting.general_info.already_completed_description,
                  userInfo.upcomming_attitude_assessment_date
                ),
              }}
            ></div>
          </>
        ) : allowAbleStatus ? (
          <>
            <div className="container">
              <div className="text-start pt-4 assessment-wrapper-description">
                <div className="row">
                  <div className="col-6">
                    <div
                      className="dashboard_description"
                      dangerouslySetInnerHTML={{
                        __html:
                          contentSettingData.attitude_assessment_description,
                      }}
                    ></div>
                  </div>

                  <div className="col-6">
                    <div className="d-flex text-align vision-div align-items-center">
                      <img
                        className="coint-img img-fluid"
                        src={cointlogo}
                      ></img>
                      <h4>Total Coins Attracted: {coinsCount}</h4>
                      {(hideVideo == true || hideVideo == "true") && (
                        <button
                          className="small-contained-button float-end mt-1 ms-4"
                          onClick={handleShowVideo}
                        >
                          Show Video
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {hideVideo == true || hideVideo == "true" ? (
                <div className="d-flex mt-5 pull-right position-relative z-index-10">
                  <button
                    className={`assesment-tab-btn ${
                      activeSection === 1 ? "btn-active btn-position" : ""
                    }`}
                  >
                    {assessmentSetting.thought_info.button_title}
                  </button>
                  <button
                    className={`assesment-tab-btn mx-md-4 mx-3 ${
                      activeSection === 2 ? "btn-active" : ""
                    }`}
                  >
                    {assessmentSetting.feeling_info.button_title}
                  </button>
                  <button
                    className={`assesment-tab-btn ${
                      activeSection === 3 ? "btn-active" : ""
                    }`}
                  >
                    {assessmentSetting.action_info.button_title}
                  </button>
                </div>
              ) : (
                <div className="row pt-70" id="assessment-container">
                  <div className="col-lg-6 col-md-6">
                    <ReactVideoPlayer
                      url={assessmentSetting.thought_info.video_url}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox onClick={handleAgreeHide} checked={false} />
                      }
                      label="Don't Show me this video again"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-md-start text-center mt-md-0 mt-4">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: assessmentSetting.thought_info.description,
                      }}
                      className="assetssment-para"
                    ></div>
                    <div className="d-flex mt-5 pull-left position-relative z-index-10">
                      <button
                        className={`assesment-tab-btn ${
                          activeSection === 1 ? "btn-active btn-position" : ""
                        }`}
                      >
                        {assessmentSetting.thought_info.button_title}
                      </button>
                      <button
                        className={`assesment-tab-btn mx-md-4 mx-3 ${
                          activeSection === 2 ? "btn-active" : ""
                        }`}
                      >
                        {assessmentSetting.feeling_info.button_title}
                      </button>
                      <button
                        className={`assesment-tab-btn ${
                          activeSection === 3 ? "btn-active" : ""
                        }`}
                      >
                        {assessmentSetting.action_info.button_title}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="container pt-120">
              <div className="row justify-content-center">
                <AttitudeAssessmentFiller
                  thoughtsTotalMarks={thoughtsTotalMarks}
                  thoughtsGainedMarks={thoughtsGainedMarks}
                  feelingsTotalMarks={feelingsTotalMarks}
                  feelingsGainedMarks={feelingsGainedMarks}
                  actionsTotalMarks={actionsTotalMarks}
                  actionsGainedMarks={actionsGainedMarks}
                />
                {activeSection === 1 ? (
                  <AttitudeAssessmentQuestions
                    data={thoughtsList}
                    assessmentSetting={assessmentSetting.thought_info}
                    activeSection={activeSection}
                    handleAnswer={handleAnswerThoughts}
                    buttonHandler={handleThoughtsChange}
                    setGainedMarks={setThoughtsGainedMarks}
                    gainedMarks={thoughtsGainedMarks}
                    totalMarks={thoughtsTotalMarks}
                  />
                ) : activeSection === 2 ? (
                  <AttitudeAssessmentQuestions
                    data={feelingList}
                    assessmentSetting={assessmentSetting.feeling_info}
                    activeSection={activeSection}
                    handleAnswer={handleAnswerFeelings}
                    buttonHandler={handleFeelingsChange}
                    setGainedMarks={setFeelingsGainedMarks}
                    gainedMarks={feelingsGainedMarks}
                    totalMarks={feelingsTotalMarks}
                  />
                ) : (
                  <AttitudeAssessmentQuestions
                    data={actionsList}
                    assessmentSetting={assessmentSetting.action_info}
                    activeSection={activeSection}
                    handleAnswer={handleAnswerActions}
                    buttonHandler={handleSubmit}
                    setGainedMarks={setActionsGainedMarks}
                    gainedMarks={actionsGainedMarks}
                    totalMarks={actionsTotalMarks}
                  />
                )}
              </div>
            </div>
          </>
        ) : (
          <div className="attitude-assessment-not-found">
            <RecordNotFound title="Attitude Assessment Not Found" />
          </div>
        )}
      </div>
      <CustomConfirmation
        open={openHide}
        setOpen={setOpenHide}
        title={"Are you sure you want to hide video ?"}
        handleAgree={handleHideVideo}
      />
    </>
  );
}

export default AttitudeAssessment;
