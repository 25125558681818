import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { s3baseUrl } from "../../config/config";
import { useSnackbar } from "notistack";
import { StudySessionRecordingDetailApi } from "../../DAL/Programmes/Programmes";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import ReactVideoDurationPlayer from "src/components/ReactPlayers/ReactVideoDurationPlayer";
import { useFirebase } from "src/Hooks/FirebaseContaxt";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function StudySessionRecordingDetail({ type }) {
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [recordingData, seRecordingData] = React.useState({});
  const [videoProgressData, setVideoProgressData] = useState([]);
  const { logPageOnFirebase } = useFirebase();

  const handleNavigate = () => {
    navigate(-1);
  };

  const updatePageTitle = (title) => {
    let firebaseVar = "studySessionRecordingsDetail";
    if (type === "program") {
      firebaseVar = "programStudySessionRecordingsDetail";
    }
    logPageOnFirebase({
      event: firebaseVar,
      replacementArr: [["{recording_title}", title]],
    });
  };

  const getSessionRecordingDetail = async () => {
    setIsLoading(true);
    const result = await StudySessionRecordingDetailApi(params.recording_slug);
    if (result.code === 200) {
      updatePageTitle(result.study_session_recording.title);
      let data = [
        {
          recording_id: result.study_session_recording?._id,
          video_duration: result.study_session_recording?.video_duration,
          total_video_duration:
            result.study_session_recording?.total_video_duration,
          is_complete: result.study_session_recording?.is_complete,
        },
      ];
      setVideoProgressData(data);
      seRecordingData(result.study_session_recording);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/programmes`);
    }
  };

  useEffect(() => {
    getSessionRecordingDetail();
  }, [params.recording_slug]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin ">
        <div className="col-12 d-flex">
          <IconButton
            className="back-screen-button"
            onClick={() => handleNavigate()}
          >
            <ArrowBackIcon />
          </IconButton>
          <h2 className="programmes-heading ms-2">
            {htmlDecode(recordingData.title)}
          </h2>
        </div>
      </div>

      <div className="row section-space">
        <div className="col-12"></div>
      </div>
      <div className="row media-margin">
        <div className="col-12">
          {recordingData.video_url ? (
            <ReactVideoDurationPlayer
              url={recordingData.video_url}
              type={"study_section_recording"}
              SaveInfo={"study_section_recording"}
              value={recordingData}
              setVideoProgressData={setVideoProgressData}
              videoProgressData={videoProgressData}
            />
          ) : (
            <img
              src={
                s3baseUrl + recordingData.session_recording_images.thumbnail_1
              }
              alt=""
              srcSet=""
            />
          )}
        </div>
        {recordingData.audio_recording && (
          <div className="col-12 section-space">
            <audio
              controlsList="nodownload"
              className="w-100"
              src={s3baseUrl + recordingData.audio_recording}
              controls
            />
          </div>
        )}
        <div className="col-12 section-space">
          <p
            dangerouslySetInnerHTML={{
              __html: recordingData.detailed_description,
            }}
          ></p>
        </div>
      </div>
    </div>
  );
}

export default StudySessionRecordingDetail;
