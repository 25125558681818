// here we add all base urls and keys

import { appLogoIcon } from "src/assets";
import { get_project_info_localStorage } from "src/DAL/localStorage/localStorage";
import { update_root_value } from "src/utils/domUtils";

// Base URLS
export const baseUri = process.env.REACT_APP_API_BASE_URL;
export const socketBaseUri = process.env.REACT_APP_API_SOCKET_BASE_URL;
export const s3baseUrl = process.env.REACT_APP_PIC_BASE_URL;
export const project_name = process.env.REACT_APP_PROJECT_NAME;
export const api_key = process.env.REACT_APP_WEB_API_KEY;
export const websiteBaseUrl = process.env.REACT_APP_WEBSITE_BASE_URL;
export const projectMode = process.env.REACT_APP_PROJECT_MODE;
export const delegateUrl = process.env.REACT_APP_DELEGATE_URL;
export const portalUrl = process.env.REACT_APP_PORTAL_URL;

const get_project_info = get_project_info_localStorage();

export const material_theme_palate = {
  lighter: "#d4bff2",
  light: "#ede7f6",
  main: "#1a93a9",
  dark: "#198BA8",
  darker: "#198BA8",
  contrastText: "#fff",
};

export const OwnerName = () => {
  return "Dynamite Response";
};

export const ownerName = OwnerName() + "'s";
export const ownerCompleteName = OwnerName();

export const get_app_logo = () => {
  if (get_project_info && get_project_info.brand_logo) {
    return s3baseUrl + get_project_info.brand_logo;
  } else {
    return appLogoIcon;
  }
};

export const get_app_headers = () => {
  if (get_project_info && get_project_info.meta_title) {
    return {
      title: get_project_info.meta_title,
      description: get_project_info.meta_description,
      fav_icon: s3baseUrl + get_project_info.brand_favicon,
    };
  } else {
    return {
      title: "Dynamite Lifestyle | Client",
      description: "Dynamite Lifestyle | Client",
      fav_icon: appLogoIcon,
    };
  }
};

material_theme_palate.main = "#ffd889";
/* Main theme color */
update_root_value("--portal-theme-primary", "#ffd889");
update_root_value(
  "--portal-theme-primary-filter-color",
  "invert(74%) sepia(96%) saturate(396%) hue-rotate(332deg) brightness(101%) contrast(93%)"
);

update_root_value("--portal-theme-secondary", "rgb(255 218 0 / 8%)");
update_root_value("--text-primary-color", "white");
update_root_value("--text-secondary-color", "rgb(150, 150, 150)");
/* Main theme color end */

/* Main background color*/
update_root_value("--background-primary-color", "black");
update_root_value("--background-secondary-color", "#1d1c1d");

/* Main background color end*/

/* Sidebar Properties */
update_root_value("--sidebars-text-color", "white");
update_root_value("--portal-theme-primary", "#ffd889");
update_root_value("--portal-theme-primary:", "#ffd889");
update_root_value("--sidebars-background-color", "#1d1c1d");
/* Sidebar Properties */

/* Popup / Popover properties  */
update_root_value("--popup-background-color", "#1d1c1d");
update_root_value("--popup-text-color", "white");
/* Popup / Popover properties end  */

/* Element properties */
update_root_value("--input-text-color", "#fff");
update_root_value("--input-placeholder-color", "#fff");
update_root_value("--input-background-primary", "#1d1c1d");
update_root_value("--input-background-secondary", "#1d1c1d");
update_root_value(" --portal-theme-primary", "#ffd889");
/* Element properties ends*/

/* Button Properties */

update_root_value("--button-background-color", "#1c1c1c");
update_root_value("--portal-theme-primary", "#ffd889");
update_root_value("--portal-theme-primary", "#ffd889");
update_root_value("--portal-theme-primary", "#ffd889");

/* Button Properties ends*/

/* Audio Properties */
update_root_value("--audio-color", "rgb(56, 54, 54)");
/* Audio Properties ends*/

/* calendar Properties */
update_root_value("--calendar-selected-box", "#8b8b8b");
update_root_value("--datepicker-selected-color", "#fff");
