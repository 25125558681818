import { Avatar, FormControlLabel, Switch } from "@mui/material";
import React from "react";
import { s3baseUrl } from "src/config/config";

export default function NotificationItem(props) {
  const { notification, inputs, setInputs } = props;

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  return (
    <>
      <div className="col-10 mt-4 d-flex">
        <Avatar alt="DD" src={s3baseUrl + notification.icon} />
        <div
          dangerouslySetInnerHTML={{
            __html: notification.description,
          }}
          className="ms-2 notification-description"
        ></div>
      </div>
      <div className="col-2 mt-4 text-end">
        <FormControlLabel
          control={
            <Switch
              checked={inputs[notification.notification_for]}
              name={notification.notification_for}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
        />
      </div>
    </>
  );
}
