import { createContext, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useSnackbar } from "notistack";
import {
  add_free_member_by_web,
  register_through_bank_api,
  pay_now_for_subscription_web_v1,
  confirm_subscription_incomplete_for_web_V1,
  get_web_intent_client_secret_for_one_time_v1,
  confirm_one_time_payment_for_web_v1,
  show_browser_consoles_on_backend,
  check_ticket_availability_api,
  pixel_button_events,
} from "../../DAL/web-pages/web-pages";

import {
  _get_token_from_localStorage,
  _get_user_from_localStorage,
  _set_user_in_localStorage,
} from "../../DAL/localStorage/localStorage";
import { isValidNumber } from "libphonenumber-js";
import {
  handleFormValidation,
  validationSchema,
} from "../../validation/payment";
import {
  convertCurrencyToSign,
  generate_random_chars,
  handleMove,
  number_with_comma,
  show_words,
} from "../../utils/constants";

import moment from "moment/moment";
import { CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { projectMode } from "src/config/config";

const PaymentContext = createContext();
const PaymentProvider = ({ children, result }) => {
  const params = useParams();
  const navigate = useNavigate();
  const stripe = useStripe();
  const router = useNavigate();
  const elements = useElements();
  const { enqueueSnackbar } = useSnackbar();

  const affiliate_user_name =
    params.affiliate_name || result.affliliate_user_name;

  const handleGetCountry = () => {
    const userTimezone = moment.tz.guess();
    let country = moment.tz.zone(userTimezone).countries();
    if (country.length > 0) {
      country = country[0];
      return country;
    }
  };

  const country = handleGetCountry();

  const [isLoadingCard, setIsLoadingCard] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [resPostData, setResPostData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [isPurchased, setIsPurchased] = useState(false);
  const [paymentPlan, setPaymentPlan] = useState({});
  const [upsellPlan, setUpsellPlan] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [venuesList, setVenuesList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(``);
  const [shippingObject, setShippingObject] = useState({
    street: "",
    city: "",
    state: "",
    zip_code: "",
    country: country,
  });

  const [inputState, setInputsState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: ``,
    street: "",
    venue: "",
    city: "",
    state: "",
    zip_code: "",
    country: country,
    password: "",
    passwordType: "password",
    aboutUs: "",
    currency: "gbp",
    same_billing: true,
    dietary_requirements: "",
    starter_menu: "",
    main_menu: "",
    dessert_menu: "",
    tea_and_coffee_menu: "",
    plan_amount: 0,
    ticket_count: {
      label: 1,
      value: 1,
    },
    payment_method: "credit_card",
  });

  const user_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  let userData = {
    first_name: inputState.first_name,
    last_name: inputState.last_name,
    email: inputState.email,
  };

  const handle_get_events = (props) => {
    let { event_clicked, plan } = props;
    let events = result.Sale_page.sale_page_load_events;
    let page_slug = result.Sale_page.sale_page_title_slug;

    let button_event = null;
    const isPayment = router.pathname.includes("/payment/");
    const isThanks = router.pathname.includes("/thank-you/");

    if (plan) {
      events = plan.pixel_events?.events;
    } else if (event_clicked) {
      events = result.Sale_page.sale_page_button_events;
      if (isPayment) {
        events = result.Sale_page.payment_page_button_events;
        page_slug = result.Sale_page.payment_page?.sale_page_title_slug;
      } else if (isThanks) {
        events = result.Sale_page.thanks_page_button_events;
        page_slug = result.Sale_page.thanks_page?.sale_page_title_slug;
      }
      button_event = events.find((e) => e.button === event_clicked);
    } else {
      if (isPayment) {
        events = result.Sale_page.payment_page_load_events;
        page_slug = result.Sale_page.payment_page?.sale_page_title_slug;
      } else if (isThanks) {
        events = result.Sale_page.thanks_page_load_events;
        page_slug = result.Sale_page.thanks_page?.sale_page_title_slug;
      }
    }
    return { events, page_slug, button_event };
  };

  const invoke_pixel_api = async (props = {}) => {
    if (projectMode === "prod") return;
    console.log(props, "<==invoke_pixel_api==>");
    let { event_clicked, plan } = props;
    const { events = [], page_slug, button_event } = handle_get_events(props);
    if (event_clicked && !button_event) return;
    if (!event_clicked && events.length === 0) return;

    let postData = {
      type: "page_url",
      sale_page_slug: page_slug,
    };
    if (plan) {
      postData.payment_plan = plan.plan_slug;
      postData.user_info = userData;
    }
    if (button_event) {
      postData.event_name = button_event.name;
      postData.button = button_event.button;
      postData.type = "button";
    } else if (events.length > 0) {
      postData.events = events;
    }
    pixel_button_events(postData);
  };

  const handleNavigate = (affiliate_name) => {
    let data = { affiliate_name, params, result, navigate, paymentPlan };
    // handle_navigate(data);
    invoke_pixel_api({ plan: paymentPlan });
  };

  const set_user_info = (user_info) => {
    setInputsState((prevState) => ({
      ...prevState,
      phone: user_info.contact_number,
      email: user_info.email,
      first_name: user_info.first_name,
      last_name: user_info.last_name,
      country: user_info.country || "",
      city: user_info.city || "",
      state: user_info.state || "",
      street: user_info.street || "",
      zip_code: user_info.zip_code || "",
    }));
    setIsLoggedin(true);
    setPhoneNumber(user_info.contact_number);
  };

  const handleValidation = async (e, length) => {
    let errorMes = await handleFormValidation(e, length);
    setErrorMessage(errorMes);
    if (errorMes) {
      handleMove();
    }
  };

  const number_of_seats_variable = (plan) => {
    let variable_to_use = "available_number_of_seats";
    if (plan.plan_ticket_type == "vip") {
      variable_to_use = "available_vip_number_of_seats";
    } else if (plan.plan_ticket_type == "executive") {
      variable_to_use = "available_executive_number_of_seats";
    }
    return variable_to_use;
  };

  const get_available_seats = (plan) => {
    let variable_to_use = number_of_seats_variable(plan);
    let sumOfSeats = 0;
    for (let i = 0; i < venuesList.length; i++) {
      sumOfSeats += venuesList[i][variable_to_use];
    }
    return sumOfSeats;
  };

  const available_seats = get_available_seats(paymentPlan);

  const is_disabled_venue = (venue) => {
    let disabled = false;
    let variable_to_use = number_of_seats_variable(paymentPlan);
    if (venue[variable_to_use] < 1) {
      disabled = true;
    }
    return disabled;
  };

  const handleClickPlan = (plan) => {
    setErrorMessage("");
    if (params.affiliate_name) {
      router.push(
        `/${params.page_slug}/payment/${plan.plan_slug}/${params.affiliate_name}`
      );
    } else {
      router.push(`/${params.page_slug}/payment/${plan.plan_slug}`);
    }
  };

  const is_safari = () => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.platform);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isIOS || isSafari) {
      return true;
    } else {
      return false;
    }
  };
  const handleNavigateToPayment = (value) => {
    window.open(`${value}`, "_self");
  };

  const validateForm = async (formData) => {
    try {
      if (_get_user_from_localStorage().first_name) {
        delete formData.password;
      }
      if (
        (paymentPlan.is_plan_free && !formData.show_street) ||
        formData?.address_not_validate
      ) {
        delete formData.street;
        delete formData.city;
        delete formData.state;
        delete formData.zip_code;
        delete formData.country;
      }
      const isValid = isValidNumber(`+${formData.phone}`);
      formData.phone = formData.phone.replace(/\+/g, "");

      if (formData?.page_name == "gps_payment_page") {
        if (formData?.phone && formData?.country_code !== formData.phone) {
          if (!isValid) {
            return "Please enter a valid phone number";
          }
        }
      } else {
        if (!isValid) {
          return "Please enter a valid phone number";
        }
      }
      if (formData?.page_name !== "gps_payment_page") {
        await validationSchema.validate(formData, { abortEarly: false });
      }
      return 200;
    } catch (validationErrors) {
      const newErrors = [];
      validationErrors.inner.forEach((error) => {
        newErrors.push(error.message);
      });
      return show_words(newErrors[0]);
    }
  };

  const submitFreeUser = async (
    inputState,
    params,
    handleNavigateToThankyou
  ) => {
    const errorData = await validateForm(inputState);
    if (errorData !== 200) {
      setIsLoadingCard(false);
      return errorData;
    }

    const postData = {
      plan_id: paymentPlan._id,
      page_slug: params.page_slug,
      contact_number: inputState.phone,
      register_url: window.location.href,
      affliliate_user_name: affiliate_user_name,
      time_zone: user_time_zone,
    };
    if (_get_token_from_localStorage()) {
      postData.x_sh_auth = _get_token_from_localStorage();
    } else {
      postData.first_name = inputState.first_name;
      postData.last_name = inputState.last_name;
      postData.email = inputState.email;
      postData.password = inputState.password;
      postData.contact_number = inputState.phone;
      postData.street = inputState.street;
      postData.city = inputState.city;
      postData.zip_code = inputState.zip_code;
      postData.state = inputState.state;
      postData.country = inputState.country;
    }
    setIsLoadingCard(true);
    const result = await add_free_member_by_web(postData);
    if (result.code === 200) {
      setIsLoadingCard(false);
      enqueueSnackbar(result.message, {
        variant: "success",
      });
      setIsLoading(true);

      handleNavigateToThankyou(result.affiliate_member.affiliate_url_name);
    } else {
      setIsLoadingCard(false);
      return result.message;
    }
  };

  let calculateTotalPrice = () => {
    let subtotal = 0;
    let ticket_count = inputState.ticket_count.value || 1;

    if (paymentPlan && !paymentPlan.is_plan_free) {
      let price_var = "plan_price";
      if (paymentPlan?.is_dont_show_full_amount) {
        price_var = "initial_amount";
      }
      subtotal += (paymentPlan[price_var] || 0) * ticket_count;
    } else {
      subtotal = 0;
    }

    upsellPlan?.forEach((plan) => {
      if (!plan?.is_plan_free) {
        subtotal += plan?.plan_price || 0;
      }
    });

    let discount_amount = 0;
    if (inputState?.coupon_info) {
      if (inputState.coupon_info.amount_off) {
        discount_amount = (inputState.coupon_info.amount_off || 0) / 100;
      } else if (inputState.coupon_info.percent_off) {
        discount_amount =
          (subtotal * (inputState.coupon_info.percent_off || 0)) / 100;
      }
    }

    discount_amount = Math.min(discount_amount, subtotal);
    const finalTotal = subtotal - discount_amount;

    return {
      subtotal: subtotal || 0,
      discount_amount: discount_amount || 0,
      finalTotal: 10,
    };
  };

  const getPirces = calculateTotalPrice();
  const { finalTotal } = getPirces;

  const planAmountToShow = (plan) => {
    let { plan_currency, plan_price, initial_amount, is_plan_free } = plan;
    if (is_plan_free) return "Free";
    if (plan.is_dont_show_full_amount) {
      plan_price = initial_amount;
    }
    return convertCurrencyToSign(plan_currency) + number_with_comma(plan_price);
  };

  const handleUpsellPlan = (plan) => {
    let find_plan = upsellPlan.find((item) => item._id == plan._id);
    if (find_plan) {
      let filter_plan = upsellPlan.filter((item) => item._id !== plan._id);
      setUpsellPlan(filter_plan);
    } else {
      setUpsellPlan((old) => [...old, plan]);
    }
  };

  const paymentThroughBank = async (
    inputState,
    params,
    handleNavigateToThankyou
  ) => {
    const errorData = await validateForm(inputState);
    if (errorData !== 200) {
      setIsLoadingCard(false);
      return errorData;
    }
    setIsLoadingCard(true);
    const postData = {
      plan_id: paymentPlan._id,
      page_slug: params.page_slug,
      contact_number: inputState.phone,
      register_url: window.location.href,
      affliliate_user_name: affiliate_user_name,
      time_zone: user_time_zone,
    };
    if (_get_token_from_localStorage()) {
      postData.x_sh_auth = _get_token_from_localStorage();
    } else {
      postData.first_name = inputState.first_name;
      postData.last_name = inputState.last_name;
      postData.email = inputState.email;
      postData.password = inputState.password;
      postData.contact_number = inputState.phone;
      postData.street = inputState.street;
      postData.city = inputState.city;
      postData.zip_code = inputState.zip_code;
      postData.state = inputState.state;
      postData.country = inputState.country;
    }
    const result = await register_through_bank_api(postData);
    if (result.code === 200) {
      setIsLoadingCard(false);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(true);

      handleNavigateToThankyou(result.affiliate_member.affiliate_url_name);
      // return result;
    } else {
      setIsLoadingCard(false);
      return result.message;
    }
  };

  const pay_by_google_and_apple = async (data) => {
    const { client_secret, payment_request } = data;
    payment_request.on("paymentmethod", async (ev) => {
      setIsLoadingCard(true);
      const { paymentIntent, error: confirmError } =
        await stripe.confirmCardPayment(client_secret, {
          payment_method: ev.paymentMethod.id,
        });
      if (confirmError) {
        ev.complete("fail");
        setIsLoadingCard(false);
        alert(confirmError.message);
        return confirmError.message;
      } else {
        ev.complete("success");
        if (paymentIntent.status === "requires_action") {
          const { error } = await stripe.confirmCardPayment(client_secret);
          if (error) {
            setIsLoadingCard(false);
            return error.message;
          } else {
            setIsLoadingCard(true);
            return confirmCardPayment(data);
          }
        } else {
          setIsLoadingCard(true);
          return confirmCardPayment(data);
        }
      }
    });
  };

  const confirmCardPayment = async (data) => {
    const { postData } = data;
    if (paymentPlan.payment_access === "recursion") {
      let data = {
        mission_id: postData.mission_id,
        plan_option_id: postData.plan_option_id ?? "",
        request_by: "web",
      };
      const result = await await confirm_one_time_payment_for_web_v1(data);
      if (result.code === 200) {
        setIsLoading(true);
        setIsLoadingCard(false);
        enqueueSnackbar("Payment succeeded successfully.", {
          variant: "success",
        });
        navigate(`/mission-levels/quest/${postData.mission_id}`);
      } else {
        setIsLoadingCard(false);
        enqueueSnackbar(result.message, {
          variant: "error",
        });
        return result.message;
      }
    } else {
      let data = {
        mission_id: postData?.mission_id,
        plan_option_id: postData?.plan_option_id ?? "",
        request_by: "web",
      };
      const result = await confirm_one_time_payment_for_web_v1(data);
      if (result.code === 200) {
        setIsLoading(true);
        setIsLoadingCard(false);
        enqueueSnackbar("Payment succeeded successfully.", {
          variant: "success",
        });
        navigate(`/mission-levels/quest/${postData.mission_id}`);
      } else {
        setIsLoadingCard(false);
        return result.message;
      }
    }
  };

  const handleSecureCard = async (data) => {
    const { client_secret, paymentMethod, cardElement, postData } = data;
    if (finalTotal <= 0 && paymentPlan.payment_access === "onetime") {
      const confirmationResult = await confirmCardPayment(data);
      return confirmationResult;
    }
    enqueueSnackbar("Processing payment...", { variant: "info" });
    const { payment_method } = postData;
    let confirmPromise;
    let google_pay = payment_method === "google_pay";
    let apple_pay = payment_method === "apple_pay";
    let credit_card = payment_method === "credit_card";

    try {
      if (credit_card) {
        confirmPromise = stripe.confirmCardPayment(client_secret, {
          payment_method: {
            card: cardElement,
          },
        });
      } else if (google_pay || apple_pay) {
        confirmPromise = stripe.confirmPayment({
          clientSecret: client_secret,
          confirmParams: {
            payment_method: {
              payment_method: paymentMethod.paymentMethod.id,
            },
          },
        });
      }
      const result = await confirmPromise;
      if (result.error) {
        if (google_pay || apple_pay) {
          paymentMethod.complete("fail");
        }
        setIsLoadingCard(false);
        return (
          result.error.message || "Payment failed due to an unknown error."
        );
      }

      if (google_pay || apple_pay) {
        paymentMethod.complete("success");
      }
      const confirmationResult = await confirmCardPayment(data);
      if (confirmationResult?.code !== 200) {
        return confirmationResult?.message;
      }
      return confirmationResult;
    } catch (err) {
      setIsLoadingCard(false);
      return (
        err.message || "An unexpected error occurred during payment processing."
      );
    }
  };

  const getIntentClientSecretForOneTime = async (data) => {
    let { postData } = data;
    delete postData.source_token;
    let google_pay = postData.payment_method === "google_pay";
    let apple_pay = postData.payment_method === "apple_pay";

    if ((google_pay || apple_pay) && finalTotal > 0) {
      data.payment_request.show();
    }
    const result = await get_web_intent_client_secret_for_one_time_v1(postData);
    if (result.code === 200) {
      setResPostData(postData);
      setClientSecret(result.client_secret);
      let is_fire_bank = postData.payment_method == "fire";
      let is_paypal = postData.payment_method == "paypal";
      if (is_paypal && finalTotal > 0) {
        localStorage.setItem("bank_payment_data", JSON.stringify(postData));
        localStorage.setItem("payment_plan_Access", paymentPlan.payment_access);

        handleNavigateToPayment(result.redirect_url);
        return;
      }
      if (is_fire_bank) {
        if (finalTotal > 0) {
          localStorage.setItem("bank_payment_data", JSON.stringify(postData));
          if (is_safari()) {
            handleNavigateToPayment(result.redirect_url);
          } else {
            handleNavigateToPayment(result.redirect_url);
          }
        } else {
          setIsLoading(true);
        }
        return;
      }
      if (paymentPlan.payment_access === "onetime") {
        postData.payment_intent_id = result.payment_intent_id;
      }
      setClientSecret(result.client_secret);
      setResPostData(postData);
      let function_data = { ...data, postData };
      function_data.client_secret = result.client_secret;

      if ((google_pay || apple_pay) && finalTotal > 0) {
        setIsLoadingCard(false);
        return pay_by_google_and_apple(function_data);
      } else {
        return handleSecureCard(function_data);
      }
    } else {
      setIsLoadingCard(false);

      return result.message;
    }
  };

  const pay_now_by_subsription = async (data) => {
    const { postData, paymentMethod } = data;
    postData.request_by = "client";
    let google_pay = postData.payment_method === "google_pay";
    let apple_pay = postData.payment_method === "apple_pay";
    const result = await pay_now_for_subscription_web_v1(postData);
    if (result.code === 200) {
      let is_paypal = postData.payment_method == "paypal";
      if (is_paypal) {
        localStorage.setItem("bank_payment_data", JSON.stringify(postData));
        localStorage.setItem("payment_plan_Access", paymentPlan.payment_access);
        handleNavigateToPayment(result.redirect_url);
        return;
      }
      if (google_pay || apple_pay) {
        paymentMethod.complete("success");
      }
      setIsLoading(true);
      setIsLoadingCard(false);
      const confirmationResult = await confirmCardPayment(data);
    } else if (result.code === 210) {
      postData.subscription_id = result?.strip_subscription_id;
      let function_data = { ...data, postData };
      function_data.client_secret = result.client_secret;
      return await handleSecureCard(function_data);
    } else {
      if (google_pay || apple_pay) {
        paymentMethod.complete("fail");
      }
      setIsLoadingCard(false);
      // enqueueSnackbar(result.message, {
      //   variant: "error",
      // });
      return result.message;
    }
  };

  const payNowForSubscription = async (data) => {
    let { postData, payment_request } = data;
    delete postData.currency;

    let google_pay = postData.payment_method === "google_pay";
    let apple_pay = postData.payment_method === "apple_pay";

    if (google_pay || apple_pay) {
      payment_request.show();
      setIsLoadingCard(false);
      payment_request.on("paymentmethod", async (ev) => {
        try {
          postData.source_token = ev.paymentMethod.id;
          const function_date = { ...data, postData, paymentMethod: ev };
          return await pay_now_by_subsription(function_date);
        } catch (error) {
          ev.complete("fail");
          return error.message;
        }
      });
    } else {
      const function_date = { ...data, postData };
      return await pay_now_by_subsription(function_date);
    }

    // if (clientSecret && resPostData) {
    //   postData = resPostData;
    //   let function_data = { ...data, postData, paymentType: payment_method };
    //   function_data.client_secret = result.client_secret;
    //   return handleSecureCard(function_data);
    // }
  };

  const handleCardAction = async (data) => {
    if (paymentPlan.payment_access === "recursion") {
      return await payNowForSubscription(data);
    } else {
      return getIntentClientSecretForOneTime(data);
    }
  };

  const handlePayment = async (missionData) => {
    setErrorMessage("");
    setIsLoadingCard(true);

    if (paymentPlan.is_plan_free && upsellPlan.length == 0) {
      let error = await submitFreeUser(inputState, params, handleNavigate);
      if (error) {
        return error;
      }
      return;
    }

    //if stripe card is null
    if (inputState.payment_method == "stripe" && elements == null) {
      setIsLoadingCard(true);
      return;
    }

    //Generating Return URL
    const { protocol, host } = window.location;

    let return_url = `${protocol}//${host}/mission-levels/final/${params.plan_id}`;
    let cancel_url = `${protocol}//${host}/mission-levels/final/${params.plan_id}?status=cancelled`;

    //Generating Return URL End

    const postData = { ...missionData };
    postData.payment_method = inputState.payment_method;
    let payment_request = inputState.payment_request;
    if (
      inputState.payment_method == "fire" ||
      inputState.payment_method == "paypal"
    ) {
      postData.return_url = return_url;
      postData.currency = inputState.currency;
    }
    if (inputState.payment_method == "paypal") {
      postData.cancel_url = cancel_url;
    }

    let fundtion_data = { postData };
    let cardElement = {};

    if (inputState.payment_method == "credit_card") {
      if (finalTotal > 0 || paymentPlan.payment_access === "recursion") {
        cardElement = elements.getElement(CardElement);
        return stripe.createToken(cardElement).then(async function (result) {
          if (result.error) {
            setIsLoadingCard(false);
            return result.error.message;
          } else {
            fundtion_data.postData.source_token = result?.token?.id;
            fundtion_data.cardElement = cardElement;

            return await handleCardAction(fundtion_data);
          }
        });
      } else {
        return await handleCardAction(fundtion_data);
      }
    } else {
      fundtion_data.payment_request = payment_request;
      return await handleCardAction(fundtion_data);
    }
  };

  if (isLoading) {
    return <CircularProgress className="centered" color="primary" />;
  }

  const Collection = {
    handlePayment,
    paymentThroughBank,
    getPirces,
    isLoadingCard,
    setIsLoadingCard,
    submitFreeUser,
    paymentPlan,
    setPaymentPlan,
    upsellPlan,
    setUpsellPlan,
    planAmountToShow,
    usersList,
    setUsersList,
    shippingObject,
    setShippingObject,
    handleUpsellPlan,
    venuesList,
    setVenuesList,
    handleClickPlan,
    errorMessage,
    setErrorMessage,
    isLoggedin,
    setIsLoggedin,
    inputState,
    setInputsState,
    phoneNumber,
    setPhoneNumber,
    isLoadingPage,
    setIsLoadingPage,
    available_seats,
    handleValidation,
    get_available_seats,
    is_disabled_venue,
    openPopup,
    setOpenPopup,
    invoke_pixel_api,
    isPurchased,
    setIsPurchased,
  };

  return (
    <PaymentContext.Provider value={Collection}>
      {children}
    </PaymentContext.Provider>
  );
};

export { PaymentContext, PaymentProvider };
