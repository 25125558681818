import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import PaymentPage from "./PaymentPage";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useFirebase } from "src/Hooks/FirebaseContaxt";
import { mentorship_category_detail_api } from "src/DAL/BonusContent/BonusContent";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function MentorshipPayment() {
  const classes = useStyles();
  const { category_slug } = useParams();
  const [planData, setPlanData] = useState({});
  const [apiData, setApiData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { logPageOnFirebase } = useFirebase();

  const getPlanDetail = async () => {
    const result = await mentorship_category_detail_api(category_slug);
    if (result.code === 200) {
      setApiData(result);
      setPlanData(result.payment_plan);
      logPageOnFirebase({
        event: "bonusContentPayment",
        replacementArr: [["{title}", result.bonus_content.title]],
      });
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/payment-request`);
    }
  };

  useEffect(() => {
    getPlanDetail();
  }, [category_slug]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <Elements stripe={loadStripe(apiData.stripe_publish_key)}>
      <PaymentPage planData={planData} apiData={apiData} />
    </Elements>
  );
}
