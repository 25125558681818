import React from "react";
import MuiTimePicker from "../GeneralComponents/Mui/TimePicker";

export default function MeditationNotification(props) {
  const { reminderTimes, setReminderTimes, inputs } = props;

  const handleChangeReminder = (value, name) => {
    setReminderTimes((old) => ({ ...old, [name]: value?.$d }));
  };

  return (
    <>
      {inputs.meditation_notification && (
        <div className="col-12 mb-3">
          <div className="d-flex">
            <div className="me-3">
              <MuiTimePicker
                label="Meditation Reminder Time"
                value={reminderTimes.meditation_reminder_time}
                onChange={(e) => {
                  handleChangeReminder(e, "meditation_reminder_time");
                }}
                inputProps={{ size: "small" }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
