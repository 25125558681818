import React, { useContext, createContext, useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { firebaseScreenObj, makeProperEvent } from "src/utils/firebase_utils";

const FirebaseContext = createContext();
export const useFirebase = () => useContext(FirebaseContext);

export function ContextFirebase({ children }) {
  const [firebaseConfig, setFirebaseConfig] = useState({});
  const [analytics, setAnalytics] = useState(null);

  useEffect(() => {
    if (Object.keys(firebaseConfig).length > 0) {
      const initializedApp = initializeApp(firebaseConfig);
      setAnalytics(getAnalytics(initializedApp));
    }
  }, [firebaseConfig]);

  const logOnFirebase = async (data) => {
    const { event, replacementArr = [], obj = {} } = data;
    if (analytics) {
      const event_name = makeProperEvent({ event, replacementArr });
      console.log(event_name, obj, "<===Firebase Event===>");
      logEvent(analytics, event_name, obj);
    }
  };

  const logPageOnFirebase = (props) => {
    // this will on firebase as visited page
    let { event } = props;
    event = makeProperEvent({ ...props, event: firebaseScreenObj[event] });
    logOnFirebase({
      event: "page_view",
      obj: { page_title: event, page_path: location.pathname },
    });
  };

  const collection = {
    firebaseConfig,
    setFirebaseConfig,
    logOnFirebase,
    logPageOnFirebase,
  };

  return (
    <FirebaseContext.Provider value={collection}>
      {children}
    </FirebaseContext.Provider>
  );
}
