import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { IconButton, CircularProgress, Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { htmlDecode } from "src/utils/convertHtml";
import MissionLevelsOverviewTab from "../../components/MissionLevels/MissionLevelsOverviewTab";
import GeneralModelBox from "src/components/GeneralComponents/GeneralModelBox";
import UnlockVideo from "src/components/MissionLevels/UnlockVideo";
import {
  mission_level_detail_api,
  unlock_mission_schedule_api,
} from "src/DAL/MissionLevels/MissionLevels";
import { ReactVideoPlayer } from "src/components";
import { s3baseUrl } from "src/config/config";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import { handle_change_page, number_with_comma } from "src/utils/constants";
import StartMission from "src/components/MissionLevels/StartMission";
import moment from "moment";
import GeneralQuestionsList from "../GeneralQuestions/GeneralQuestionsList";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";
import QuestCardCompleteForReport from "../MissionControlNew/components/QuestCardCompleteForReport ";
import QuestLevelsScheduleTab from "src/components/MissionLevels/QuestLevelsScheduleTab";
import { confirm_one_time_payment_for_web_v1 } from "src/DAL/web-pages/web-pages";
import { useFirebase } from "src/Hooks/FirebaseContaxt";
import { firebaseEventsObj } from "src/utils/firebase_utils";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function QuestLevelDetailLocked({
  hideHeading,
  missionSchedules,
  missionBadges,
  isComponent,
}) {
  const { mission__id } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const { logOnFirebase, logPageOnFirebase } = useFirebase();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openMissionConfirm, setOpenMissionConfirm] = useState(false);
  const [openUnlock, setOpenUnlock] = useState(null);
  const [missionDetail, setMissionDetail] = useState({});
  const [schedules, setSchedules] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [unLockCoin, setUnLockCoin] = useState(0);
  const [userCoin, setUserCoin] = useState(0);
  const [startQuest, setStartQuest] = useState(false);
  const [openQuestionsPopup, setOpenQuestionsPopup] = useState(false);
  const [paymentPlan, setPaymentPlan] = useState(null);
  const [memberShipLOckedStatus, setMembrShipLOckedStatus] = useState(null);
  const [quest_payment_status, set_quest_payment_status] = useState("");
  const [upgradeButton, setUpgradeButton] = useState(false);
  const [loadButton, setLoadButton] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClick = (schedule) => {
    const today = moment();
    const startedAt = moment(schedule.started_at);
    setSelectedSchedule(schedule);
    if (missionDetail.mission_status === "not_started") {
      return;
    }
    if (quest_payment_status == "pending") {
      return;
    }

    navigate(
      `/mission-levels/quest-details/${
        mission__id ?? "66a722347421ac375573836f"
      }/${schedule._id}`,
      {
        state: schedule,
      }
    );
  };

  const handleClickStart = () => {
    if (
      missionDetail?.content_settings?.show_mission_on_boarding_questions &&
      missionDetail?.question_exist
    ) {
      handleOpenQuestionsPopup();
    } else {
      navigate(`/mission-levels/the-art-of-achievement/${mission__id}`);
    }
  };
  const handleStartFree = () => {
    if (
      missionDetail?.content_settings?.show_mission_on_boarding_questions &&
      missionDetail?.question_exist
    ) {
      handleOpenQuestionsPopup();
    } else {
      setStartQuest(true);
    }
  };
  const handleStartQuest = async () => {
    let data = {
      mission_id: mission__id,
      plan_option_id: "",
      request_by: "web",
    };
    const result = await confirm_one_time_payment_for_web_v1(data);
    if (result.code === 200) {
      logOnFirebase({
        event: firebaseEventsObj.onQuestStarted,
        replacementArr: [["{mission_title}", missionDetail.title]],
      });
      logOnFirebase({
        event: firebaseEventsObj.onQuestPurchasedFree,
        replacementArr: [["{mission_title}", missionDetail.title]],
      });
      setStartQuest(false);
      navigate(`/mission-levels/quest/${mission__id}`);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleCrossUnlock = () => {
    setSelectedSchedule({});
    setOpenUnlock(null);
  };

  const get_mission_detail = async () => {
    const result = await mission_level_detail_api(
      mission__id ?? "66a722347421ac375573836f"
    );
    if (result.code == 200) {
      if (!isComponent) {
        // only log when this is a page like lock detail page
        logPageOnFirebase({
          event: "questLockDetail",
          replacementArr: [["{mission_title}", result.mission.title]],
        });
      }
      setMissionDetail({ ...result.mission, user_data: result.user_data });
      setMembrShipLOckedStatus(result.membership_upgrade_button_setting);
      setSchedules(result.mission_schedules);
      set_quest_payment_status(result?.quest_payment_status);
      setPaymentPlan(result?.payment_plan);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate("/mission-levels");
      setIsLoading(false);
    }
  };

  const handleStartMission = () => {
    setOpenQuestionsPopup(false);
    if (missionDetail?.is_paid) {
      navigate(`/mission-levels/the-art-of-achievement/${mission__id}`);
    } else {
      setStartQuest(true);
    }
  };

  const handleUnlock = (unlock_coin, user_coin) => {
    if (user_coin < unlock_coin) {
      enqueueSnackbar("You do not have enough coins to start Schedule", {
        variant: "error",
      });
      return;
    }
    setOpenConfirm(true);
    setUnLockCoin(unlock_coin);
    setUserCoin(user_coin);
    setTimeout(() => {
      setOpenUnlock(null);
    }, 500);
  };

  const handleOpenQuestionsPopup = () => {
    setOpenQuestionsPopup(true);
  };

  const handleMissionStart = (unlock_coin, user_coin) => {
    if (user_coin < unlock_coin) {
      enqueueSnackbar("You do not have enough coins to start this Mission", {
        variant: "error",
      });
      return;
    }
    setUnLockCoin(unlock_coin);
    setUserCoin(user_coin);
    setOpenMissionConfirm(true);
    setTimeout(() => {
      setOpenUnlock(null);
    }, 100);
  };

  const handleConfirmSubmit = async () => {
    setIsSubmitting(true);
    const result = await unlock_mission_schedule_api(selectedSchedule._id);
    if (result.code == 200) {
      setSchedules((old) =>
        old.map((item) => {
          if (item._id === selectedSchedule._id) {
            item.schedule_status = "in_progress";
          }
          return item;
        })
      );
      setOpenConfirm(false);
      setIsSubmitting(false);
      setOpenUnlock(null);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setOpenConfirm(false);
      setIsSubmitting(false);
    }
  };

  const TABS_DATA = {
    handleClick,
    schedules,
    missionDetail,
    handleClickStart,
  };

  useEffect(() => {
    get_mission_detail();
  }, []);

  useEffect(() => {
    if (openQuestionsPopup) {
      logPageOnFirebase({
        event: "questOnboardingQuestion",
        replacementArr: [["{mission_title}", missionDetail.title]],
      });
    }
  }, [openQuestionsPopup]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row mobile-margin">
          {!hideHeading && (
            <div className="col-12">
              <div className="d-flex challenge-video-detail-title">
                <span>
                  <IconButton
                    className="back-screen-button"
                    onClick={() =>
                      navigate(
                        `/mission-levels/level-details-new/${missionDetail?.membership_level_info?.badge_level_info?._id}`
                      )
                    }
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </span>
                <h2 className="ms-2">{htmlDecode(missionDetail.title)}</h2>
              </div>
            </div>
          )}
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
          {quest_payment_status == "pending" && (
            <Alert
              severity="warning"
              style={{
                backgroundColor: "rgb(43 32 9 / 20%)",
                color: "#e4c073",
              }}
            >
              Your payment is in process. Bank payments take some time to
              complete. As soon as it is successful, you will get access to the
              QUEST.
            </Alert>
          )}
        </div>
        <div className="row mt-2">
          <div className={`col-12 ${"col-md-8 mt-3"}`}>
            {missionDetail.mission_status == "not_started" ? (
              <div id="video_detail_image_box">
                {missionDetail.promo_video ? (
                  <ReactVideoPlayer url={missionDetail.promo_video} />
                ) : (
                  <img
                    src={s3baseUrl + missionDetail.image?.thumbnail_1}
                    className="img-fluid w-100"
                  />
                )}
              </div>
            ) : (
              <div id="video_detail_image_box">
                {missionDetail.video_url ? (
                  <ReactVideoPlayer url={missionDetail.video_url} />
                ) : (
                  <img
                    src={s3baseUrl + missionDetail.image?.thumbnail_1}
                    className="img-fluid w-100"
                  />
                )}
              </div>
            )}
          </div>

          <div className="col-md-4">
            <QuestCardCompleteForReport
              missionDetail={missionDetail}
              missionBadges={missionBadges}
              hideEranedBadges={true}
              // missionDashboaerd={missionDashboaerd}
            />
          </div>

          <div className="col-12">
            {!missionDetail?.content_settings?.allow_user_to_start_mission &&
              missionDetail?.content_settings
                ?.user_unable_to_start_mission_discription && (
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      missionDetail?.content_settings
                        ?.user_unable_to_start_mission_discription,
                  }}
                  className="level-not-match mt-3"
                ></div>
              )}
          </div>

          {(missionDetail?.detailed_description ||
            (missionDetail?.mission_status === "not_started" &&
              missionDetail?.content_settings?.start_mission_button_text)) && (
            <div className="col-12">
              <MissionLevelsOverviewTab {...TABS_DATA} />
            </div>
          )}
          {schedules.length > 0 && (
            <div className="col-md-12  mt-3">
              <div className="row">
                <div
                  className="col-12 schedule-heading pb-0 mt-3"
                  id="schedule-heading-box"
                >
                  <h2 style={{ fontSize: "20px" }} className="mt-2 mb-2">
                    {missionDetail?.content_settings?.schedule_heading}
                  </h2>
                </div>
              </div>
              <QuestLevelsScheduleTab
                {...TABS_DATA}
                missionSchedules={missionSchedules}
                is_lock_screen={true}
              />
            </div>
          )}
          {/* {memberShipLOckedStatus &&
            memberShipLOckedStatus?.show_membership_button &&
            memberShipLOckedStatus?.upgrade_button_url &&
            memberShipLOckedStatus?.upgrade_button_text && (
              <div className="col-12">
                <button
                  className="mc-button-contained floating-button"
                  onClick={() =>
                    handle_change_page(
                      memberShipLOckedStatus?.upgrade_button_url,
                      setUpgradeButton
                    )
                  }
                >
                  {upgradeButton
                    ? "Please Wait..."
                    : memberShipLOckedStatus?.upgrade_button_text}
                </button>
              </div>
            )} */}
          {!openQuestionsPopup && (
            <>
              {missionDetail?.is_paid &&
                !missionDetail?.redirect_user_to &&
                paymentPlan &&
                typeof paymentPlan === "object" &&
                Object.keys(paymentPlan).length > 0 &&
                quest_payment_status !== "pending" &&
                missionDetail?.content_settings?.allow_user_to_start_mission &&
                missionDetail?.mission_status === "not_started" && (
                  <div className="col-12">
                    <button
                      className="mc-button-contained floating-button"
                      onClick={handleClickStart}
                    >
                      {
                        missionDetail?.content_settings
                          ?.start_mission_button_text
                      }
                    </button>
                  </div>
                )}{" "}
              {missionDetail?.is_paid &&
                quest_payment_status !== "pending" &&
                missionDetail?.redirect_user_to &&
                missionDetail?.redirect_link &&
                missionDetail?.content_settings?.allow_user_to_start_mission &&
                missionDetail?.mission_status === "not_started" && (
                  <div className="col-12">
                    <button
                      className="mc-button-contained floating-button"
                      onClick={() =>
                        handle_change_page(
                          missionDetail?.redirect_link,
                          setLoadButton
                        )
                      }
                    >
                      {loadButton
                        ? "Please wait..."
                        : missionDetail?.content_settings
                            ?.start_mission_button_text}
                    </button>
                  </div>
                )}
              {!missionDetail?.is_paid &&
                quest_payment_status !== "pending" &&
                missionDetail?.content_settings?.allow_user_to_start_mission &&
                missionDetail?.mission_status === "not_started" && (
                  <div className="col-12">
                    <button
                      className="mc-button-contained floating-button"
                      onClick={handleStartFree}
                    >
                      {
                        missionDetail?.content_settings
                          ?.start_mission_button_text
                      }
                    </button>
                  </div>
                )}
            </>
          )}
        </div>
      </div>
      <GeneralModelBox
        open={Boolean(openUnlock)}
        setOpen={setOpenUnlock}
        className={"challenge-video-days-questions-modal"}
        componentToPassDown={
          <>
            {openUnlock == 1 ? (
              <UnlockVideo
                handleCross={handleCrossUnlock}
                selectedSchedule={selectedSchedule}
                handleUnlock={handleUnlock}
                isSubmitting={isSubmitting}
              />
            ) : (
              openUnlock && (
                <StartMission
                  handleCross={handleCrossUnlock}
                  missionDetail={missionDetail}
                  handleUnlock={handleMissionStart}
                  isSubmitting={isSubmitting}
                />
              )
            )}
          </>
        }
      />
      <WhatsappPopUpModel
        open={Boolean(openQuestionsPopup)}
        setOpen={setOpenQuestionsPopup}
        title={"Mission  Questions"}
        show_date_and_income={true}
        componentToPassDown={
          <GeneralQuestionsList
            created_for="mission_onboarding"
            created_for_id={mission__id}
            hideSubmitButton={false}
            remove_back_button={true}
            hide_dynamite_reply={true}
            for_unlock_mission={true}
            prevent_single_answer={true}
            onSubmitSuccess={handleStartMission}
            className="challenge-video-days-questions"
            collapseAll={true}
            submit_button_text={"Start Mission"}
          />
        }
      />

      <CustomConfirmation
        open={openConfirm}
        setOpen={setOpenConfirm}
        title={`Unlocking this Schedule will leave you with a remaining balance of ${number_with_comma(
          userCoin - unLockCoin
        )} coins. Are you sure you want to proceed?`}
        handleAgree={handleConfirmSubmit}
        isLoading={isSubmitting}
      />
      <CustomConfirmation
        open={startQuest}
        setOpen={setStartQuest}
        title={"Are you sure you want to start this quest?"}
        handleAgree={handleStartQuest}
      />

      <CustomConfirmation
        open={openMissionConfirm}
        setOpen={setOpenMissionConfirm}
        title={
          unLockCoin <= 0
            ? "Are you sure you want to proceed?"
            : `Starting this Mission will leave you with a remaining balance of ${number_with_comma(
                userCoin - unLockCoin
              )} coins. Are you sure you want to proceed?`
        }
        handleAgree={() => {
          if (
            missionDetail?.content_settings
              ?.show_mission_on_boarding_questions &&
            missionDetail?.question_exist
          ) {
            handleOpenQuestionsPopup();
          } else {
            handleStartMission();
          }
        }}
        isLoading={isSubmitting}
      />
    </>
  );
}
