import React, { useEffect, useState } from "react";
import PinterestLayout from "src/components/GeneralComponents/Pinterest";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  AffirmationListApi,
  DeleteAffirmationsApi,
} from "src/DAL/AffirmationCategories/Affirmations/Affirmations";
import { CircularProgress, IconButton } from "@mui/material";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import { htmlDecode } from "src/utils/convertHtml";
import AddOrUpdateAffirmations from "src/components/Affirmations/AddOrUpdateAffirmations";
import { useFirebase } from "src/Hooks/FirebaseContaxt";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function AffirmationList() {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const { logPageOnFirebase } = useFirebase();
  const { enqueueSnackbar } = useSnackbar();
  const [affirmationData, setAffirmationData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [drawerState, setDrawerState] = useState(false);
  const [affirmationId, setAffirmationId] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [editValues, setEditValues] = useState({});
  const [formType, setFormType] = useState("ADD");
  const location = useLocation();
  const [query, setQuery] = useState("");

  const getFilteredData = (query) => {
    let dataToFilter = [...affirmationData];
    if (query) {
      let resultFilter = dataToFilter.filter((post) =>
        post.category_title.toLowerCase().includes(query.toLowerCase())
      );
      return resultFilter;
    } else {
      return dataToFilter;
    }
  };

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
    setFormType("ADD");
  };

  const handleDetailPage = () => {};

  //Getting lesson detail
  const getAffirmationList = async () => {
    const result = await AffirmationListApi(params.category_slug);
    if (result.code === 200) {
      let affirmationArray = [];
      let affirmationObject = {};
      result.affirmation.map((affirmation, index) => {
        affirmationObject = {
          category_title: affirmation.category.name,
          pinteres_id: affirmation._id,
          pinterestImage: affirmation.bg_image.thumbnail_2,
          pinterestDescription: affirmation.affirmation_text,
          pinterestDate: affirmation.createdAt,
        };
        affirmationArray.push(affirmationObject);
      });
      setAffirmationData(affirmationArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    logPageOnFirebase({ event: "affirmationCategory" });
    getAffirmationList();
  }, []);

  //Getting Note In textarea
  const handleEdit = (value) => {
    setEditValues(value);
    setDrawerState(true);
    setFormType("EDIT");
  };
  const handleAgreeDelete = (value) => {
    setAffirmationId(value.pinteres_id);
    setOpenDelete(true);
  };

  //Deleting Affirmation
  const handleDelete = async () => {
    setOpenDelete(false);

    const result = await DeleteAffirmationsApi(affirmationId);
    if (result.code === 200) {
      getAffirmationList();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin  mt-2">
        <div className="col-12 d-flex justify-content-between ">
          <div className="d-flex">
            <IconButton
              className="back-screen-button"
              onClick={() => navigate("/affirmations")}
            >
              <ArrowBackIcon />
            </IconButton>
            <h2>{htmlDecode(location.state)}</h2>
          </div>
          <button
            className="small-contained-button float-end mt-1"
            onClick={handleOpenDrawer}
          >
            Add Affirmation
          </button>
        </div>
      </div>

      <div className="row media-margin">
        <PinterestLayout
          open={openDelete}
          setOpen={setOpenDelete}
          title={"Are you sure you want to delete this Affirmation?"}
          handleAgree={handleDelete}
          menuOptions={MENU_OPTIONS}
          data={getFilteredData(query)}
          notFoundtitle="Affirmations Not Found"
          handleDetailPage={handleDetailPage}
        />
      </div>

      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`${formType === "ADD" ? "Add" : "Edit"} Affirmation`}
        componentToPassDown={
          <AddOrUpdateAffirmations
            reloadListing={getAffirmationList}
            editData={editValues}
            formType={formType}
            category_slug={params.category_slug}
            onCloseDrawer={handleCloseDrawer}
          />
        }
      />
    </div>
  );
}

export default AffirmationList;
