import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { IconButton, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { lessonList, programmDetail } from "../../DAL/Programmes/Programmes";
import { s3baseUrl } from "../../config/config";
import CustomMUICard from "src/components/GeneralComponents/CustomMUICard";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import LockIcon from "@mui/icons-material/Lock";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import {
  get_date_with_user_time_zone,
  is_lesson_locked,
} from "src/utils/constants";
import { useFirebase } from "src/Hooks/FirebaseContaxt";

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

// ----------------------------------------------------------------------

export default function LessonListing() {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [lessonData, setLessonData] = useState([]);
  const [programmesDetail, setProgrammesDetail] = useState({});
  const { userInfo, adminTimeZone } = useContentSetting();
  const { logPageOnFirebase } = useFirebase();

  const getProgrammesDetail = async () => {
    const result = await programmDetail(params.program_slug);
    if (result.code === 200) {
      logPageOnFirebase({
        event: "programLessons",
        replacementArr: [["{program_title}", result.program.title]],
      });
      setProgrammesDetail(result.program);
      getLessonList(result?.program);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/programmes`);
    }
  };

  const handle_get_lock = (object, program) => {
    return is_lesson_locked(
      object,
      program,
      userInfo.time_zone,
      adminTimeZone,
      get_date_with_user_time_zone
    );
  };

  const handleClick = (object) => {
    if (!handle_get_lock(object, programmesDetail)) {
      navigate(`/lessons/${object.lesson_slug}`, {
        state: object,
      });
    }
  };

  const getLessonList = async (program) => {
    const result = await lessonList(params.program_slug);
    if (result.code === 200) {
      let CARD_OPTIONS = [];
      result.lesson.map((lesson) => {
        CARD_OPTIONS.push({
          ...lesson,
          card_title: lesson.title,
          card_short_description: lesson.short_description,
          card_image: s3baseUrl + lesson.lesson_images.thumbnail_2,
          card_classes: "col-lg-4 col-md-6 col-sm-12 mt-4 ms-0",
          card_button: handle_get_lock(lesson, program)
            ? {
                button_title: (
                  <>
                    <LockIcon />
                    {lesson.lock_statement}
                  </>
                ),
              }
            : "",
        });
      });
      setLessonData(CARD_OPTIONS);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/programmes`);
    }
  };

  useEffect(() => {
    getProgrammesDetail();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row mobile-margin display-flex">
          <div className="col-12 d-flex">
            <span>
              <IconButton
                className="back-screen-button"
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />
              </IconButton>
            </span>
            <h1 className="ms-2">{programmesDetail.title}</h1>
          </div>
        </div>
        <div className="row section-space">
          <div className="col-12"></div>
        </div>
        {lessonData.length > 0 ? (
          <CustomMUICard data={lessonData} handleClick={handleClick} />
        ) : (
          <RecordNotFound title="Lessons Not Found" />
        )}
      </div>
    </>
  );
}
