import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
// material
import {
  IconButton,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  StudySessionLis,
  StudySessionDetail,
} from "../../DAL/Programmes/Programmes";
import { s3baseUrl } from "../../config/config";
import CustomMUICard from "src/components/GeneralComponents/CustomMUICard";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import SearchIcon from "@mui/icons-material/Search";
import { useFirebase } from "src/Hooks/FirebaseContaxt";

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

// ----------------------------------------------------------------------

export default function StudySessionRecordingListing({ type }) {
  const { enqueueSnackbar } = useSnackbar();
  const { logPageOnFirebase } = useFirebase();
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [recoringsData, setRecoringsData] = useState([]);
  const [studySessionDetail, setStudySessionDetail] = useState({});
  const [query, setQuery] = useState("");
  const handleChangeSearch = (e) => {
    setQuery(e.target.value);
    localStorage.setItem("studySessionRecordingList", e.target.value);
  };
  const getFilteredData = (query) => {
    let dataToFilter = [...recoringsData];
    if (query) {
      let resultFilter = dataToFilter.filter((post) =>
        post.title.toLowerCase().includes(query.toLowerCase())
      );
      return resultFilter;
    } else {
      return dataToFilter;
    }
  };
  const handleClick = (object) => {
    let path = `/study-session/${params.sesson_slug}/study-session-recording/${object.study_session_recording_slug}`;
    if (type === "program") {
      path = "/programmes" + path;
    }
    navigate(path);
  };

  const updatePageTitle = (title) => {
    let firebaseVar = "studySessionRecordingsList";
    if (type === "program") {
      firebaseVar = "programStudySessionRecordingsList";
    }
    logPageOnFirebase({
      event: firebaseVar,
      replacementArr: [["{study_title}", title]],
    });
  };

  const getStudySessionDetail = async () => {
    const result = await StudySessionDetail(params.sesson_slug);
    if (result.code === 200) {
      setStudySessionDetail(result.study_session);
      updatePageTitle(result.study_session.title);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/programmes`);
    }
  };

  const getStudySessionListing = async () => {
    const result = await StudySessionLis(params.sesson_slug);
    if (result.code === 200) {
      let CARD_OPTIONS = [];
      result.study_session_recording.map((recording) => {
        CARD_OPTIONS.push({
          ...recording,
          card_title: recording.title,
          card_short_description: recording.short_description,
          card_image:
            s3baseUrl + recording.session_recording_images.thumbnail_2,
          card_classes: "col-lg-4 col-md-6 col-sm-12 mt-4",
        });
      });
      setRecoringsData(CARD_OPTIONS);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/programmes`);
    }
  };

  useEffect(() => {
    let studySessionRecordingList = localStorage.getItem(
      "studySessionRecordingList"
    );
    if (studySessionRecordingList) {
      setQuery(studySessionRecordingList);
    }
    getStudySessionListing();
    getStudySessionDetail();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row mobile-margin  mt-3">
          <div className="col-lg-8 col-md-8 col-sm-12 d-flex">
            <span>
              <IconButton
                className="back-screen-button"
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />
              </IconButton>
            </span>
            <h2 className="ms-3">{studySessionDetail.title}</h2>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div>
              <FormControl variant="outlined" style={{ marginTop: "2px" }}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Search
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type="text"
                  variant="small"
                  value={query}
                  onChange={handleChangeSearch}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Search"
                />
              </FormControl>
            </div>
          </div>
        </div>

        {getFilteredData(query).length > 0 ? (
          <CustomMUICard
            data={getFilteredData(query)}
            handleClick={handleClick}
          />
        ) : (
          <RecordNotFound title="Study Sessions Recordings Not Found" />
        )}
      </div>
    </>
  );
}
