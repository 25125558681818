import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { IconButton, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { programmModuleDetail } from "../../DAL/Programmes/Programmes";
import { s3baseUrl } from "../../config/config";
import CustomMUICard from "src/components/GeneralComponents/CustomMUICard";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import LockIcon from "@mui/icons-material/Lock";
import { get_date_with_user_time_zone } from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import moment from "moment";
import { useFirebase } from "src/Hooks/FirebaseContaxt";

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

// ----------------------------------------------------------------------

export default function ModulesLessonListing() {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const { logPageOnFirebase } = useFirebase();
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [lessonData, setLessonData] = useState([]);
  const [programmesDetail, setProgrammesDetail] = useState({});
  const [programmesModule, setProgrammesModule] = useState({});
  const [programmesStatus, setProgrammesStatus] = useState();
  const { userInfo, adminTimeZone } = useContentSetting();

  const handleClick = (object) => {
    let purchase_date = moment(
      get_update_time_with_time_zone(programmesDetail.purchase_date_time)
    )
      .add(
        "day",
        object.access_after_days > 0
          ? object.access_after_days - 1
          : object.access_after_days
      )
      .format("YYYY-MM-DD");

    if (programmesStatus == true && object.locked == true) {
    } else if (new Date(purchase_date) > new Date()) {
    } else {
      navigate(`/lessons/${object.lesson_slug}`, {
        state: object,
      });
    }
  };

  const get_update_time_with_time_zone = (date) => {
    let new_date = get_date_with_user_time_zone(
      date,
      "YYYY-MM-DD",
      userInfo.time_zone,
      adminTimeZone
    );
    return Date.parse(new_date);
  };

  const getLessonList = async () => {
    const result = await programmModuleDetail(params.program_slug);
    if (result.code === 200) {
      setProgrammesStatus(result.program_lock_status);
      setProgrammesDetail(result.program);
      setProgrammesModule(result.program_module);
      logPageOnFirebase({
        event: "moduleLessons",
        replacementArr: [["{module_title}", result.program_module.title]],
      });
      let CARD_OPTIONS = [];
      result.lesson.map((lesson) => {
        let purchase_date = moment(
          get_update_time_with_time_zone(result?.program?.purchase_date_time)
        )
          .add(
            "day",
            lesson.access_after_days > 0
              ? lesson.access_after_days - 1
              : lesson.access_after_days
          )
          .format("YYYY-MM-DD");

        CARD_OPTIONS.push({
          ...lesson,
          card_title: lesson.title,
          card_short_description: lesson.short_description,
          card_image: s3baseUrl + lesson.lesson_images.thumbnail_2,
          card_classes: "col-lg-4 col-md-6 col-sm-12 mt-4 ms-0",
          card_button:
            result.program.program_lock_status == true
              ? lesson.locked == true && {
                  button_title: (
                    <>
                      {/* <LockIcon />
                      {"Unlock Access"} */}
                      {lesson.locked ? (
                        <img
                          src={s3baseUrl + lesson.lock_icon}
                          width={20}
                          className="me-1"
                        />
                      ) : (
                        <img
                          src={s3baseUrl + result.lock_icon}
                          width={20}
                          className="me-1"
                        />
                      )}
                      {lesson.lock_statement
                        ? lesson.lock_statement
                        : result.lock_statement}
                    </>
                  ),
                }
              : new Date(purchase_date) > new Date()
              ? {
                  button_title: (
                    <>
                      {/* <LockIcon />
                      {"Unlock Access"} */}
                      {lesson.locked && lesson.lock_icon ? (
                        <img
                          src={s3baseUrl + lesson.lock_icon}
                          width={20}
                          className="me-1"
                        />
                      ) : (
                        <>
                          <LockIcon />
                        </>
                      )}
                      {!!lesson.lock_statement &&
                      lesson.lock_statement !== "undefind"
                        ? lesson.lock_statement
                        : "Unlock Access"}
                    </>
                  ),
                }
              : "",
        });
      });
      setLessonData(CARD_OPTIONS);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/programmes`);
    }
  };

  useEffect(() => {
    getLessonList();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row mobile-margin display-flex">
          <div className="col-12 d-flex">
            <span>
              <IconButton
                className="back-screen-button"
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />
              </IconButton>
            </span>

            <h1 className="ms-2">{programmesModule.title}</h1>
          </div>
        </div>
        <div className="row section-space">
          <div className="col-12"></div>
        </div>
        {lessonData.length > 0 ? (
          <CustomMUICard data={lessonData} handleClick={handleClick} />
        ) : (
          <RecordNotFound title="Lessons Not Found" />
        )}
      </div>
    </>
  );
}
