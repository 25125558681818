import { Icon } from "@iconify/react";
import { Avatar, Tooltip } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { communityHeart } from "src/assets";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import { s3baseUrl } from "src/config/config";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {
  get_date_with_user_time_zone,
  get_short_string,
  is_small_screen,
  LONG_TEXT_LIMIT,
  string_avatar,
} from "src/utils/constants";
import { htmlDecode, urlify } from "src/utils/convertHtml";
import CustomAvatarBadgeColor from "../GeneralComponents/CustomAvatarBadgeColor";

function SingleChildInception(props) {
  const {
    reply,
    handleCommentEdit,
    handleCommentDelete,
    handleLikeComment,
    comment,
    handleOpenLikes,
    handleViewProfile,
  } = props;
  const settings = useContentSetting();
  const [isShowCommentMore, setIsShowCommentMore] = useState(true);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleCommentEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleCommentDelete,
    },
  ];

  const get_short_date = () => {
    let from_now = moment(
      get_date_with_user_time_zone(
        reply?.createdAt,
        "DD MMM YYYY hh:mm A",
        settings.userInfo.time_zone,
        settings.adminTimeZone
      )
    );
    return from_now?._i;

    // let from_now = moment(
    //   get_date_with_user_time_zone(
    //     reply?.createdAt,
    //     "DD MMM YYYY hh:mm",
    //     settings.userInfo.time_zone,
    //     settings.adminTimeZone
    //   )
    // ).fromNow();

    // from_now = from_now.replace("a few seconds ago", "just now");
    // from_now = from_now.replace("seconds", "s");
    // from_now = from_now.replace("minutes", "m");
    // from_now = from_now.replace("hours", "h");

    // return from_now;
  };

  return (
    <>
      <div className={"profile-comments inception p-2 mb-1 pb-1"}>
        <div className="d-flex position-relative">
          <div className="poster-name-image d-flex w-100">
            {/* <Avatar
              src={s3baseUrl + reply?.member?.profile_image}
              alt="photoURL"
            >
              {string_avatar(
                reply?.member?.first_name + " " + reply?.member?.last_name
              )}
            </Avatar> */}
            <span
              onClick={
                reply?.action_by == "member"
                  ? () => handleViewProfile(reply?.member?._id)
                  : ""
              }
              className="cursor-pointer"
            >
              <CustomAvatarBadgeColor
                src={s3baseUrl + reply?.member?.profile_image}
                alt={reply?.member?.first_name}
                colorCode={reply?.badge_info?.color_code}
                width={33}
                height={33}
              />
            </span>
            <div className="w-100">
              <div className="text-start set-title-width poster-name ps-1 w-100">
                <span
                  className="card-title mb-0 fs-12 fw-500 cursor-pointer"
                  onClick={
                    reply?.action_by == "member"
                      ? () => handleViewProfile(reply?.member?._id)
                      : ""
                  }
                >
                  {htmlDecode(
                    reply?.member?.first_name + " " + reply?.member?.last_name
                  )}
                </span>

                {reply.is_featured && (
                  <Icon
                    icon="bi:pin-angle-fill"
                    className="pinned-icon ms-2"
                  ></Icon>
                )}
                {!is_small_screen() && (
                  <span className="date-color float-end pe-4 fw-400 fs-8">
                    {get_short_date()}
                  </span>
                )}
              </div>
              {is_small_screen() && (
                <span className="date-color ps-2">
                  {get_date_with_user_time_zone(
                    reply?.createdAt,
                    "DD MMM YYYY [at] hh:mm A",
                    settings.userInfo.time_zone,
                    settings.adminTimeZone
                  )}
                </span>
              )}
              <div className="ps-1 pt-0 fw-400 live-event-comment-message">
                {reply?.message?.length > 200 ? (
                  isShowCommentMore ? (
                    <>
                      <p className="text-start mb-0 pb-1">
                        {" "}
                        {get_short_string(
                          `${htmlDecode(reply?.message)}`,
                          LONG_TEXT_LIMIT
                        )}
                      </p>
                      <span
                        className="ms-2 see-more"
                        onClick={() => {
                          setIsShowCommentMore(false);
                        }}
                      >
                        See More
                      </span>
                    </>
                  ) : (
                    <>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: urlify(htmlDecode(reply?.message)),
                        }}
                        className="text-start mb-0"
                      ></p>
                      <span
                        className="ms-2 see-more"
                        onClick={() => {
                          setIsShowCommentMore(true);
                        }}
                      >
                        See Less
                      </span>
                    </>
                  )
                ) : (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: urlify(htmlDecode(reply?.message)),
                    }}
                    className="text-start mb-0"
                  ></span>
                )}
                {reply.like_count > 0 && (
                  <Tooltip title={`${reply.like_count} Likes`}>
                    <div className="event-comment-like-box position-relative">
                      <div
                        className="d-flex comment-like-heart mt-2"
                        onClick={() => {
                          handleOpenLikes(reply);
                        }}
                      >
                        <div className="comment-like-heart-image">
                          <img src={communityHeart} alt="" />
                        </div>
                        {reply.like_count > 0 && (
                          <div className="comment-like-heart-count">
                            {reply.like_count}
                          </div>
                        )}
                      </div>
                    </div>
                  </Tooltip>
                )}
              </div>
              <div className="col-12">
                {reply.file_url && (
                  <a
                    href={s3baseUrl + reply.file_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="comment-view-attachment"
                  >
                    <span className="chat-image-preview mt-2">
                      <img src={s3baseUrl + reply.file_url} />
                    </span>
                  </a>
                )}
              </div>
            </div>
          </div>

          {reply?.comment_is_self === true && (
            <div className="affirmation-dots">
              <CustomPopover menu={MENU_OPTIONS} data={reply} />
            </div>
          )}
        </div>
        {/* <div className="chat-comment-border">
          <hr />
        </div> */}
        <div className="like-reply-inception-box py-1 pt-2 px-2">
          <div></div>
          <div
            className="comment-like action-box"
            onClick={() => {
              handleLikeComment(reply, comment);
            }}
          >
            {reply.is_liked ? (
              <>
                <img src={communityHeart} alt="" /> Liked
              </>
            ) : (
              <>
                <FavoriteBorderIcon /> Like
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SingleChildInception;
