import React from "react";
import { Card } from "@mui/material";
import { Icon } from "@iconify/react";
import moment from "moment";
import { convertCurrencyToSign } from "src/utils/constants";
import { htmlDecode } from "src/utils/convertHtml";
import { s3baseUrl } from "src/config/config";

function TransactionCard({ transaction, handlePage, btn_title, handledetail }) {
  let transaction_title = "";
  if (transaction.transaction_type == "payment_request") {
    transaction_title = transaction.payment_request?.request_title + " | ";
  } else if (transaction.created_by == "clickfunnels") {
    transaction_title =
      transaction.clickfunnel_info?.funnel?.name +
      " | " +
      transaction?.plan_info?.plan_title +
      " |";
  } else if (transaction.transaction_type == "payment_plan") {
    transaction_title = transaction.sale_page?.sale_page_title + " | ";
  } else if (transaction.transaction_type == "dynamite_product_purchase") {
    transaction_title = "Dynamite Product Purchase | ";
  } else if (transaction.transaction_type == "quest") {
    transaction_title = "Quest | " + transaction?.mission_info?.title;
  }

  return (
    <Card className="payment-card mb-3">
      <div className="row p-3">
        <div className="col-12">
          {transaction.payment_request_info?.request_title && (
            <div className="mb-3">
              <span>
                {htmlDecode(transaction?.payment_request_info?.request_title)}
              </span>
            </div>
          )}
          <div className="mb-3 svg-color date-color">
            <Icon icon="uiw:date" />
            <span>
              &nbsp;{moment(transaction.createdAt).format("MMM DD YYYY")}
            </span>
          </div>
          <div className="row">
            <div className="col-4 col-md-6 col-lg-2">Transaction :</div>
            <div className="col-8 col-md-6 col-lg-4 ">
              {transaction.transaction_request_type === "onetime"
                ? `${transaction_title} OneTime`
                : `${transaction_title} Recurring`}
            </div>
            <div className="col-4 col-md-6 col-lg-2 mt-3 mt-lg-0">
              <div>Amount:</div>
              {transaction.discount_info?.discount_amount > 0 && (
                <div>Discount:</div>
              )}
              {transaction.discount_info?.coins_deduction > 0 && (
                <div>Coins Deducted:</div>
              )}
            </div>
            <div className="col-8 col-md-6 col-lg-1  mt-3 mt-lg-0  ">
              <div>
                {convertCurrencyToSign(transaction.currency)}
                {transaction.amount.toLocaleString()}
              </div>
              {transaction.discount_info?.discount_amount > 0 && (
                <div>
                  {convertCurrencyToSign(transaction.currency)}
                  {transaction.discount_info?.discount_amount?.toFixed(2)}
                </div>
              )}
              {transaction.discount_info?.coins_deduction > 0 && (
                <div>{transaction.discount_info?.coins_deduction}</div>
              )}
            </div>
            <div className="col-12 col-md-6 col-lg-3  mt-3 mt-lg-0 text-muted">
              {transaction.agrement_pdf_url && (
                <a
                  href={s3baseUrl + transaction.agrement_pdf_url}
                  target="_blank"
                  className="anchor-style"
                >
                  Preview Agreement PDF
                </a>
              )}{" "}
              {transaction.transaction_type == "dynamite_product_purchase" &&
                transaction?.product_order_id && (
                  <a
                    className="anchor-style"
                    onClick={() => handledetail(transaction?.product_order_id)}
                  >
                    View Products
                  </a>
                )}
            </div>

            {handlePage && transaction.payment_request_info?.request_title && (
              <div className="col-12 text-center">
                <button
                  className="small-contained-button mt-4"
                  onClick={() => {
                    handlePage(
                      transaction.payment_request_info.payment_request_slug
                    );
                  }}
                >
                  View Request
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}

export default TransactionCard;
