import React, { useState, useEffect } from "react";
import LevelMissions from "./LevelMissions";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { mission_levels_new } from "src/DAL/MissionLevels/MissionLevels";
import { useParams } from "react-router-dom";
import { useFirebase } from "src/Hooks/FirebaseContaxt";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function LevelMissionTab() {
  const [Levels, setLevels] = useState({});
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { mission__id } = useParams();
  const classes = useStyles();
  const { logPageOnFirebase } = useFirebase();

  const get_mission_levels = async () => {
    const result = await mission_levels_new(mission__id);
    if (result.code === 200) {
      logPageOnFirebase({
        event: "missionLevelDetail",
        replacementArr: [["{level}", result.level_badge.tagline]],
      });
      setLevels(result.level_badge);
      setData(result.level_badge.missions);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  let breadCrumbMenu = [
    {
      title: Levels?.tagline,
      navigation: `/mission-levels`,
      active: false,
    },

    {
      title: "Mission",
      navigation: ``,
      active: true,
    },
  ];

  useEffect(() => {
    get_mission_levels();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row mobile-margin">
          <div className="col-12">
            {Levels?.tagline && (
              <div className="d-flex challenge-video-detail-title">
                <div className="mission-level-heading mb-3">
                  <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
                </div>
              </div>
            )}
          </div>
          {Levels.detailed_description && (
            <div
              className="mission_dashboard_description "
              dangerouslySetInnerHTML={{
                __html: Levels.detailed_description,
              }}
            ></div>
          )}

          <div
            className="col-12 mt-2 d-block  level-icons position-relative "
            style={{ zIndex: "999" }}
          >
            <LevelMissions
              setLevels={setLevels}
              Levels={Levels}
              type={"quest"}
              dataArray={data}
            />
            <LevelMissions
              setLevels={setLevels}
              Levels={Levels}
              type={"mission"}
              dataArray={data}
            />
          </div>
        </div>
      </div>
    </>
  );
}
