import React, { useEffect, useRef, useState } from "react";
import AddComment from "./AddComment";
import { projectMode, s3baseUrl } from "src/config/config";
import { htmlDecode, urlify } from "src/utils/convertHtml";
import { useSnackbar } from "notistack";
import {
  community_feed_action,
  delete_feed_action,
  pin_feed_action,
  report_post_api,
} from "src/DAL/Community/Community";
import { ReactVideoPlayer } from "src/components";
import ShowAllLikes from "./components/LikesPopup/ShowAllLikes";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import {
  FEED_TXET_LIMIT,
  LONG_TEXT_LIMIT,
  count_chars,
  decryptToken,
  post_description,
  replaceUserNamesWithHTML,
  string_avatar,
} from "src/utils/constants";
import { get_short_string } from "src/utils/constants";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import { SingleComment } from "./components";
import { delete_comment_api } from "src/DAL/Community/Comments";
import UserInfoCard from "./components/LikesPopup/UserInfoCard";
import ActionsCount from "./components/LikesPopup/ActionsCount";
import HandleAction from "./components/LikesPopup/HandleAction";
import { Tooltip } from "@mui/material";
import { Icon } from "@iconify/react";
import { useChat } from "src/Hooks/ChatContext/ChatContext";
import { _check_chat_existing } from "src/DAL/Chat/Chat";
import FeedImagesList from "./components/FeedImagesList";
import EventInfoForPost from "./components/LikesPopup/EventInfoForPost";
import HowToEarn from "./components/HowToEarn";
import PollFeed from "./components/FeedPoll/PollFeed";
import { isFileExistsOnS3 } from "../../utils/file_utils";
import SurveyFeed from "./components/SurveyFeed/SurveyFeed";
import ReportPost from "./components/ReportPost";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import CustomConfirmationForBlockUser from "src/components/GeneralComponents/CustomConfirmationForBlockUser";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { firebaseEventsObj } from "src/utils/firebase_utils";
import { useFirebase } from "src/Hooks/FirebaseContaxt";

function PostCard({
  showInfoBox,
  setShowInfoBox,
  feeds_list,
  index,
  descriptions,
  getFeedsListingOnDelete,
  handleShowDetails,
  handleSelectedImage,
  handleFeedEdit,
  handleUpdateSpecificFeed,
  feeds_type,
  feedSetting,
  event_id,
  program_id,
  handleUpdatePoll,
  handlePollDetail,
  handleSurveyDetail,
  setFeedsList,
  handleReportPost,
  getFeedsListing,
  firebaseInfo,
}) {
  const settings = useContentSetting();
  const { logOnFirebase } = useFirebase();
  const { socket, userInfo } = settings;
  const { enqueueSnackbar } = useSnackbar();
  const targetRef = useRef(null);
  const [showAddComment, setShowAddComment] = useState(false);
  const [openLikesModal, setOpenLikesModal] = useState(false);
  const [showTab, setShowTab] = useState(0);
  const [isLiked, setIsLiked] = useState(true);
  const [isGratituded, setIsGratituded] = useState(true);
  const [commetMessage, setCommetMessage] = useState({});
  const [topLikedUser, setTopLikedUser] = useState([]);
  const [topGratitudeUser, setTopGratitudeUser] = useState([]);
  const [likeCount, setLikeCount] = useState(0);
  const [gratitudeCount, setGratitudeCount] = useState(0);
  const [commentCount, setCommentCount] = useState(0);
  const [coinsCount, setCoinsCount] = useState(0);
  const [playingVideo, setPlayingVideo] = useState(false);
  const [isShowMore, setIsShowMore] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [OpenBlock, setOpenBlock] = useState(false);

  const [showGlow, setShowGlow] = useState(true);
  const [openPin, setOpenPin] = useState(false);
  const [openDeleteComment, setOpenDeleteComment] = useState(false);
  const [formAction, setFormAction] = useState("ADD");
  const [selectedComment, setSelectedComment] = useState({});
  const [totalCommentCount, setTotalCommentCount] = useState(0);
  const [showAllComments, setShowAllComments] = useState(false);
  const [isFileExist, setIsFileExist] = useState(false);
  const { handleAddChat } = useChat();

  const handleFeedAction = async (id, action) => {
    if (action === "feedlike") {
      setIsLiked(true);
    } else if (action === "feedunlike") {
      setIsLiked(false);
    } else if (action === "gratitude") {
      setIsGratituded(true);
    }
    const formData = new FormData();
    formData.append("action", action);
    formData.append("feed", id);

    const result = await community_feed_action(formData);
    if (result.code === 200) {
      if (action === "feedlike" || action === "feedunlike") {
        // setTopLikedUser(result.action_response.top_liked_user);
        setLikeCount(result.action_response.like_count);
        setCoinsCount(result.action_response.coins_count);
      } else if (action === "gratitude") {
        // setTopGratitudeUser(result.action_response.top_gratitude_user);
        setGratitudeCount(result.action_response.gratitude_count);
        setCoinsCount(result.action_response.coins_count);
      }

      if (action === "feedlike" || action === "gratitude") {
        let firebase_event = "communityPostLike";
        if (action === "gratitude") {
          firebase_event = "communityPostGratitude";
        }

        logOnFirebase({
          event: firebaseInfo.prefix + firebaseEventsObj[firebase_event],
          obj: firebaseInfo.info,
        });
      }

      const socketData = {
        action,
        feed_id: id,
        token: decryptToken(localStorage.getItem("token")),
        creator_id: result.action_response.creator_id,
        action_by: result.action_response.sender,
        action_response: result.action_response,
      };
      socket.emit("feed_room_action_event", socketData);
    } else {
      if (action === "feedlike") {
        setIsLiked(false);
      } else if (action === "feedunlike") {
        setIsLiked(true);
      } else if (action === "gratitude") {
        setIsGratituded(false);
      }
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleAgree = async () => {
    setOpenDelete(false);
    const result = await delete_feed_action(feeds_list._id);
    if (result.code === 200) {
      enqueueSnackbar("Post deleted successfully", { variant: "success" });
      getFeedsListingOnDelete(feeds_list._id);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handlePinFeed = async () => {
    setOpenPin(false);
    const formData = new FormData();
    formData.append("feed", feeds_list._id);
    if (
      feeds_list.is_feature === false ||
      feeds_list.is_feature === "false" ||
      feeds_list.is_feature === undefined
    ) {
      formData.append("action", "feature");
    } else {
      formData.append("action", "unfeature");
    }
    const result = await pin_feed_action(formData);
    if (result.code === 200) {
      handleUpdateSpecificFeed(feeds_list._id);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handlePostDetail = (post_id) => {
    handleShowDetails(feeds_list);
  };

  const handleClickImage = (event, index, path) => {
    handleSelectedImage(feeds_list, index);
  };

  const handleOpenLikedBox = () => {
    setOpenLikesModal(true);
    setShowTab(1);
  };

  const call_on_comment = () => {};

  const handleOpenGratitudeBox = () => {
    setOpenLikesModal(true);
    setShowTab(2);
  };

  const handleCloseSimpleBox = () => {
    setOpenLikesModal(false);
    setShowTab();
  };

  const handleAgreeDelete = (value) => {
    setOpenDelete(true);
  };
  const handleAgreeBlock = (value) => {
    setOpenBlock(true);
  };

  const handleAgreePin = (value) => {
    setOpenPin(true);
  };

  const handleSendMessage = async (row) => {
    handleAddChat(row.action_info?.action_id);
  };

  const handleCommentEdit = (selected_comment) => {
    setSelectedComment(selected_comment);
    setFormAction("EDIT");
    setShowAddComment(true);
  };

  const handleCommentDelete = (selected_comment) => {
    setOpenDeleteComment(true);
    setSelectedComment(selected_comment);
  };

  //Deleting Category
  const handleDelete = async () => {
    setOpenDeleteComment(false);
    const result = await delete_comment_api(selectedComment._id);
    if (result.code === 200) {
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleBlock = async () => {
    const postData = {
      action_type: "block_user",
      block_user: feeds_list.action_info.action_id,
    };
    const result = await report_post_api(postData);
    if (result.code === 200) {
      let firebase_event = "communityPostBlockUser";
      logOnFirebase({
        event: firebaseEventsObj[firebase_event],
        obj: firebaseInfo.info,
      });

      setOpenBlock(false);
      getFeedsListing(
        `api/feeds/feed_list_with_pagination/v3?page=0&limit=10&feed_type=${feeds_type}&event=${
          event_id ? event_id : program_id ? program_id : ""
        }`
      );
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setOpenBlock(false);
    }
  };

  const handleMenu = (row) => {
    let MENU_OPTIONS = [];
    if (row.is_live_streaming) {
      return [];
    }
    if (feeds_list.is_self || userInfo.is_super_executive) {
      if (feeds_list.feed_type === "poll") {
        if (feeds_list.poll_info.poll_status !== "expired") {
          MENU_OPTIONS.push({
            label: "Edit",
            icon: "akar-icons:edit",
            handleClick: handleFeedEdit,
          });
        }
      } else if (feeds_list.feed_type === "survey" && feeds_list.is_self) {
        if (feeds_list.survey_info.survey_status !== "expired") {
          MENU_OPTIONS.push({
            label: "Edit",
            icon: "akar-icons:edit",
            handleClick: handleFeedEdit,
          });
        }
      } else {
        MENU_OPTIONS.push({
          label: "Edit",
          icon: "akar-icons:edit",
          handleClick: handleFeedEdit,
        });
      }

      MENU_OPTIONS.push({
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleAgreeDelete,
      });
    }
    if (userInfo.is_super_executive) {
      MENU_OPTIONS.push({
        label: feeds_list.is_feature == false ? "Pin" : "Unpin",
        icon: "akar-icons:pin",
        handleClick: handleAgreePin,
      });
    }
    if (
      (userInfo.is_super_executive || userInfo.is_executive) &&
      userInfo.is_chat_allow &&
      row.action_info.action_id !== userInfo._id
    ) {
      MENU_OPTIONS.push({
        label: "Message",
        icon: "material-symbols:send",
        handleClick: handleSendMessage,
      });
    }
    if (row.action_info.action_id !== userInfo._id) {
      MENU_OPTIONS.push(
        {
          label: "Report",
          icon: "material-symbols:report-outline",
          handleClick: handleReportPost,
        },
        {
          label: "Block User",
          icon: "material-symbols:block",
          handleClick: handleAgreeBlock,
        }
      );
    }
    return MENU_OPTIONS;
  };

  const handleMenuNew = (row) => {
    let MENU_OPTIONS = [];

    if (row.action_info.action_id !== userInfo._id) {
      MENU_OPTIONS.push(
        {
          label: "Report",
          icon: "material-symbols:report-outline",
          handleClick: handleReportPost,
        },
        {
          label: "Block User",
          icon: "material-symbols:block",
          handleClick: handleAgreeBlock,
        }
      );
    }
    return MENU_OPTIONS;
  };

  useEffect(async () => {
    setIsLiked(feeds_list.is_liked);
    setIsGratituded(feeds_list.is_gratitude);
    setCommetMessage(feeds_list.comment);
    // setTopLikedUser(feeds_list.top_liked_user);
    setLikeCount(feeds_list.like_count);
    // setTopGratitudeUser(feeds_list.top_gratitude_user);
    setGratitudeCount(feeds_list.gratitude_count);
    setCommentCount(feeds_list.comment_count);
    setCoinsCount(feeds_list.coins_count);

    if (feeds_list.stream_recording_url) {
      const is_file = await isFileExistsOnS3(feeds_list.stream_recording_url);
      setIsFileExist(is_file);
    }

    const options = {
      root: null, // use the viewport as the root
      rootMargin: "0px",
      threshold: 0.5, // trigger when 50% of the target is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setShowGlow(entry.isIntersecting);
        if (entry.isIntersecting) {
          setTimeout(() => {
            setShowGlow(false); // Hide the image after 3 seconds
          }, 3000);
        }
      });
    }, options);

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    // Cleanup observer on component unmount
    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [feeds_list]);

  useEffect(() => {
    if (showAllComments == false) {
      if (commetMessage?.child_comment?.length) {
        setTotalCommentCount(1 + commetMessage?.child_comment?.length);
      } else {
        setTotalCommentCount(1);
      }
    }
  }, [showAllComments, commetMessage]);

  return (
    <div
      className={`profile-cards p-3 mb-3 main-section ${
        feeds_list.is_reward_feed ? "glow-reward-feed" : ""
      }`}
      ref={targetRef}
    >
      {feeds_list.is_reward_feed &&
        showGlow &&
        feeds_list.reward_data?.reward_feed_gif && (
          <div className="gif-image">
            <img src={s3baseUrl + feeds_list.reward_data?.reward_feed_gif} />
          </div>
        )}
      {/* {showInfoBox && index === 0 && (
        <div className="info-popup-box show-on-desktop">
          <HowToEarn
            coin_description={descriptions?.coin_description}
            like_description={descriptions?.like_description}
            stick_description={descriptions?.stick_description}
            gratitude_description={descriptions?.gratitude_description}
            setShowInfoBox={setShowInfoBox}
          />
        </div>
      )}
      <div
        className={`d-flex w-100 justify-content-between ${
          feeds_list.is_live_streaming ? "live-streaming-enabled" : ""
        }`}
      >
      )} */}
      <div className="d-flex w-100 justify-content-between">
        <UserInfoCard
          profile_image={s3baseUrl + feeds_list.action_info?.profile_image}
          user_name={htmlDecode(feeds_list.action_info?.name)}
          avatar_char={string_avatar(feeds_list.action_info?.name)}
          date={feeds_list?.createdAt}
          activity_type={null}
          feed_level={htmlDecode(feeds_list.badge_level_info?._id)}
          win_status={htmlDecode(feeds_list.feed_appear_by)}
          is_live_streaming={feeds_list.is_live_streaming}
          badge_level_info={feeds_list.badge_level_info}
          feed={feeds_list}
        />

        <div className="poster-logo text-end">
          {feeds_list.badge_level_info ? (
            <span className="d-flex feed-level">
              <Tooltip title={feeds_list.badge_level_info.title}>
                <img
                  src={
                    s3baseUrl + feeds_list.badge_level_info.icon?.thumbnail_1
                  }
                  className="dynamite-level-image"
                />
              </Tooltip>
              {feeds_list.is_self ||
              userInfo.is_super_executive ||
              userInfo.is_executive ? (
                <CustomPopover
                  menu={handleMenu(feeds_list)}
                  data={feeds_list}
                />
              ) : (
                <CustomPopover
                  menu={handleMenuNew(feeds_list)}
                  data={feeds_list}
                />
              )}
            </span>
          ) : (
            <span className="d-flex feed-level">
              {feeds_list.is_self ||
              userInfo.is_super_executive ||
              userInfo.is_executive ? (
                <CustomPopover
                  menu={handleMenu(feeds_list)}
                  data={feeds_list}
                />
              ) : (
                <CustomPopover
                  menu={handleMenuNew(feeds_list)}
                  data={feeds_list}
                />
              )}
            </span>
          )}
        </div>
      </div>
      <div className="mt-2 post-description position-relative">
        <p
          onClick={() => handlePostDetail(feeds_list._id)}
          dangerouslySetInnerHTML={{
            __html: urlify(
              count_chars(feeds_list.description) > FEED_TXET_LIMIT &&
                isShowMore
                ? post_description(
                    replaceUserNamesWithHTML(
                      feeds_list.description,
                      feeds_list.mentioned_users
                    ),
                    FEED_TXET_LIMIT
                  )
                : replaceUserNamesWithHTML(
                    feeds_list.description,
                    feeds_list.mentioned_users
                  )
            ),
          }}
        ></p>
        {count_chars(feeds_list.description) > FEED_TXET_LIMIT && (
          <span
            className="ms-2"
            onClick={() => {
              setIsShowMore(!isShowMore);
            }}
          >
            {isShowMore ? "See More" : "See Less"}
          </span>
        )}
      </div>

      <>
        {feeds_list.feed_type === "poll" && feeds_list.poll_info && (
          <PollFeed
            feed={feeds_list}
            handleUpdateFeed={handleUpdatePoll}
            handlePollDetail={handlePollDetail}
            feedSetting={feedSetting}
          />
        )}
        {feeds_list.feed_type === "survey" && feeds_list.survey_info && (
          <SurveyFeed
            feed={feeds_list}
            handleUpdateFeed={handleUpdatePoll}
            handleSurveyDetail={handleSurveyDetail}
          />
        )}
        {feeds_list.feed_type === "image" &&
          feeds_list.feed_images &&
          feeds_list.feed_images.length > 0 && (
            <div className="feed-image">
              <FeedImagesList
                feed_images={feeds_list.feed_images}
                handleClickImage={handleClickImage}
              />
            </div>
          )}
        {(feeds_list.feed_type === "live" ||
          feeds_list.feed_type === "meeting") &&
          (projectMode === "DEV" ? (
            <div className="feed-image position-relative">
              {feeds_list.is_live_streaming && (
                <div
                  className="join-stream-box"
                  onClick={() => handlePostDetail(feeds_list._id)}
                >
                  <button className="small-contained-button">
                    Click to join
                  </button>
                </div>
              )}
              <div className="live-stream-label">
                <Icon
                  className="me-1"
                  fontSize={16}
                  style={{
                    color: feeds_list.is_live_streaming ? "red" : "grey",
                  }}
                  icon="carbon:dot-mark"
                />
                {feeds_list.is_live_streaming ? (
                  <span>Live</span>
                ) : (
                  <span>Offline</span>
                )}
              </div>
              {feeds_list.stream_recording_url && isFileExist ? (
                <ReactVideoPlayer
                  url={s3baseUrl + feeds_list.stream_recording_url}
                  thumbnail={s3baseUrl + feeds_list.image?.thumbnail_1}
                  playingVideo={true}
                />
              ) : (
                feeds_list.image?.thumbnail_1 && (
                  <>
                    {!feeds_list.is_live_streaming && (
                      <div
                        className="stream-video-available"
                        onClick={() => handlePostDetail(feeds_list._id)}
                      >
                        <div>
                          <h2>
                            {`${
                              feeds_list.feed_type === "meeting"
                                ? "Meeting"
                                : "Stream"
                            }`}{" "}
                            has been ended
                          </h2>
                          <h2>Video will be available shortly</h2>
                        </div>
                      </div>
                    )}
                    <img
                      src={s3baseUrl + feeds_list.image.thumbnail_1}
                      onClick={() => handlePostDetail(feeds_list._id)}
                    />
                  </>
                )
              )}
              {/* )} */}
            </div>
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: feeds_list.embed_code,
              }}
              className="live-embed-code"
            ></div>
          ))}
        {feeds_list.feed_type === "embed_code" && (
          <div
            dangerouslySetInnerHTML={{
              __html: feeds_list.embed_code,
            }}
            className="live-embed-code"
          ></div>
        )}
        {feeds_list.feed_type === "video" &&
          feeds_list.video_url &&
          feeds_list.description === "" && (
            <div className="feed-image">
              <ReactVideoPlayer
                url={feeds_list.video_url}
                // handleOnPlay={handlePostDetail}
                playingVideo={playingVideo}
              />
            </div>
          )}
        {feeds_list.feed_type === "video" &&
          feeds_list.video_url &&
          feeds_list.description !== "" && (
            <div className="feed-image">
              <ReactVideoPlayer url={feeds_list.video_url} />
            </div>
          )}
        {feeds_list?.event_info?.is_event_info && (
          <EventInfoForPost feed={feeds_list} />
        )}
      </>
      {feeds_list?.review_status === "pending" ? (
        <div class="under-review-post">
          <ErrorOutlineIcon />
          Your post is currently under review as it does not meet the required
          guidelines. Please wait a little while. Thank you for your patience!
        </div>
      ) : (
        <>
          <ActionsCount
            handleOpenLikedBox={handleOpenLikedBox}
            likeCount={likeCount}
            // topLikedUser={topLikedUser}
            handleOpenGratitudeBox={handleOpenGratitudeBox}
            gratitudeCount={gratitudeCount}
            topGratitudeUser={topGratitudeUser}
            coinsCount={coinsCount}
            feeds_type={feeds_type}
            likeCountNew={feeds_list.like_count}
            commentsCount={feeds_list.comment_count}
            handleClick={() => handlePostDetail(feeds_list._id)}
          />
          <hr />
          <HandleAction
            isLiked={isLiked}
            handleFeedAction={handleFeedAction}
            feeds_type={feeds_type}
            setShowAddComment={setShowAddComment}
            isGratituded={isGratituded}
            feed_id={feeds_list._id}
          />
          <hr />
        </>
      )}

      <div className="profile-comments">
        {feeds_list.comment?.length > 0 &&
          feeds_list.comment.map((comment) => {
            return (
              <SingleComment
                comment={comment}
                handleCommentEdit={handleCommentEdit}
                handleCommentDelete={handleCommentDelete}
                handleUpdateSpecificFeed={handleUpdateSpecificFeed}
                feed={feeds_list}
                event_id={event_id}
                program_id={program_id}
                feedSetting={feedSetting}
                parent_comment={comment}
                feeds_type={feeds_type}
                firebaseInfo={firebaseInfo}
              />
            );
          })}
        {showAddComment && !feeds_list.is_show_all && (
          <AddComment
            post_id={feeds_list._id}
            get_date={call_on_comment}
            formAction={formAction}
            feedsData={selectedComment}
            setFormAction={setFormAction}
            setShowAddComment={setShowAddComment}
            handleUpdateSpecificFeed={handleUpdateSpecificFeed}
            feed={feeds_list}
            event_id={event_id}
            program_id={program_id}
            feedSetting={feedSetting}
            feeds_type={feeds_type}
            firebaseInfo={firebaseInfo}
          />
        )}
      </div>
      {openLikesModal === true && (
        <ShowAllLikes
          openLikesModal={openLikesModal}
          setOpenLikesModal={setOpenLikesModal}
          handleCloseSimpleBox={handleCloseSimpleBox}
          showTab={showTab}
          post_id={feeds_list._id}
          feeds_type={feeds_type}
        />
      )}
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this post?"}
        handleAgree={handleAgree}
      />
      <CustomConfirmationForBlockUser
        open={OpenBlock}
        setOpen={setOpenBlock}
        title={`Are you sure you want to Block  ${feeds_list?.action_info?.name}?`}
        handleAgree={handleBlock}
      />
      <CustomConfirmation
        open={openDeleteComment}
        setOpen={setOpenDeleteComment}
        title={"Are you sure you want to delete this comment?"}
        handleAgree={handleDelete}
      />
      <CustomConfirmation
        open={openPin}
        setOpen={setOpenPin}
        title={`Are you sure you want to ${
          feeds_list.is_feature ? "unpin" : "pin"
        } this post?`}
        handleAgree={handlePinFeed}
      />
    </div>
  );
}

export default PostCard;
