import React, { useEffect, useState } from "react";
import { ReactVideoPlayer } from "src/components";
import SourceJourney from "./components/SourceJourney";
import CoinsLeaderboard from "./components/CoinsLeaderboard";
import MissionCard from "./components/MissionCard";
import UpComingEvents from "./components/UpComingEvents";
import SourceSession from "./components/SourceSession";
import { DashboardAppNew } from "src/DAL/Calender/Calender";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import InfoPopup from "./components/InfoPopup";
import GeneralModelBox from "src/components/GeneralComponents/GeneralModelBox";
import CommunityStoriesSlider from "src/components/MissionLevels/CommunityStoriesSlider";
import { useNavigate } from "react-router-dom";
import SalePages from "src/components/_dashboard/app/SalePages";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { handle_change_page } from "src/utils/constants";
import UpComingQuest from "./components/UpComingQuest";
import { useFirebase } from "src/Hooks/FirebaseContaxt";
import { firebaseEventsObj } from "src/utils/firebase_utils";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const MissionControlNew = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { navbarList, bookCallButtonSettings } = useContentSetting();
  const { logOnFirebase } = useFirebase();
  const [isLoading, setIsLoading] = useState(true);
  const [dashBoardData, setDashBoardData] = useState(null);
  const [dashboardResult, setDashboardResult] = useState({});
  const [showInfoPopup, setShowInfoPopup] = useState(null);
  const [UpComingEventsArray, setUpComingEventsArray] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentBadgeLevel, setCurrentBadgeLevel] = useState(null);
  const [loadingButton, setIsLoadingButton] = useState(false);
  const [quest, setQuest] = useState([]);
  const [upgradeButton, setUpgradeButton] = useState(false);

  const getEventListing = async () => {
    const result = await DashboardAppNew();
    if (result.code === 200) {
      setDashboardResult(result);
      setDashBoardData(result.dashboard_data);
      setQuest(result?.quest_list);
      setUpComingEventsArray(result.pages_mission_control_settings);
      setCurrentUser(result.dashboard_data.current_user);
      setCurrentBadgeLevel(
        result?.dashboard_data?.current_badge_level?.mission_control_content
      );
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleFeedDetail = (item) => {
    navigate(`/mission-control/feed-detail/${item._id}`);
  };

  useEffect(() => {
    getEventListing();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  let is_affiliate_links = navbarList.find(
    (nav) => nav.value === "affiliate_links"
  );

  return (
    <div className="container">
      <div class="row d-flex align-items-stretch mb-4">
        <div className="col-12 mb-2">
          <PageDescription
            parameter="mission_control_description"
            else_title="Hi, Welcome To Dashboard"
          />
        </div>
        <div
          class="col-12 col-lg-8 bg-secondary1 text-white p-2  "
          id="video-col"
        >
          <div className="feed-image h-100">
            <ReactVideoPlayer url={currentBadgeLevel?.video_url} />
          </div>
        </div>

        <div class="col-12 col-lg-4 bg-secondary1 text-white p-2 d-flex flex-fill">
          <SourceJourney
            user_data={dashBoardData.user_data}
            dashBoardData={dashBoardData}
            setShowInfoPopup={setShowInfoPopup}
            currentBadgeLevel={currentBadgeLevel}
          />
        </div>
        {currentBadgeLevel &&
          currentBadgeLevel?.description &&
          currentBadgeLevel?.button_text &&
          currentBadgeLevel?.button_url && (
            <div className="col-12 mt-2 mb-2">
              <div
                className="mission-control-card mission-control-card-new p-4"
                style={{ borderRadius: "8px" }}
              >
                <div className="row">
                  <div className={`col-md-12`}>
                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: currentBadgeLevel?.description,
                        }}
                        className="mission-control-description"
                      ></div>
                    </div>
                  </div>
                  {currentBadgeLevel?.button_text &&
                    currentBadgeLevel?.button_url && (
                      <div className="col-md-12 mt-3 mt-3 upgrade-account-box text-end">
                        <button
                          className="small-contained-button"
                          style={{ textTransform: "capitalize" }}
                          onClick={() => {
                            logOnFirebase({
                              event: firebaseEventsObj.mcMissionButton,
                            });
                            handle_change_page(
                              currentBadgeLevel?.button_url,
                              setIsLoadingButton
                            );
                          }}
                        >
                          {loadingButton
                            ? "Please wait..."
                            : currentBadgeLevel?.button_text}
                        </button>
                      </div>
                    )}
                </div>
              </div>
            </div>
          )}
      </div>

      {dashBoardData.feeds.length > 0 && (
        <CommunityStoriesSlider
          feedsList={dashBoardData.feeds}
          handleShowDetails={handleFeedDetail}
        />
      )}

      <div className="row mt-4">
        <div className="col-12 col-md-5  pe-0 mb-3">
          <div class="d-flex justify-content-between mb-2">
            <h4 className="mt-2 mb-1">COINS LEADERBOARD</h4>
          </div>
          <div className="d-flex flex-fill">
            <CoinsLeaderboard
              dashBoardData={dashBoardData}
              currentUser={currentUser}
            />
          </div>
        </div>
        <div className="col-12 col-md-7  mb-3">
          <div class="d-flex justify-content-between mb-2">
            <h4 className="mt-2 mb-1 ms-1">MISSION</h4>
          </div>
          <div className="d-flex flex-fill">
            <MissionCard current_mission={dashBoardData.current_mission} />
          </div>
        </div>
      </div>
      {dashBoardData?.session_recording.length > 0 && (
        <div className="row ">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="title-source-journey mt-2 mb-3">
              SOURCE SESSION
              {dashBoardData?.session_recording.length > 1 ? "S" : ""}
            </h4>
            <div className="view-comment mt-2 text-end me-3 date-color">
              <span
                id="load-more-comments"
                onClick={() => navigate(`/source-session`)}
              >
                View All
              </span>
            </div>
          </div>

          <div className="col-12">
            <SourceSession
              session_recording={dashBoardData?.session_recording}
            />
          </div>
        </div>
      )}

      {quest?.length > 0 && (
        <div className="row mt-2">
          <div class="d-flex justify-content-between mb-2">
            <h4 className="mt-2 mb-0">
              UPCOMING QUEST{quest?.length > 1 ? "S" : ""}
            </h4>
          </div>

          <div className="col-12 ">
            <UpComingQuest upcoming_events_array={quest} />
          </div>
        </div>
      )}

      {UpComingEventsArray.length > 0 && (
        <div className="row mt-2">
          <div class="d-flex justify-content-between mb-2">
            <h4 className="mt-2 mb-0">
              UPCOMING EVENT{UpComingEventsArray.length > 1 ? "S" : ""}
            </h4>
            <div className="view-comment mt-2 text-end me-3 date-color">
              <span
                id="load-more-comments"
                onClick={() => navigate(`/mission-control/Upcoming-events`)}
              >
                View All
              </span>
            </div>
          </div>

          <div className="col-12 ">
            <UpComingEvents upcoming_events_array={UpComingEventsArray} />
          </div>
        </div>
      )}

      {is_affiliate_links && (
        <div className="row mt-5">
          <div class="d-flex justify-content-between mb-2">
            <h4 className="mt-2 mb-0">AFFILIATE LINKS</h4>
            <div className="view-comment mt-2 text-end me-3 date-color">
              <span
                id="load-more-comments"
                onClick={() => navigate(`/affiliate-links`)}
              >
                View All
              </span>
            </div>
          </div>
          <div className="col-12 ">
            <SalePages
              sourceSessions={dashboardResult.affiliate_links}
              is_mission_control={true}
            />
            <div className="text-end mt-3"></div>
          </div>
        </div>
      )}
      <GeneralModelBox
        open={Boolean(showInfoPopup)}
        setOpen={setShowInfoPopup}
        className={"challenge-video-days-questions-modal"}
        componentToPassDown={
          <InfoPopup
            handleCross={() => setShowInfoPopup("")}
            showInfoPopup={showInfoPopup}
          />
        }
      />
      {bookCallButtonSettings &&
        bookCallButtonSettings?.show_book_call_button &&
        bookCallButtonSettings?.book_call_button_url &&
        bookCallButtonSettings?.book_call_button_text && (
          <div className="col-12">
            <button
              className="mc-button-contained floating-button"
              onClick={() => {
                logOnFirebase({
                  event: firebaseEventsObj.mcBookCallButton,
                });
                handle_change_page(
                  bookCallButtonSettings?.book_call_button_url,
                  setUpgradeButton
                );
              }}
            >
              {upgradeButton
                ? "Please Wait..."
                : bookCallButtonSettings?.book_call_button_text}
            </button>
          </div>
        )}
    </div>
  );
};

export default MissionControlNew;
