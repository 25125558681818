import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import QuestPaymentPage from "./QuestPaymentPage";
import { mission_level_detail_api } from "src/DAL/MissionLevels/MissionLevels";
import { PaymentProvider } from "src/Hooks/PaymentContext/PaymentContext";
import { useFirebase } from "src/Hooks/FirebaseContaxt";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function QuestPayment() {
  const classes = useStyles();
  const { plan_id } = useParams();
  const [planData, setPlanData] = useState({});
  const [stripeKey, setStripeKey] = useState({});
  const [apiData, setApiData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [plan, setPlan] = useState(null);
  const { logPageOnFirebase } = useFirebase();

  const get_mission_detail = async () => {
    const result = await mission_level_detail_api(plan_id);
    if (result.code == 200) {
      logPageOnFirebase({
        event: "questPayment",
        replacementArr: [["{mission_title}", result.mission.title]],
      });

      setPlanData(result?.mission?.payment_configuration);
      setApiData(result?.mission);
      setStripeKey(result?.key_data);
      setPlan(result.payment_plan);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate("/mission-levels");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_mission_detail();
  }, [plan_id]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <Elements stripe={loadStripe(stripeKey?.publish_key)}>
        <PaymentProvider result={{}} query={""}>
          <QuestPaymentPage planData={planData} apiData={apiData} plan={plan} />
        </PaymentProvider>
      </Elements>
    </>
  );
}
