import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Avatar, CircularProgress } from "@mui/material";
import { mission_control_data } from "src/DAL/MissionLevels/MissionLevels";
import { NotFoundNew, point } from "src/assets";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import { s3baseUrl } from "src/config/config";
import { get_kmb_number } from "src/utils/formatNumber";
import { medal_1, medal_2, medal_3 } from "src/assets";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { number_with_comma } from "src/utils/constants";
import CustomAvatarBadgeColor from "src/components/GeneralComponents/CustomAvatarBadgeColor";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  resize: {
    fontSize: 20,
  },
}));

export default function LeaderBoardForMissionControl({
  handleCross,
  badgeId,
  badge,
}) {
  const classes = useStyles();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadMorePath, setLoadMorePath] = useState(
    `api/member/community_level/members?page=${0}&limit=${50}&badge_id=${badgeId}`
  );
  const [streakLeaderBoard, setStreakLeaderBoard] = useState([]);
  const { userInfo, handleViewProfile } = useContentSetting();
  const observer = useRef();
  const get_mission_detail = async () => {
    const result = await mission_control_data(loadMorePath);
    if (result.code == 200) {
      let newArray = streakLeaderBoard.concat(result.members);

      setStreakLeaderBoard(newArray);

      setLoadMorePath(result.load_more);
      setIsLoading(false);
      setIsLoadingMore(false);
    } else {
      setIsLoadingMore(false);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && loadMorePath) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, loadMorePath, observer]
  );
  const loadMoreData = () => {
    setIsLoadingMore(true);
    if (loadMorePath) {
      get_mission_detail(loadMorePath);
    }
  };

  useEffect(() => {
    get_mission_detail(loadMorePath);
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="unlock-video p-2 congratulation-message">
      <div className="unlock-popup-cross-icon" onClick={handleCross}>
        x
      </div>
      <div className="d-flex justify-content-cetner main-heading-leader-boeard">
        <span className="challenge-heading unlock-color ">
          Coins Leader Board
        </span>
      </div>
      {streakLeaderBoard?.length > 0 ? (
        streakLeaderBoard?.map((item, index) => {
          return (
            <div
              className={`d-flex justify-content-between align-items-center mt-2 main-card-container `}
              key={item._id}
            >
              <div
                className="d-flex align-items-center"
                style={{ width: "70%" }}
              >
                {index == 0 ? (
                  <img src={medal_1} alt="medal_1" className="me-2 medal-img" />
                ) : index == 1 ? (
                  <img
                    src={medal_2}
                    alt="medal_2"
                    className="me-2 medal-img  "
                  />
                ) : index == 2 ? (
                  <img
                    src={medal_3}
                    alt="medal_3"
                    className="me-2  medal-img  "
                  />
                ) : (
                  <span className="me-2 ms-2">{index + 1}. </span>
                )}
                <span
                  className="cursor-pointer"
                  onClick={() => handleViewProfile(item?._id)}
                >
                  <CustomAvatarBadgeColor
                    sx={{ width: 30, height: 30 }}
                    alt={item?.first_name}
                    colorCode={badge?.color_code}
                    width={30}
                    height={30}
                  />
                </span>
                <div
                  className="user-name ms-2 d-flex align-items-center cursor-pointer"
                  onClick={() => handleViewProfile(item?._id)}
                >
                  <span
                    style={{
                      textAlign: "left",
                      color: "white",
                    }}
                  >
                    {" "}
                    {item?.first_name + " " + item?.last_name}
                  </span>

                  {item?._id == userInfo._id && (
                    <img src={point} className={`pointer_hand`} />
                  )}
                </div>
              </div>
              <div
                className="user-coins"
                style={{ width: "30%", textAlign: "end" }}
              >
                {number_with_comma(item?.coins_count)} Coins
              </div>
            </div>
          );
        })
      ) : (
        <>
          <RecordNotFound title="Data not found" show_new_image={true} />
        </>
      )}
      {loadMorePath ? (
        <div className="col-12 text-center">
          <button
            ref={lastBookElementRef}
            className="small-contained-button mt-3"
          >
            {isLoadingMore ? "Loading..." : "Load More"}
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
