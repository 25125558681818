import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SkeletonColor from "src/pages/MissionLevels/Skulton";
import { useNavigate } from "react-router-dom";
import { handle_change_page } from "src/utils/constants";
import { Typography, Grid, Chip } from "@mui/material";

function UpComingQuest({ upcoming_events_array }) {
  const sliderRef = useRef(null);
  const navigate = useNavigate();

  const handleNavigate = (value) => {
    console.log(value, "valuevaluevaluevaluevalue");
    if (value.mission_status !== "not_started") {
      navigate(`/mission-levels/quest/${value?._id}`);
    } else {
      if (value.is_paid) {
        if (value?.content_settings?.allow_user_to_start_mission) {
          navigate(`/mission-levels/the-art-of-achievement/${value?._id}`);
        } else {
          navigate(`/mission-levels/quest-detail/${value?._id}`);
        }
      } else {
        navigate(`/mission-levels/quest-detail/${value?._id}`);
      }
    }
  };

  useEffect(() => {
    if (sliderRef.current) {
      // Reset scroll position to the start
      sliderRef.current.scrollLeft = 0;

      // Check if the content is scrollable
      const isOverflowing =
        sliderRef.current.scrollWidth > sliderRef.current.offsetWidth;
    }
  }, [upcoming_events_array]);

  return (
    <>
      <Box className="p-0">
        <Grid container spacing={2}>
          {upcoming_events_array.map((session, index) => (
            <Grid item xs={12} md={12} key={index}>
              <Box
                className="card"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  borderRadius: "12px",
                  overflow: "hidden",
                  height: "100%",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                }}
              >
                {/* <h4
                variant="h6"
                className="ms-1 p-2 bg-top-session text-session mb-0"
              >
                {session.title}
              </h4> */}

                <Grid container className="bg-session">
                  <Grid item xs={12} sm={3} sx={{ padding: "12px" }}>
                    <Box
                      component="img"
                      src={s3baseUrl + session?.image?.thumbnail_2}
                      alt={session.title}
                      sx={{
                        width: "100%",
                        height: "auto",
                        maxHeight: "150px",
                        objectFit: "cover",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={9} sx={{ padding: "12px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <span style={{ cursor: "pointer" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontSize: "16px",
                            marginBottom: "0px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {session?.title}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontSize: "12px",
                            color: "#B0B0B0",
                            marginBottom: "10px",
                          }}
                        >
                          <p
                            className="card-title upcomingdes"
                            dangerouslySetInnerHTML={{
                              __html: htmlDecode(session?.short_description),
                            }}
                          ></p>
                        </Typography>
                      </span>

                      <div className="col-sm-12 col-md-12  mt-2 text-start position-relative text-center">
                        <Chip
                          label={
                            session.mission_status !== "not_started"
                              ? session?.content_settings
                                  ?.already_purchase_button_text ??
                                "Go to Quest"
                              : session?.content_settings
                                  ?.start_mission_button_text ??
                                "Already Subscribed"
                          }
                          color="primary"
                          className="me-1 dd-chip chip-update chip-update-new"
                          variant="outlined"
                          onClick={() => handleNavigate(session)}
                        />
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}

export default UpComingQuest;
