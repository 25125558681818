import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import { s3baseUrl } from "src/config/config";
import LockIcon from "@mui/icons-material/Lock";
import { mission_levels_new } from "src/DAL/MissionLevels/MissionLevels";
import { handle_change_page } from "src/utils/constants";
import { htmlDecode } from "src/utils/convertHtml";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import { tick } from "src/assets";
import DoneIcon from "@mui/icons-material/Done";
import { useFirebase } from "src/Hooks/FirebaseContaxt";
import { firebaseEventsObj } from "src/utils/firebase_utils";

export default function LevelMissions({ setLevels, Levels, type, dataArray }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { logOnFirebase } = useFirebase();

  const [upgradeButton, setUpgradeButton] = useState(false);
  const [missionLevels, setMissionLevels] = useState([]);
  const [paddingMap, setPaddingMap] = useState({});

  const get_mission_levels = (dataArray) => {
    let quest = [];
    let mission = [];
    const data = dataArray.map((item, index) => {
      if (item.type == "quest") {
        quest.push(item);
      } else {
        mission.push(item);
      }
    });
    if (type == "quest") {
      setMissionLevels(quest);
    } else {
      setMissionLevels(mission);
    }
  };
  const handleDetail = (object) => {
    if (type == "mission") {
      if (
        object.user_mission_status == "in_progress" ||
        object.user_mission_status == "completed"
      ) {
        navigate(`/mission-levels/level-details/${object._id}`);
      } else {
        navigate(`/mission-levels/level-detail/${object._id}`);
      }
    } else {
      if (
        object.user_mission_status == "in_progress" ||
        object.user_mission_status == "completed"
      ) {
        if (!object?.is_expired) {
          if (object?.quest_payment_status == "pending") {
            navigate(`/mission-levels/quest-detail/${object._id}`);
          } else {
            navigate(`/mission-levels/quest/${object._id}`);
          }
        }
      } else {
        if (!object?.is_expired) {
          navigate(`/mission-levels/quest-detail/${object._id}`);
        }
      }
    }
  };

  useEffect(() => {
    get_mission_levels(dataArray);
  }, [dataArray]);

  return (
    <>
      {missionLevels.length > 0 && (
        <div className="mission-levels mb-3">
          <div className="row">
            <h3
              className="text-capitalize mb-0"
              style={{ fontSize: "26px", color: "#ffd889" }}
            >
              {missionLevels.length > 1 ? type + "s" : type}
            </h3>

            <div className="col-12 mb-3">
              <div className="challenge-video-card mt-3">
                {missionLevels.length > 0 && (
                  <div className="row">
                    {missionLevels.map((mission) => {
                      let note_id = `note_${mission._id}`;
                      return (
                        <div
                          className="col-12 col-md-6 col-lg-4 mb-3 position-relative"
                          key={mission._id}
                        >
                          {mission.user_mission_status == "not_started" && (
                            <div
                              className="lock-icon-journey-main-container "
                              style={{ left: "-12px" }}
                            >
                              <span className="lock-icon-journey-main">
                                <LockIcon
                                  style={{ fontSize: "22px", color: "black" }}
                                />
                              </span>
                            </div>
                          )}
                          {mission.user_mission_status == "completed" && (
                            <div
                              className="lock-icon-journey-main-container-complete "
                              style={{ left: "-12px" }}
                            >
                              <span className="lock-icon-journey-main">
                                <div className="completed-images">
                                  <img src={tick} alt="Completed" />
                                </div>
                              </span>
                            </div>
                          )}
                          <div
                            className="card mission-level-card"
                            style={{
                              paddingBottom: paddingMap[note_id] || 0,
                            }}
                            onClick={() => handleDetail(mission)}
                          >
                            <div className="mission-days text-capitalize">
                              {type == "quest" ? (
                                <>
                                  {mission?.is_expired ? (
                                    "Expired"
                                  ) : (
                                    <>{mission.mission_duration} Days</>
                                  )}
                                </>
                              ) : (
                                <>{mission.mission_duration} Days</>
                              )}
                            </div>
                            <img
                              alt={mission.title}
                              src={s3baseUrl + mission.image.thumbnail_2}
                            />
                            <div className="p-3">
                              <h3>{htmlDecode(mission.title)}</h3>
                              {mission.short_description && (
                                <p className="mui-custom-card-description">
                                  {htmlDecode(mission.short_description)}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>

              {missionLevels.length == 0 && !Levels.detailed_description && (
                <RecordNotFound title="No Data Found" />
              )}
            </div>
            {type == "mission" &&
              Levels.badge_locked &&
              Levels?.configration?.upgrade_button_url &&
              Levels?.configration?.upgrade_button_text && (
                <div className="col-12">
                  <button
                    className="mc-button-contained floating-button"
                    onClick={() => {
                      logOnFirebase({
                        event: firebaseEventsObj.missionLevelUpgradeBtn,
                        replacementArr: [["{level}", Levels.tagline]],
                      });
                      handle_change_page(
                        Levels?.configration?.upgrade_button_url,
                        setUpgradeButton
                      );
                    }}
                  >
                    {upgradeButton
                      ? "Please Wait..."
                      : Levels?.configration?.upgrade_button_text}
                  </button>
                </div>
              )}
          </div>
        </div>
      )}
    </>
  );
}
