import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { IconButton, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  lessonDetail,
  lessonViewCountIncrease,
  markelessonCompleted,
  programmeViewCountIncrease,
} from "../../DAL/Programmes/Programmes";
import { htmlDecode } from "../../utils/convertHtml";
import { s3baseUrl } from "../../config/config";
import { styled } from "@mui/material/styles";
import {
  csvImage,
  docImage,
  pdfImage,
  wordImage,
  audioImage,
  xlsxImage,
  otherImage,
} from "src/assets";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import ProgrammeRecordingTab from "src/components/programmes/ProgrammeRecordingTab";
import ResourcesCardTab from "src/components/programmes/ResourcesCardTab";
import LessonNoteTab from "src/components/programmes/LessonNoteTab";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { useChat } from "src/Hooks/ChatContext/ChatContext";
import {
  get_live_event_detail,
  handleFeatureStatus,
  handle_delete_message,
  handle_send_message,
  handle_update_message,
  receive_delete_live_message_by_socket,
  receive_feature_status_by_socket,
  receive_new_live_message_by_socket,
  receive_update_live_message_by_socket,
  scroll_to_bottom,
} from "../LiveEvents/lesson_detail_socket_functions";
import ChatCardLesson from "../LiveEvents/components/Chat-Box/ChatCardLesson";
import LessonsSeekVideoPlayer from "src/components/ReactPlayers/LessonsSeekVideoPlayer";
import WaveformAudioPlayer from "src/components/WaveformAudioPlayer";
import ShowEventCommentLikes from "../Community/components/LikesPopup/ShowEventCommentLikes";
import ReactVideoDurationPlayer from "src/components/ReactPlayers/ReactVideoDurationPlayer";
import { firebaseEventsObj } from "src/utils/firebase_utils";
import { useFirebase } from "src/Hooks/FirebaseContaxt";

const ProgrammesImgStyle = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function LessonsDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [lessonsDetail, setLessonsDetail] = useState({});
  const [resourcesList, setResourcesList] = useState([]);
  const [recordingList, setRecordingList] = useState([]);
  const [videoProgressData, setVideoProgressData] = useState([]);
  const [nextLesson, setNextLesson] = useState({});
  const [previousLesson, setPreviousLesson] = useState({});
  const [notesList, setNotesList] = useState([]);
  const [completeLessonSlug, setCompleteLessonSlug] = useState("");
  const [openMarkComplete, setOpenMarkComplete] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  // chat
  const { userInfo, socket } = useContentSetting();
  const { handleSetIsChatShow } = useChat();
  const [showChat, setShowChat] = useState(false);
  const [IsChatEnable, setIsChatEnable] = useState(false);
  const [IsChatEnableButton, setIsChatEnableButton] = useState(false);
  const [isEventAvailable, setIsEventAvailable] = useState(true);
  const [isReply, setIsReply] = useState(false);
  const [parentMessage, setParentMessage] = useState({});
  const [showScrollArrow, setShowScrollArrow] = useState(false);
  const [eventDetail, setEventDetail] = useState();
  const [liveChat, setLiveChat] = useState([]);
  const [pinnedComments, setPinnedComments] = useState([]);
  const [currentEventsArray, setCurrentEventsArray] = useState([]);
  const [upcomingEventsArray, setUpcomingEventsArray] = useState([]);
  const [chatMessage, setChatMessage] = useState("");
  const [image, setImage] = React.useState("");
  const [oldImage, setOldImage] = React.useState("");
  const [previews, setPreviews] = useState("");
  const [selectedComment, setSelectedComment] = useState({});
  const [formType, setFormType] = useState("ADD");
  const [openDelete, setOpenDelete] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newChat, setNewChat] = useState(false);
  const [videoDuration, setVideoDuration] = useState(0);
  const [videoPlayed, setVideoPlayed] = useState(0);
  const [videoCompletedStatus, setVideoCompletedStatus] = useState(false);
  const [watched, setWatched] = useState(false);
  const [lessonConfiguration, setLessonConfiguration] = useState();
  const [programPurchase, setProgramPurchase] = useState(false);
  const [openLikesModal, setOpenLikesModal] = useState(false);
  const { logPageOnFirebase, logOnFirebase } = useFirebase();

  const imageLinks = {
    docx: wordImage,
    mp3: audioImage,
    pdf: pdfImage,
    csv: csvImage,
    doc: docImage,
    xlsx: xlsxImage,
    xls: xlsxImage,
    other: otherImage,
  };

  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const increaseWatchCount = async (type) => {
    const result = await lessonViewCountIncrease(type, params.lesson_slug);
    if (result.code === 200) {
      console.log(type, "increased");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  //Getting lesson detail
  const getLesonDetail = async (value) => {
    const result = await lessonDetail(params.lesson_slug);
    if (value == "markComplete") {
    } else {
      increaseWatchCount("view");
    }
    if (result.code === 200) {
      logPageOnFirebase({
        event: "lessonDetail",
        replacementArr: [["{lesson_title}", result.lesson.title]],
      });
      let data = [
        {
          recording_id: result.lesson?._id,
          video_duration: result.lesson?.video_duration,
          total_video_duration: result.lesson?.total_video_duration,
          is_complete: result.lesson?.is_complete,
        },
      ];
      setVideoProgressData(data);
      setLessonsDetail(result.lesson);
      setProgramPurchase(result.program_lock_status);
      setLessonConfiguration(result.lesson?.lesson_configration);
      setResourcesList(result.document_list);
      setIsChatEnable(result.lesson.is_chat_enable);
      setIsChatEnableButton(result.lesson.is_chat_enable);
      let recording_array = [];
      result.recording_list.map((recording) => {
        recording_array.push({
          ...recording,
          card_title: recording.title,
          card_image: s3baseUrl + recording.recording_image.thumbnail_2,
          card_short_description: recording.short_description,
          card_classes: "col-12 mt-3",
        });
      });
      setRecordingList(recording_array);
      setNotesList(result.notes_list);
      setNextLesson(result.next_lesson);
      setPreviousLesson(result.previous_lesson);
      setIsLoading(false);
    } else {
      // setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/programmes`);
    }
  };

  const handleMarkComplete = (lesson_slug) => {
    setCompleteLessonSlug(lesson_slug);
    setOpenMarkComplete(true);
  };

  const mrakCompleted = async () => {
    setOpenMarkComplete(false);
    setIsLoading(true);
    const result = await markelessonCompleted(completeLessonSlug);
    if (result.code === 200) {
      getLesonDetail("markComplete");
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const showPreviouLesson = () => {
    navigate(`/lessons/${previousLesson.lesson_slug}`);
  };
  const showNextLesson = () => {
    navigate(`/lessons/${nextLesson.lesson_slug}`);
  };
  const handleLessonQuestions = () => {
    navigate(`/lessons/questions/${lessonsDetail._id}`);
  };

  // chat
  const handleOpenReplyBox = (object) => {
    setParentMessage(object);
    setIsReply(true);
  };
  const handleRemove = () => {
    setPreviews("");
    setImage("");
  };

  const handleRemoveAll = () => {
    handleRemove();
    setChatMessage("");
    setIsSubmitting(false);
    setParentMessage({});
    setFormType("ADD");
    setIsReply(false);
  };

  const handleSendMessage = async (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    handle_send_message(
      image,
      lessonsDetail._id,
      chatMessage,
      userInfo,
      isReply,
      parentMessage,
      socket
    );
    handleRemoveAll();
  };
  const handleChatShow = () => {
    setShowChat(false);
    setIsChatEnable(false);
  };

  const handleMessageUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    handle_update_message(
      lessonsDetail,
      oldImage,
      previews,
      image,
      chatMessage,
      socket,
      selectedComment
    );
    handleRemoveAll();
  };
  const handleDuration = (duration) => {
    setVideoDuration(duration);
  };
  const handleVideoEnded = (value) => {
    setVideoCompletedStatus(true);
  };

  const handleDeleteLiveComment = async () => {
    handle_delete_message(lessonsDetail, selectedComment, socket);
    setOpenDelete(false);
  };

  const handleCommentEdit = (comment) => {
    setSelectedComment(comment);
    setChatMessage(comment.message);
    if (comment.file_url) {
      setPreviews(s3baseUrl + comment.file_url);
      setOldImage(comment.file_url);
    } else {
      setImage("");
      setPreviews("");
      setOldImage("");
    }
    setFormType("EDIT");
  };

  const handleCommentPin = (comment) => {
    handleFeatureStatus(comment, lessonsDetail, socket);
  };

  const handleCommentDelete = (comment) => {
    setSelectedComment(comment);
    setOpenDelete(true);
  };

  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };

  const handleOpenLikes = (comment) => {
    setSelectedComment(comment);
    setOpenLikesModal(true);
  };

  const handleLikeComment = (comment, parentComment) => {
    const event_id = lessonsDetail._id;
    const comment_id = comment._id;
    const member = {
      _id: userInfo._id,
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      profile_image: userInfo.profile_image,
    };

    let socket_object = {
      event_id,
      member,
      comment_id,
    };

    if (parentComment) {
      socket_object.parent_message = parentComment._id;
    }
    socket.emit("live_event_message_like", socket_object);
  };

  const handleLikeParentComment = (data) => {
    setLiveChat((old) => {
      return old.map((old_comment) => {
        let like_count = old_comment.like_count;
        let is_liked = old_comment.is_liked;
        if (data.action == "like") {
          like_count = like_count + 1;
        } else {
          like_count = like_count - 1;
        }

        if (data.member._id == userInfo._id) {
          is_liked = !is_liked;
        }

        if (old_comment._id == data.comment_id) {
          return {
            ...old_comment,
            like_count: like_count,
            is_liked: is_liked,
          };
        } else {
          return old_comment;
        }
      });
    });
  };

  const handleLikeChildComment = (data) => {
    setLiveChat((old) => {
      return old.map((old_comment) => {
        if (old_comment._id == data.parent_message) {
          let replies = old_comment.replies;
          let new_replies = replies.map((reply) => {
            if (reply._id == data.comment_id) {
              let is_liked = reply.is_liked;
              let like_count = reply.like_count;
              if (data.action == "like") {
                like_count = like_count + 1;
              } else {
                like_count = like_count - 1;
              }

              if (data.member._id == userInfo._id) {
                is_liked = !is_liked;
              }

              return {
                ...reply,
                like_count: like_count,
                is_liked: is_liked,
              };
            } else {
              return reply;
            }
          });
          return {
            ...old_comment,
            replies: new_replies,
          };
        } else {
          return old_comment;
        }
      });
    });
  };

  const handle_like_message_receiver = (data) => {
    if (data.parent_message) {
      handleLikeChildComment(data);
    } else {
      handleLikeParentComment(data);
    }
  };

  // chat end

  useEffect(() => {
    getLesonDetail();
  }, [params.lesson_slug]);

  useEffect(() => {
    if (lessonsDetail) {
      // event handling
      socket.emit("live_event_room", lessonsDetail._id);
      socket.on("lesson_message_receiver", (data) => {
        receive_new_live_message_by_socket(
          data,
          lessonsDetail._id,
          userInfo,
          setLiveChat,
          setNewChat
        );
      });

      socket.on("lesson_message_update_receiver", (data) => {
        receive_update_live_message_by_socket(
          data,
          userInfo,
          setLiveChat,
          setPinnedComments
        );
      });

      socket.on("lesson_chat_message_delete_receiver", (data) => {
        receive_delete_live_message_by_socket(
          data,
          setLiveChat,
          setPinnedComments
        );
      });

      socket.on("lesson_make_message_featured_unfeatured_receiver", (data) => {
        receive_feature_status_by_socket(
          data,
          lessonsDetail._id,
          setEventDetail,
          setLiveChat,
          setPinnedComments,
          setNewChat,
          setShowScrollArrow,
          userInfo,
          setIsLoading
        );
      });
      socket.on("live_event_message_like_receiver", (data) => {
        handle_like_message_receiver(data);
      });
    }

    return () => {
      socket.off("live_event_message_like_receiver");
      socket.off("lesson_message_receiver");
      socket.off("lesson_message_update_receiver");
      socket.off("lesson_chat_message_delete_receiver");
      socket.off("lesson_make_message_featured_unfeatured_receiver");
    };
  }, [lessonsDetail]);

  useEffect(() => {
    setTimeout(() => {
      get_live_event_detail(
        lessonsDetail._id,
        setIsEventAvailable,
        params.category_slug,
        setTabValue,
        setEventDetail,
        setCurrentEventsArray,
        setUpcomingEventsArray,
        setShowChat,
        setIsLoading,
        setLiveChat,
        setPinnedComments,
        setNewChat,
        setShowScrollArrow,
        userInfo
      );
      setInterval(function () {
        setRefresh((r) => !r);
      }, 10000);
      return () => {
        handleSetIsChatShow(false);
      };
    }, 1000);
  }, [lessonsDetail]);

  useEffect(() => {
    if (watched == false) {
    } else {
      increaseWatchCount("watch");
    }
  }, [watched]);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const handleTabClick = (e, row) => {
    logOnFirebase({
      event: firebaseEventsObj.onLessonTab,
      replacementArr: [["{tab_title}", row.title]],
      obj: { lesson: lessonsDetail.title, tab: row.title },
    });
  };

  const TABS_OPTIONS = [];
  const numAscending =
    lessonConfiguration &&
    [...lessonConfiguration].sort((a, b) => +a.order - +b.order);
  numAscending?.map((value, i) => {
    if (value?.recordings_tab_title_status) {
      TABS_OPTIONS.push({
        title: value?.recordings_tab_title,
        component: (
          <ProgrammeRecordingTab
            data={recordingList}
            lesson_slug={params.lesson_slug}
          />
        ),
        onClick: handleTabClick,
      });
    }
    if (value?.resources_tab_title_status) {
      TABS_OPTIONS.push({
        title: value?.resources_tab_title,
        component: (
          <ResourcesCardTab data={resourcesList} imageLink={imageLinks} />
        ),
        onClick: handleTabClick,
      });
    }
    if (value?.notes_tab_title_status) {
      TABS_OPTIONS.push({
        title: value?.notes_tab_title,
        component: (
          <LessonNoteTab
            notesList={notesList}
            lesson_slug={params.lesson_slug}
            progran_slug={
              lessonsDetail?.program?.length > 0
                ? lessonsDetail?.program[0]?._id?.program_slug
                : ""
            }
            getLesonDetail={getLesonDetail}
          />
        ),
        onClick: handleTabClick,
      });
    }
  });

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 lesson-detail-page">
          <div className="d-flex">
            <span>
              <IconButton
                className="back-screen-button"
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />
              </IconButton>
            </span>

            <h1 className="programmes-heading ms-2">
              {htmlDecode(lessonsDetail.title)}
            </h1>
          </div>

          {lessonsDetail.lesson_completion_status === true ? (
            <button
              className="mark-contained-button float-end mt-1 disabled"
              disabled="disabled"
            >
              Completed
            </button>
          ) : (
            <button
              className="small-contained-button float-end mt-1"
              onClick={() => {
                handleMarkComplete(lessonsDetail.lesson_slug);
              }}
            >
              Mark Complete
            </button>
          )}
        </div>
      </div>

      <div className="row media-margin align-items-center">
        {/* showChat ? "col-12 col-lg-8" : in chat case place it in className*/}
        <div
          className={IsChatEnable ? "col-12 col-lg-8" : "col-12"}
          id="video-col"
        >
          {lessonsDetail.landing_lesson_video_url ? (
            <>
              {/* <LessonsSeekVideoPlayer
              url={lessonsDetail.landing_lesson_video_url}
              handleDuration={handleDuration}
              setVideoPlayed={setVideoPlayed}
              lesson_slug={params.lesson_slug}
              handleVideoEnded={handleVideoEnded}
              isLoading={isLoading}
              setWatched={setWatched}
            /> */}
              <ReactVideoDurationPlayer
                url={lessonsDetail.landing_lesson_video_url}
                type={"lesson"}
                SaveInfo={"lesson"}
                value={lessonsDetail}
                setVideoProgressData={setVideoProgressData}
                videoProgressData={videoProgressData}
              />
            </>
          ) : (
            <ProgrammesImgStyle
              src={s3baseUrl + lessonsDetail?.lesson_images?.thumbnail_1}
            />
          )}
        </div>
        {IsChatEnable && (
          <ChatCardLesson
            eventDetail={eventDetail}
            pinnedComments={pinnedComments}
            handleCommentDelete={handleCommentDelete}
            handleCommentEdit={handleCommentEdit}
            handleCommentPin={handleCommentPin}
            liveChat={liveChat}
            handleOpenReplyBox={handleOpenReplyBox}
            newChat={newChat}
            setNewChat={setNewChat}
            setShowScrollArrow={setShowScrollArrow}
            showScrollArrow={showScrollArrow}
            isReply={isReply}
            chatMessage={chatMessage}
            setChatMessage={setChatMessage}
            formType={formType}
            handleRemoveAll={handleRemoveAll}
            previews={previews}
            handleRemove={handleRemove}
            handleUpload={handleUpload}
            isSubmitting={isSubmitting}
            handleSendMessage={handleSendMessage}
            handleMessageUpdate={handleMessageUpdate}
            setIsLoading={setIsLoading}
            handleLikeComment={handleLikeComment}
            handleOpenLikes={handleOpenLikes}
          />
        )}

        <div className="col-12 text-end pe-3 mt-4 mb-3">
          {lessonsDetail.question_configration?.show_question &&
            lessonsDetail.question_configration?.button_text &&
            (lessonsDetail.question_configration?.is_show_qestion_all_time ||
              +lessonsDetail.question_configration?.question_show_after <
                videoPlayed / 60) && (
              <button
                className="small-contained-button"
                onClick={handleLessonQuestions}
              >
                {lessonsDetail.question_configration?.button_text}
              </button>
            )}
          {IsChatEnableButton &&
            (showChat ? (
              <button
                onClick={handleChatShow}
                className="ms-3 comment-submit-button"
              >
                Hide Chat
              </button>
            ) : (
              <button
                onClick={() => {
                  setShowChat(true);
                  setIsChatEnable(true);
                  setTimeout(() => {
                    scroll_to_bottom(setNewChat, setShowScrollArrow);
                  }, 500);
                }}
                className="ms-3 comment-submit-button"
              >
                Show Chat
              </button>
            ))}
        </div>

        {programPurchase ? (
          ""
        ) : (
          <div className="col-12 section-space">
            {Object.entries(previousLesson).length > 0 && (
              <button
                className="small-contained-button"
                onClick={showPreviouLesson}
              >
                {"<<"} Previous
              </button>
            )}

            {Object.entries(nextLesson).length > 0 && (
              <button
                className="small-contained-button float-end"
                onClick={showNextLesson}
              >
                Next {">>"}
              </button>
            )}
          </div>
        )}

        {lessonsDetail.audio_file && (
          <div className="waveAudio mt-3">
            <audio
              controlsList="nodownload"
              className="w-100"
              src={s3baseUrl + lessonsDetail.audio_file}
              controls
            />
            {/* <WaveformAudioPlayer
              url={s3baseUrl + lessonsDetail.audio_file}
              title=""
              hideVideo={false}
              loading={false}
            /> */}
          </div>
        )}
        <div className="col-12 section-space set-image-center">
          <div
            className="lesson_detailed_description"
            dangerouslySetInnerHTML={{
              __html: lessonsDetail.detailed_description,
            }}
          ></div>
        </div>
        <div className="col-12 section-space">
          <MUICustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChange}
          />
        </div>
      </div>
      <CustomConfirmation
        open={openMarkComplete}
        setOpen={setOpenMarkComplete}
        title={"Are you sure you want to mark this lesson as completed?"}
        handleAgree={mrakCompleted}
      />
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete live comment?"}
        handleAgree={handleDeleteLiveComment}
      />
      <ShowEventCommentLikes
        open={openLikesModal}
        setOpen={setOpenLikesModal}
        comment_id={selectedComment._id}
      />
    </div>
  );
}

export default LessonsDetail;
