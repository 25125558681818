import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { login_by_admin_api } from "../DAL/Login/Login";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { _get_is_seen_video_localStorage } from "src/DAL/localStorage/localStorage";
import { CircularProgress } from "@mui/material";
import { loaderImage } from "src/assets";
import { encryptToken } from "src/utils/constants";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function LoginByAdmin() {
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);

  const { handleUserInfo, handleConsultantInfo, START_SOCKET_FORCEFULLY } =
    useContentSetting();

  const handleSubmitLogin = async () => {
    setIsLoading(true);
    const data = {
      token: params.token,
      user_type: "client",
    };
    localStorage.removeItem(`UpgradePopCount`);
    const result = await login_by_admin_api(data);
    if (result.code === 200) {
      localStorage.setItem(`user_data`, JSON.stringify(result.member));
      localStorage.setItem("token", encryptToken(result.token));
      START_SOCKET_FORCEFULLY(result.member._id);
      handleUserInfo(result.member);

      if (result.member.consultant) {
        localStorage.setItem(
          `consultant_data`,
          JSON.stringify(result.member.consultant)
        );
        handleConsultantInfo(result.member.consultant);
      } else {
        localStorage.setItem(`consultant_data`, JSON.stringify({}));
      }
      navigate("/mission-control", { replace: true });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate("/login");
    }
  };

  useEffect(() => {
    handleSubmitLogin();
  }, []);

  if (isLoading) {
    return (
      <div className="image-loader">
        <div>
          <img src={loaderImage} className="mx-auto" />
          <h2>You are now connecting to The Source please wait.</h2>
        </div>
      </div>
    );
  }
}
