import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import CardInformation from "src/components/PaymentRequests/CardInformation";
import { convertCurrencyToSign, decryptToken } from "src/utils/constants";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  add_free_member_by_web,
  confirm_one_time_payment_for_web,
  confirm_subscription_incomplete_for_web,
  get_web_intent_client_secret_for_one_time,
  pay_now_for_subscription_web,
} from "src/DAL/WebPages/web-pages";
import { useStripe } from "@stripe/react-stripe-js";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import QuestCardInformation from "src/components/MissionLevels/QuestCardInformation";
import StripeCheckOut from "./StripeCheckOut";
import { PaymentContext } from "src/Hooks/PaymentContext/PaymentContext";
import { boolean } from "yup";
import { confirm_one_time_payment_for_web_v1 } from "src/DAL/web-pages/web-pages";
import { useFirebase } from "src/Hooks/FirebaseContaxt";
import { firebaseEventsObj } from "src/utils/firebase_utils";

const QuestPaymentPage = (props) => {
  const {
    handlePayment,
    isLoadingCard,
    setIsLoadingCard,
    setPaymentPlan,
    isPurchased,
    setIsPurchased,
  } = useContext(PaymentContext);
  const { apiData, planData, plan } = props;
  const navigate = useNavigate();
  const { category_slug, plan_id } = useParams();
  const stripe = useStripe();
  const { logOnFirebase } = useFirebase();
  const [selectedPage, setSelectedPage] = useState(0);
  const { userInfo } = useContentSetting();
  const [clientSecret, setClientSecret] = useState("");
  const [resPostData, setResPostData] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const handleChangeRadios = (event) => {
    setSelectedValue(event);
  };

  const { enqueueSnackbar } = useSnackbar();

  let breadCrumbMenu = [
    {
      title: "Levels",
      navigation: `/mission-levels`,
      active: false,
    },
    {
      title: apiData?.title,
      navigation: `/mission-levels/quest-detail/${apiData._id}`,
      active: false,
    },

    {
      title: "Payment",
      navigation: ``,
      active: true,
    },
  ];

  const confirmCardPayment = async () => {
    setIsLoadingCard(true);
    let data = {
      mission_id: plan_id,
      plan_option_id: selectedValue._id ?? "",
      request_by: "web",
    };
    const result = await confirm_one_time_payment_for_web_v1(data);
    if (result.code === 200) {
      logOnFirebase({
        event: firebaseEventsObj.onQuestStarted,
        replacementArr: [["{mission_title}", apiData.title]],
      });
      logOnFirebase({
        event: firebaseEventsObj.onQuestPurchasedFree,
        replacementArr: [["{mission_title}", apiData?.title]],
      });
      setIsLoadingCard(false);
      navigate(`/mission-levels/quest/${plan_id}`);
    } else {
      setIsLoadingCard(false);
      enqueueSnackbar(result.message, {
        variant: "error",
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedValue?.is_free) {
      confirmCardPayment();
    } else {
      let postData = {
        mission_id: plan_id,
      };
      if (plan && plan?.plan_options.length) {
        postData.plan_option_id = !selectedValue.is_plan_option
          ? ""
          : selectedValue?._id;
      }
      let error = await handlePayment(postData);
      if (error) {
        enqueueSnackbar(error, { variant: "error" });
      }
    }
  };

  useEffect(() => {
    setPaymentPlan(plan);
    if (plan && plan.plan_options.length > 0) {
      setSelectedValue(plan.plan_options[0]);
    }
  }, [plan]);

  useEffect(() => {
    if (isPurchased) {
      logOnFirebase({
        event: firebaseEventsObj.onQuestPurchasedPaid,
        replacementArr: [["{mission_title}", apiData?.title]],
      });
      logOnFirebase({
        event: firebaseEventsObj.onQuestStarted,
        replacementArr: [["{mission_title}", apiData.title]],
      });
      setIsPurchased(false);
    }
  }, [isPurchased]);

  return (
    <div className="container">
      <form className="row" onSubmit={handleSubmit}>
        <span className="d-flex mb-2" style={{ alignItems: "baseline" }}>
          <div className="d-flex challenge-video-detail-title">
            <div className="mission-level-heading mb-3">
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </div>
          </div>
        </span>

        <>
          <div className="col-12 col-md-6 ps-0">
            <div
              className="payment-plan-box"
              style={{ border: "1px solid transparent" }}
            >
              <div className="plan-title plan-title-new">
                <h3 style={{ color: "#ffd889" }}>{apiData?.title}</h3>
                <h3 className="amount" style={{ color: "#ffd889" }}></h3>
              </div>
              <div className="plan-description quest-plan-description p-3 pt-0">
                <div
                  dangerouslySetInnerHTML={{
                    __html: selectedValue.description,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-3 mt-md-0">
            <div className="mt-2">
              {plan && plan?.plan_options.length > 0 && (
                <h3 className="mb-0">
                  {plan?.plan_options.length > 1
                    ? "Payment Options"
                    : "Payment Option"}
                </h3>
              )}
              {plan &&
                plan?.plan_options.length > 0 &&
                plan?.plan_options.map((item, index) => {
                  return (
                    <>
                      <div
                        className="card mt-4 p-2 position-relative plan-option"
                        onClick={() => handleChangeRadios(item)}
                      >
                        {item?.discount_type == "percentage" &&
                        item.discount_percentage > 0 ? (
                          <span className="discount-title">
                            {item.discount_percentage}% DISCOUNT
                          </span>
                        ) : item?.discount_type == "fixed" &&
                          item.discount_amount > 0 ? (
                          <span className="discount-title">
                            {convertCurrencyToSign(plan.plan_currency)}
                            {item.discount_amount} DISCOUNT
                          </span>
                        ) : (
                          ""
                        )}
                        <div className="d-flex justify-content-between w-100">
                          <div className="d-flex quest-plan-container">
                            <Radio
                              checked={selectedValue._id == item._id}
                              value={item._id}
                            />
                            <div>
                              <h3 className="quest-plan-heading text-capitalize">
                                {item.option_title}
                              </h3>
                              {item.use_with_coins && (
                                <p
                                  className="mb-0"
                                  style={{
                                    fontSize: "12px",
                                    color: "#e4c073",
                                  }}
                                >
                                  This payment will deduct{" "}
                                  {item?.coins_to_deduct} coins from your
                                  wallet.
                                </p>
                              )}
                            </div>
                          </div>
                          {item?.is_free ? (
                            <p className="mt-3 me-2">Free</p>
                          ) : (
                            <p className="mt-3 me-2">
                              {convertCurrencyToSign(plan.plan_currency) +
                                item?.total_amount_after_discount?.toFixed(2)}
                            </p>
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
            {selectedValue?.is_free && (
              <div className="text-end">
                <button
                  className="small-contained-button mt-3 mb-4"
                  type="submit"
                >
                  {isLoadingCard
                    ? "Please wait..."
                    : apiData?.content_settings
                        ?.free_quest_purchase_button_text ?? "Start Now"}
                </button>
              </div>
            )}
            {!selectedValue?.is_free && (
              <StripeCheckOut
                page_result={{}}
                getPirces={{
                  finalTotal: selectedValue?.total_amount_after_discount ?? 0,
                }}
                labelProps={{ style: { left: "20px" } }}
                selectedValue={selectedValue}
                buttonText={
                  apiData?.content_settings?.paid_quest_purchase_button_text
                }
                plan={plan}
                stripeOptions={{
                  hidePostalCode: true,
                  style: {
                    base: {
                      padding: "20px",
                      color: "black",
                    },
                  },
                }}
                btnProps={{
                  className: "payment-login-btn black-btn btn-payment",
                }}
              />
            )}
          </div>
        </>
      </form>
    </div>
  );
};

export default QuestPaymentPage;
