import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { IconButton, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  programmModuleDetail,
  programmeViewCountIncrease,
} from "../../DAL/Programmes/Programmes";
import { htmlDecode } from "../../utils/convertHtml";
import { s3baseUrl } from "../../config/config";
import { styled } from "@mui/material/styles";
import LessonsCardTab from "src/components/programmes/LessonsCardTab";
import ReactVideoPlayerNew from "src/components/ReactPlayers/ReactVideoPlayerNew";
import { useFirebase } from "src/Hooks/FirebaseContaxt";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function ProgrammesModuleDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const { logPageOnFirebase } = useFirebase();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [programmesDetail, setProgrammesDetail] = useState({});
  const [lessonsList, setLessonsList] = useState([]);
  const [modulesInfo, setModuleInfo] = useState();
  const [videoProgressData, setVideoProgressData] = useState([]);
  const [watched, setWatched] = useState(false);

  const ProgrammesImgStyle = styled("img")({
    width: "100%",
    height: "100%",
    objectFit: "cover",
  });

  const increaseWatchCount = async (type) => {
    const result = await programmeViewCountIncrease(type, params.slug);
    if (result.code === 200) {
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const getProgrammesDetail = async () => {
    const result = await programmModuleDetail(params.slug);
    increaseWatchCount("view");
    if (result.code === 200) {
      setProgrammesDetail(result.program);
      logPageOnFirebase({
        event: "programModule",
        replacementArr: [["{module_title}", result.program_module.title]],
      });
      let lessons_array = [];
      result.lesson.map((lesson) => {
        lessons_array.push({
          ...lesson,
          card_title: lesson.title,
          card_image: s3baseUrl + lesson.lesson_images?.thumbnail_3,
          card_short_description: lesson.short_description,
          card_classes: "col-12 mt-3",
          start_time: "",
        });
      });
      setModuleInfo(result.program_module);
      let data = {
        _id: result.program_module?._id,
        video_duration: result.program_module?.video_duration,
        total_video_duration: result.program_module?.total_video_duration,
        is_complete: result.program_module?.is_complete,
      };
      setVideoProgressData(data);
      setLessonsList(lessons_array);

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const get_programme_content = () => {
    if (programmesDetail?.program_lock_status === true) {
      return {
        video_url: programmesDetail?.locked_program_info.video_url,
        detailed_description:
          programmesDetail?.locked_program_info?.detailed_description,
        image: programmesDetail?.locked_program_info?.image,
      };
    } else {
      return {
        video_url: modulesInfo?.video_url,
        audio_file: modulesInfo?.audio_file
          ? s3baseUrl + modulesInfo?.audio_file
          : "",
        detailed_description: modulesInfo?.detailed_description,
        image: modulesInfo?.program_images?.thumbnail_1,
      };
    }
  };

  useEffect(() => {
    getProgrammesDetail();
  }, []);

  useEffect(() => {
    if (watched == false) {
    } else {
      increaseWatchCount("watch");
    }
  }, [watched]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() =>
              navigate(
                `/programmes/${programmesDetail?.program_slug}/module-tab`
              )
            }
          >
            <ArrowBackIcon />
          </IconButton>

          {programmesDetail?.program_lock_status === false ? (
            <button
              className="small-contained-button float-end mt-1"
              onClick={() =>
                navigate(`/programmes/modules/${params.slug}/lessons`)
              }
            >
              View Lessons
            </button>
          ) : (
            <button
              className="small-contained-button float-end mt-1 disabled"
              disabled="disabled"
            >
              View Lessons
            </button>
          )}
        </div>
      </div>
      <div className="row section-space">
        <div className="col-12">
          <h1>{htmlDecode(modulesInfo?.title)}</h1>
        </div>
      </div>
      <div className="row media-margin">
        <div className="col-12">
          {get_programme_content().video_url ? (
            <ReactVideoPlayerNew
              url={get_programme_content().video_url}
              setWatched={setWatched}
              type={"program_pillars"}
              value={videoProgressData}
              setVideoProgressData={setVideoProgressData}
              videoProgressData={videoProgressData}
            />
          ) : get_programme_content().image ? (
            <ProgrammesImgStyle
              src={s3baseUrl + get_programme_content().image}
            />
          ) : (
            ""
          )}
        </div>
        {get_programme_content().audio_file && (
          <div className="col-12 section-space">
            <audio
              controlsList="nodownload"
              className="w-100"
              src={get_programme_content().audio_file}
              controls
            />
          </div>
        )}

        <div className="col-12 section-space set-image-center">
          <div
            dangerouslySetInnerHTML={{
              __html: get_programme_content().detailed_description,
            }}
          ></div>
        </div>
      </div>

      <div className="row section-space">
        <div className="col-12">
          <LessonsCardTab
            lessons={lessonsList}
            programLockStatus={programmesDetail?.program_lock_status}
            programSlug={params.slug}
            programmesDetail={programmesDetail}
          />
        </div>
      </div>
    </div>
  );
}

export default ProgrammesModuleDetail;
