import { CircularProgress } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { _dynamite_event_category_video_list_chat } from "src/DAL/Inception/Inception";
import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "src/config/config";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import Community from "../Community/Community";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import { useChat } from "src/Hooks/ChatContext/ChatContext";

import {
  get_live_event_detail,
  handleFeatureStatus,
  handle_delete_message,
  handle_send_message,
  handle_update_message,
  receive_delete_live_message_by_socket,
  receive_feature_status_by_socket,
  receive_new_live_message_by_socket,
  receive_update_live_message_by_socket,
} from "../LiveEvents/live_event_functions";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { get_date_with_user_time_zone } from "src/utils/constants";
import ShowEventCommentLikes from "../Community/components/LikesPopup/ShowEventCommentLikes";
import { firebasePrefixes } from "src/utils/firebase_utils";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function MissionFeedDetailNew({
  streakLeaderBoard,
  setStreakLeaderBoard,
  loadMorePath,
  setLoadMorePath,
  loadMorePathCoin,
  setLoadMorePathCoin,
  coinLeaderBoard,
  setCoinLeaderBoard,
  communityMission,
  missionDetail,
  type,
}) {
  const { userInfo, socket, adminTimeZone } = useContentSetting();

  const {
    get_users_list_against_event,
    handleSetIsChatShow,
    handleSetEventSlug,
    setOfflinePortalMembers,
    setOnlinePortalMembers,
    setOnlinePortalMembersCount,
    setOfflinePortalMembersCount,
    onlinePortalMembers,
    offlinePortalMembers,
  } = useChat();
  const { mission__id } = useParams();
  const [showChat, setShowChat] = useState(true);
  const [newChat, setNewChat] = useState(false);
  const [isReply, setIsReply] = useState(false);
  const [parentMessage, setParentMessage] = useState({});
  const [showScrollArrow, setShowScrollArrow] = useState(false);
  const [eventDetail, setEventDetail] = useState();
  const [isMemberFree, setIsMemberFree] = useState();
  const [liveChat, setLiveChat] = useState([]);
  const [pinnedComments, setPinnedComments] = useState([]);
  const [currentEventsArray, setCurrentEventsArray] = useState([]);
  const [likesArray, setLikesArray] = useState([]);
  const [openLikesModal, setOpenLikesModal] = useState(false);
  const [upcomingEventsArray, setUpcomingEventsArray] = useState([]);
  const [chatMessage, setChatMessage] = useState("");
  const [image, setImage] = React.useState("");
  const [oldImage, setOldImage] = React.useState("");
  const [previews, setPreviews] = useState("");
  const [selectedComment, setSelectedComment] = useState({});
  const [formType, setFormType] = useState("ADD");
  const [openDelete, setOpenDelete] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isEventAvailable, setIsEventAvailable] = useState(true);
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [remainTime, setRemainTime] = useState(0);
  const [eventDate, setEventDate] = useState(null);
  const [showTimer, setShowTimer] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const handleEmojiSelect = (emoji) => {
    setChatMessage((msg) => msg + emoji.native);
  };

  const handleTabClick = (e, row) => {
    if (row.tab_slug == "the-source") {
      window.history.replaceState(
        null,
        null,
        `/live-events/${"6477609bfe2e9e66b7e5ae22"}`
      );
    } else {
      window.history.replaceState(
        null,
        null,
        `/live-events/${"6477609bfe2e9e66b7e5ae22"}/${row.tab_slug}`
      );
    }
  };

  const handleChangeTabs = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleOpenReplyBox = (object) => {
    setParentMessage(object);
    setIsReply(true);
  };

  const handleRemove = () => {
    setPreviews("");
    setImage("");
  };

  const handleRemoveAll = () => {
    handleRemove();
    setChatMessage("");
    setIsSubmitting(false);
    setParentMessage({});
    setFormType("ADD");
    setIsReply(false);
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setShowEmojiPicker(false);
    handle_send_message(
      image,
      eventDetail,
      chatMessage,
      userInfo,
      isReply,
      parentMessage,
      socket
    );
    handleRemoveAll();
  };

  const handleMessageUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setShowEmojiPicker(false);
    handle_update_message(
      eventDetail,
      oldImage,
      previews,
      image,
      chatMessage,
      socket,
      selectedComment
    );
    handleRemoveAll();
  };

  const handleDeleteLiveComment = async () => {
    handle_delete_message(eventDetail, selectedComment, socket);
    setOpenDelete(false);
  };

  const handleCommentEdit = (comment) => {
    setSelectedComment(comment);
    setChatMessage(comment.message);
    if (comment.file_url) {
      setPreviews(s3baseUrl + comment.file_url);
      setOldImage(comment.file_url);
    } else {
      setImage("");
      setPreviews("");
      setOldImage("");
    }
    setFormType("EDIT");
  };

  const handleCommentPin = (comment) => {
    handleFeatureStatus(comment, eventDetail, socket);
  };

  const handleCommentDelete = (comment) => {
    setSelectedComment(comment);
    setOpenDelete(true);
  };

  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };
  const getOneDigit = (digi) => {
    let reminder = digi % 10;
    let deviser = digi / 10;
    let decimalPart = Math.floor(deviser);
    return { reminder, decimalPart };
  };

  const handleLikeComment = (comment, parentComment) => {
    const event_id = eventDetail._id;
    const comment_id = comment._id;
    const video_id = eventDetail.feature_video._id;
    const member = {
      _id: userInfo._id,
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      profile_image: userInfo.profile_image,
    };

    let socket_object = {
      event_id,
      video_id,
      member,
      comment_id,
    };

    if (parentComment) {
      socket_object.parent_message = parentComment._id;
    }
    socket.emit("live_event_message_like", socket_object);
  };

  const handleAskQuestion = () => {
    if (eventDetail) {
      let question_configration =
        eventDetail.feature_video?.question_configration;
      if (
        question_configration &&
        question_configration.show_question &&
        question_configration.button_text
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  const handleLikeParentComment = (data) => {
    setLiveChat((old) => {
      return old.map((old_comment) => {
        let like_count = old_comment.like_count;
        let is_liked = old_comment.is_liked;
        if (data.action == "like") {
          like_count = like_count + 1;
        } else {
          like_count = like_count - 1;
        }

        if (data.member._id == userInfo._id) {
          is_liked = !is_liked;
        }

        if (old_comment._id == data.comment_id) {
          return {
            ...old_comment,
            like_count: like_count,
            is_liked: is_liked,
          };
        } else {
          return old_comment;
        }
      });
    });
    setPinnedComments((old) => {
      return old.map((old_comment) => {
        let like_count = old_comment.like_count;
        let is_liked = old_comment.is_liked;
        if (data.action == "like") {
          like_count = like_count + 1;
        } else {
          like_count = like_count - 1;
        }

        if (data.member._id == userInfo._id) {
          is_liked = !is_liked;
        }

        if (old_comment._id == data.comment_id) {
          return {
            ...old_comment,
            like_count: like_count,
            is_liked: is_liked,
          };
        } else {
          return old_comment;
        }
      });
    });
  };

  const handleLikeChildComment = (data) => {
    setLiveChat((old) => {
      return old.map((old_comment) => {
        if (old_comment._id == data.parent_message) {
          let replies = old_comment.replies;
          let new_replies = replies.map((reply) => {
            if (reply._id == data.comment_id) {
              let is_liked = reply.is_liked;
              let like_count = reply.like_count;
              if (data.action == "like") {
                like_count = like_count + 1;
              } else {
                like_count = like_count - 1;
              }

              if (data.member._id == userInfo._id) {
                is_liked = !is_liked;
              }

              return {
                ...reply,
                like_count: like_count,
                is_liked: is_liked,
              };
            } else {
              return reply;
            }
          });
          return {
            ...old_comment,
            replies: new_replies,
          };
        } else {
          return old_comment;
        }
      });
    });
    setPinnedComments((old) => {
      return old.map((old_comment) => {
        if (old_comment._id == data.parent_message) {
          let replies = old_comment.replies;
          let new_replies = replies.map((reply) => {
            if (reply._id == data.comment_id) {
              let is_liked = reply.is_liked;
              let like_count = reply.like_count;
              if (data.action == "like") {
                like_count = like_count + 1;
              } else {
                like_count = like_count - 1;
              }

              if (data.member._id == userInfo._id) {
                is_liked = !is_liked;
              }

              return {
                ...reply,
                like_count: like_count,
                is_liked: is_liked,
              };
            } else {
              return reply;
            }
          });
          return {
            ...old_comment,
            replies: new_replies,
          };
        } else {
          return old_comment;
        }
      });
    });
  };

  const handle_like_message_receiver = (data) => {
    if (data.parent_message) {
      handleLikeChildComment(data);
    } else {
      handleLikeParentComment(data);
    }
  };

  const handleOpenLikes = (comment) => {
    setSelectedComment(comment);
    setOpenLikesModal(true);
  };

  const get_update_time_with_time_zone = (date) => {
    let new_date = get_date_with_user_time_zone(
      date,
      "YYYY-MM-DD HH:mm:ss",
      userInfo.time_zone,
      adminTimeZone
    );
    return Date.parse(new_date);
  };
  let deadline1 = eventDetail?.event_timer_configration?.event_date
    ? moment(eventDetail?.event_timer_configration.event_date).format(
        "YYYY-MM-DD"
      )
    : "";
  let fDate = deadline1
    ? deadline1 + " " + eventDetail?.event_timer_configration?.event_time
    : "";

  let date_now = moment();
  const currentTimeInZone = date_now
    .tz(userInfo.time_zone)
    .format("YYYY-MM-DD HH:mm:ss");

  const deadline = moment(fDate);
  const currentTime = moment(currentTimeInZone);

  const getTime = () => {
    const time = deadline ? Date.parse(deadline) - Date.parse(currentTime) : "";
    const timeRemains = deadline
      ? Date.parse(deadline) - get_update_time_with_time_zone(Date.now())
      : "";
    setRemainTime(timeRemains);
    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  // useEffect(() => {
  //   setEventDate(deadline?._i);

  //   let timea = new Date(eventDate?._i).getTime();
  //   let timeb = new Date().getTime();
  //   let resultAccess = timea.toString() > timeb.toString();

  //   setShowTimer(resultAccess);
  //   if (resultAccess == false) {
  //     setRemainTime(0);
  //     navigate(`/live-events-locked/${eventDetail._id}`);
  //   } else {
  //     new Date(eventDate).getTime() >= new Date().getTime();
  //     const interval = setInterval(() => getTime(deadline), 1000);
  //     return () => clearInterval(interval);
  //   }
  // }, [deadline]);

  useEffect(() => {
    if (eventDetail) {
      // event handling
      socket.emit("live_event_room", eventDetail._id);
      socket.on("live_event_message_receiver", (data) => {
        receive_new_live_message_by_socket(
          data,
          eventDetail,
          userInfo,
          setLiveChat,
          setNewChat
        );
      });
      socket.on("live_event_message_like_receiver", (data) => {
        handle_like_message_receiver(data);
      });

      socket.on("live_event_message_update_receiver", (data) => {
        receive_update_live_message_by_socket(
          data,
          userInfo,
          setLiveChat,
          setPinnedComments
        );
      });

      socket.on("live_event_message_delete_receiver", (data) => {
        receive_delete_live_message_by_socket(
          data,
          setLiveChat,
          setPinnedComments
        );
      });

      socket.on("make_message_featured_unfeatured_receiver", (data) => {
        receive_feature_status_by_socket(
          data,
          eventDetail,
          setEventDetail,
          setLiveChat,
          setPinnedComments,
          setNewChat,
          setShowScrollArrow,
          userInfo,
          setIsLoading
        );
      });
    }

    return () => {
      socket.off("member_online_for_portal");
      socket.off("live_event_message_receiver");
      socket.off("live_event_message_like_receiver");
      socket.off("live_event_message_update_receiver");
      socket.off("live_event_message_delete_receiver");
      socket.off("make_message_featured_unfeatured_receiver");
    };
  }, [eventDetail]);

  useEffect(() => {
    get_live_event_detail(
      "6477609bfe2e9e66b7e5ae22",
      setIsEventAvailable,
      params.category_slug,
      setTabValue,
      setEventDetail,
      setIsMemberFree,
      setCurrentEventsArray,
      setUpcomingEventsArray,
      setShowChat,
      setIsLoading,
      setLiveChat,
      setPinnedComments,
      setNewChat,
      setShowScrollArrow,
      userInfo
    );
    setInterval(function () {
      setRefresh((r) => !r);
    }, 10000);
    return () => {
      handleSetIsChatShow(false);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setRefresh((r) => !r);
    }, 0);

    window.scrollTo(0, 0);
  }, [showChat]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete live comment?"}
        handleAgree={handleDeleteLiveComment}
      />
      <ShowEventCommentLikes
        open={openLikesModal}
        setOpen={setOpenLikesModal}
        comment_id={selectedComment._id}
      />
      <div className="">
        <div className="mt-3">
          <Community
            feeds_type="mission"
            event_id={mission__id}
            // event_category={eventDetail.dynamite_event_category}
            current_events_array={currentEventsArray}
            upcoming_events_array={upcomingEventsArray}
            event_slug={mission__id}
            eventDetail={eventDetail}
            streakLeaderBoard={streakLeaderBoard}
            setStreakLeaderBoard={setStreakLeaderBoard}
            loadMorePath={loadMorePath}
            setLoadMorePath={setLoadMorePath}
            coinLeaderBoard={coinLeaderBoard}
            setCoinLeaderBoard={setCoinLeaderBoard}
            loadMorePathCoin={loadMorePathCoin}
            setLoadMorePathCoin={loadMorePathCoin}
            communityMission={communityMission}
            firebaseInfo={{
              prefix: firebasePrefixes[type],
              info: { type, title: missionDetail.title },
            }}
          />
        </div>
      </div>
    </>
  );
}

export default MissionFeedDetailNew;
