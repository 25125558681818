export const firebasePrefixes = {
  programs: "P_", //Programme
  portals: "Pr_", //Portal Events
  theSource: "TS_", //The Source
  mission: "M_", //Mission
  quest: "Q_", //Quest
};

export const firebaseEventsObj = {
  onMissionStarted: "MS ({mission_title})",
  onMissionCompleted: "MC ({mission_title})",
  onScheduleCompleted: "MSC ({schedule_title})",

  missionLevelUpgradeBtn: "BLB {level}",

  missionDashboardUpgradeBtn: "UMBD {mission_title}",
  missionCompleteUpgradeBtn: "UMBC {mission_title}",
  missionReportUpgradeBtn: "UMBR {mission_title}",

  missionBookCallDashbordBtn: "CTAD {mission_title}",
  missionBookCallCompleteBtn: "CTAC {mission_title}",
  missionBookCallReportBtn: "CTAR {mission_title}",
  missionBookCallScheduleBtn: "CTAS {mission_title}",

  missionTab: "MT_{tab_title}",

  onQuestStarted: "QS ({mission_title})",
  onQuestPurchasedFree: "QF ({mission_title})",
  onQuestCompleted: "QC ({mission_title})",
  onQuestPurchasedPaid: "QP ({mission_title})",
  onQuestScheduleCompleted: "QSC ({schedule_title})",

  questTab: "QT_{tab_title}",

  onUpgradeModalShown: `Upgrade Membership Popup Shown`,
  onUpgradeModalButtonClick: `Upgrade Membership Popup Button`,
  onBookCallModalShown: `CTA Popup Shown`,
  onBookCallModalButtonClick: `CTA Popup Clicked`,

  onProgramTab: "PT_{tab_title}",
  onLessonTab: "PLT_{tab_title}",

  mcMissionButton: "Mission Control Mission Button",
  mcBookCallButton: "Mission Control CTA Button",

  //? community Feed
  communityPostAdd: "Post_Created",
  communityWinPostAdd: "Win_Post_Created",
  communityPostLike: "Post_Liked",
  communityPostGratitude: "Post_Gratitude",
  communityPostComment: "Post_Commented",
  communityPostCommentReply: "Post_Comment_Replied",
  communityPostCommentLike: "Post_Comment_Liked",
  communityPostCommentReplyLike: "Post_Comment_Reply_Liked",
  communityPostReport: "Post_Reported",
  communityPostBlockUser: "Post_Blocked_User",

  //? Bonus Content
  bonusContentCategoryVideo: "BCS_{title}",
  bonusContentBookcallButton1: "BC_CTA_1_{title}",
  bonusContentBookcallButton2: "BC_CTA_2_{title}",
  bonusContentPurchased: "BCP_{title}",
  bonusContentStarted: "BCSt_{title}",

  //? Growth Tools
  dailyDynamiteAdd: "DAILY_DYNAMITE_ADDED",
  meditationGTAdd: "MEDITATION_GT_ADDED",
  attitudeAssessmentTab: "AAT_{tab_title}",
  attitudeAssessmentCompleted: "AAC_{slot}",
  attitudeAssessmentResultButton: "AARB_{slot}",

  //? Pods
  onPodsTab: "PoT_{tab_title}",

  //? Affirmation
  affirmationCategoryAdded: "AfC_ADDED",
  affirmationAdded: "Af_ADDED",

  //? Magic Memories
  magicMemoryAdded: "MAGIC_MEMORY_ADDED",
};

export const firebaseScreenObj = {
  //? program
  programDetail: "PD_{program_title}",
  programLessons: "PL_{program_title}",
  programModule: "PMD_{module_title}",
  moduleLessons: "PML_{module_title}",
  programRecordings: "PR_{recording_title}",
  lessonDetail: "LD_{lesson_title}",
  lessonRecrodings: "LR_{recording_title}",

  programStudySessionRecordingsList: "PSS_{study_title}",
  programStudySessionRecordingsDetail: "PSSR_{recording_title}",

  //? Studey Session
  studySessionRecordingsList: "SS_{study_title}",
  studySessionRecordingsDetail: "SSR_{recording_title}",

  //? Bonus Content
  bonusContentCategory: "BC_{title}",
  bonusContentPayment: "BCP_{title}",
  bonusContentThanks: "BCT_{title}",

  //? Mission Control
  mcUpcomingEvents: "Mission_Control_Upcoming_Events",
  mcUpcomingEventsDetail: "UED_{event_title}",

  //? Mission

  missionLevelDetail: "ML_{level}",
  missionLockDetail: "MLD_{mission_title}",
  missionDetail: "MD_{mission_title}",
  missionReport: "MR_{mission_title}",
  missionOnboardingQuestion: "MSQ_{mission_title}",
  missionSchedule: "MS_{schedule_title}",
  missionScheduleQuestion: "MSQ_{schedule_title}",

  //? Quest

  questLockDetail: "QLD_{mission_title}",
  questDetail: "QD_{mission_title}",
  questOnboardingQuestion: "MSQ_{mission_title}",
  questSchedule: "QS_{schedule_title}",
  questScheduleQuestion: "QSQ_{schedule_title}",
  questPayment: "QPay ({mission_title})",

  //? Daily Dynamite
  DailyDynamitList: "DAILY_DYNAMITE_LIST",

  //? Meditation
  GTmeditationList: "MEDITATION_LIST_GT",

  //? Attitude Assessment
  attitudeAssessmentResult: "ATTITUDE_ASSESSMENT_RESULT",

  //? Pods
  podDetail: "PoD_{title}",

  //? Source Session
  sourceSessionDetail: "SrSD_{category_title}",
  sourceSessionRecodingDetail: "SrSR_{recording_title}",

  //? Meditation
  onMedition: "Md_{title}",
  onMeditionRecordings: "MdR_{recording_title}",

  //? Affirmation
  affirmationCategory: "AFFIRMATION_CATEGORY",

  //? Magic Memories
  magicMemoryDetail: "MAGIC_MEMORY_DETAIL",
};

export const makeProperEvent = (props) => {
  let { replacementArr = [], event } = props;
  replacementArr.forEach(([pattern, value]) => {
    const replace_pattern = pattern.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp(replace_pattern, "g");
    event = event.replace(regex, value.toLowerCase());
  });

  event = event
    .replace(/[^a-zA-Z0-9_ ]/g, "") // Remove all special characters except letters, numbers, underscores, and spaces
    .replace(/ /g, "_") // Replace spaces with underscores
    .slice(0, 40); // Limit the string length to 40 characters

  return event;
};
