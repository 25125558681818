import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { IconButton, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  programmDetail,
  programmeViewCountIncrease,
} from "../../DAL/Programmes/Programmes";
import { htmlDecode } from "../../utils/convertHtml";
import { s3baseUrl } from "../../config/config";
import LessonsCardTab from "src/components/programmes/LessonsCardTab";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import ProgrammeRecordingTab from "src/components/programmes/ProgrammeRecordingTab";
import { NoAccess } from "src/components/programmes";
import ResourcesCardTab from "src/components/programmes/ResourcesCardTab";
import {
  csvImage,
  docImage,
  pdfImage,
  wordImage,
  audioImage,
  xlsxImage,
  otherImage,
} from "src/assets";
import StudySessionCardTabs from "src/components/programmes/StudySessionCardTabs";
import RatingCardTab from "src/components/programmes/RatingCardTab";
import ProgramSectionTabs from "src/components/programmes/ProgramSectionTabs";
import ModuleCardTab from "src/components/programmes/ModuleCardTab";
import ProgrammeOverview from "src/components/programmes/ProgrammeOverview";
import Community from "../Community/Community";
import {
  firebaseEventsObj,
  firebasePrefixes,
} from "../../utils/firebase_utils";
import { useFirebase } from "src/Hooks/FirebaseContaxt";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function ProgrammesDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const { logPageOnFirebase, logOnFirebase } = useFirebase();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [programmesDetail, setProgrammesDetail] = useState({});
  const [lessonsList, setLessonsList] = useState([]);
  const [modulesList, setModulesList] = useState([]);
  const [resourcesList, setResourcesList] = useState([]);
  const [reviewsList, setReviewsList] = useState([]);
  const [recordingList, setRecordingList] = useState([]);
  const [programSection, setProgramSection] = useState([]);
  const [studySession, setStudySession] = useState([]);
  const [videoProgressData, setVideoProgressData] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [watched, setWatched] = useState(false);
  const [programModuleAccess, setProgramModuleAccess] = useState();
  const [programConfiguration, setProgramConfiguration] = useState();

  const imageLinks = {
    docx: wordImage,
    mp3: audioImage,
    pdf: pdfImage,
    csv: csvImage,
    doc: docImage,
    xlsx: xlsxImage,
    xls: xlsxImage,
    other: otherImage,
  };

  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    localStorage.setItem("programme_tab_value", newValue);
  };

  const increaseWatchCount = async (type) => {
    const result = await programmeViewCountIncrease(type, params.slug);
    if (result.code === 200) {
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const getProgrammesDetail = async () => {
    const result = await programmDetail(params.slug);
    increaseWatchCount("view");
    if (result.code === 200) {
      logPageOnFirebase({
        event: "programDetail",
        replacementArr: [["{program_title}", result.program.title]],
      });
      setProgrammesDetail(result.program);
      setProgramModuleAccess(result.is_program_module);
      setProgramConfiguration(result.program?.program_configration);
      let modules_array = [];
      result.program_module.map((lesson) => {
        modules_array.push({
          ...lesson,
          card_title: lesson.title,
          card_image: s3baseUrl + lesson.program_images?.thumbnail_3,
          card_short_description: lesson.short_description,
          card_classes: "col-12 mt-3",
          start_time: "",
        });
      });
      let data = [
        {
          recording_id: result.program?._id,
          video_duration: result.program?.video_duration,
          total_video_duration: result.program?.total_video_duration,
          is_complete: result.program?.is_complete,
        },
      ];
      setVideoProgressData(data);
      let lessons_array = [];
      result.lesson.map((lesson) => {
        lessons_array.push({
          ...lesson,
          card_title: lesson.title,
          card_image: s3baseUrl + lesson.lesson_images?.thumbnail_3,
          card_short_description: lesson.short_description,
          card_classes: "col-12 mt-3",
          start_time: "",
        });
      });
      setModulesList(modules_array);
      setLessonsList(lessons_array);
      setResourcesList(result.program_document);
      setReviewsList(result.program_review);

      let recording_array = [];
      result.recording_list.map((recording) => {
        recording_array.push({
          ...recording,
          card_title: recording.title,
          card_image: s3baseUrl + recording.recording_image.thumbnail_2,
          card_short_description: recording.short_description,
          card_classes: "col-12 mt-3",
        });
      });
      setRecordingList(recording_array);
      setProgramSection(result.program_section);

      let study_session_array = [];
      result.study_session.map((study_session) => {
        study_session_array.push({
          ...study_session,
          card_title: study_session.title,
          card_image: s3baseUrl + study_session.image.thumbnail_2,
          card_short_description: study_session.short_description,
          card_classes: "col-12 mt-3",
        });
      });
      setStudySession(study_session_array);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleTabClick = (e, row) => {
    logOnFirebase({
      event: firebaseEventsObj.onProgramTab,
      replacementArr: [["{tab_title}", row.title]],
      obj: { program: programmesDetail.title, tab: row.title },
    });
  };

  const TABS_OPTIONS = [
    {
      title: "Overview",
      tab_slug: "overview",
      component: (
        <ProgrammeOverview
          programmesDetail={programmesDetail}
          setVideoProgressData={setVideoProgressData}
          videoProgressData={videoProgressData}
        />
      ),
      onClick: handleTabClick,
    },
  ];

  const numAscending =
    programConfiguration &&
    [...programConfiguration].sort((a, b) => +a.order - +b.order);

  numAscending?.map((value, i) => {
    if (!programmesDetail.program_lock_status && value.community_tab_status) {
      TABS_OPTIONS.push({
        title: value.community_tab_title,
        tab_slug: "community",
        component: (
          <div className="new-community-page mt-3">
            <Community
              feeds_type="program"
              program_id={programmesDetail._id}
              firebaseInfo={{
                prefix: firebasePrefixes.programs,
                info: { type: "programs", title: programmesDetail.title },
              }}
            />
          </div>
        ),
        onClick: handleTabClick,
      });
    }

    if (value?.document_tab_status) {
      TABS_OPTIONS.push({
        title: value?.document_tab_title,
        tab_slug: "resources-tab",
        component:
          programmesDetail.program_lock_status === false ? (
            <ResourcesCardTab data={resourcesList} imageLink={imageLinks} />
          ) : (
            <NoAccess title="You have no access to see the Resource" />
          ),
        onClick: handleTabClick,
      });
    }

    if (value?.reviews_tab_status) {
      TABS_OPTIONS.push({
        title: value?.reviews_tab_title,
        tab_slug: "reviews-tab",
        component: <RatingCardTab reviews={reviewsList} />,
        onClick: handleTabClick,
      });
    }

    if (value?.recording_tab_status) {
      TABS_OPTIONS.push({
        title: value?.recording_tab_title,
        tab_slug: "recordings-tab",
        component:
          programmesDetail.program_lock_status === false ? (
            <ProgrammeRecordingTab data={recordingList} />
          ) : (
            <NoAccess title="You have no access to see the Recordings" />
          ),
        onClick: handleTabClick,
      });
    }

    if (value?.lesson_tab_status) {
      TABS_OPTIONS.push({
        title: value?.lesson_tab_title,
        tab_slug: "lessons-tab",
        component: (
          <LessonsCardTab
            lessons={lessonsList}
            programmesDetail={programmesDetail}
          />
        ),
        onClick: handleTabClick,
      });
    }

    if (value?.module_tab_status) {
      TABS_OPTIONS.push({
        title: value?.module_tab_title,
        tab_slug: "module-tab",
        component: (
          <ModuleCardTab
            lessons={modulesList}
            programmesDetail={programmesDetail}
            programLockStatus={programmesDetail.program_lock_status}
            programSlug={params.slug}
          />
        ),
        onClick: handleTabClick,
      });
    }

    if (value?.section_tab_status) {
      if (studySession.length > 0) {
        TABS_OPTIONS.push({
          title: value?.section_tab_title,
          tab_slug: "study-session-tab",
          onClick: handleTabClick,
          component:
            programmesDetail.program_lock_status === false ? (
              <StudySessionCardTabs
                studySession={studySession}
                imageLink={imageLinks}
              />
            ) : (
              <NoAccess title="You have no access to see the Study Sessions" />
            ),
        });
      }
    }
  });

  programSection.length > 0 &&
    programSection.map((Section) => {
      TABS_OPTIONS.push({
        title: Section.title,
        tab_slug: Section.section_slug,
        onClick: handleTabClick,
        component:
          programmesDetail.program_lock_status === false ? (
            <ProgramSectionTabs
              programSection={Section}
              getProgrammesDetail={getProgrammesDetail}
            />
          ) : (
            <NoAccess
              title={`You have no access to see the ${Section.title}`}
            />
          ),
      });
    });

  useEffect(() => {
    getProgrammesDetail();
  }, []);

  useEffect(() => {
    if (watched == false) {
    } else {
      increaseWatchCount("watch");
    }
  }, [watched]);

  useEffect(() => {
    const tabValue = localStorage.getItem("programme_tab_value");
    setTabValue(tabValue ? parseInt(tabValue) : 0);
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin ">
        <div className="col-12 d-flex w-100 justify-content-between ">
          <div className="d-flex">
            <span>
              <IconButton
                className="back-screen-button"
                onClick={() => navigate("/programmes")}
              >
                <ArrowBackIcon />
              </IconButton>
            </span>
            <h2 className="ms-2">
              {location.state
                ? htmlDecode(location.state.title)
                : htmlDecode(programmesDetail.title)}
            </h2>
          </div>

          {programmesDetail.program_lock_status === false ? (
            programModuleAccess ? (
              ""
            ) : (
              <button
                className="small-contained-button float-end mt-1"
                onClick={() => navigate(`/programmes/${params.slug}/lessons`)}
              >
                View Lessons
              </button>
            )
          ) : programModuleAccess ? (
            ""
          ) : (
            <button
              className="small-contained-button float-end mt-1 disabled"
              disabled="disabled"
            >
              View Lessons
            </button>
          )}
        </div>
        <div className="col-12 mt-3">
          <MUICustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChange}
            className="challenge-video-detail-tabs"
          />
        </div>
      </div>
    </div>
  );
}

export default ProgrammesDetail;
