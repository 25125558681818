import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { s3baseUrl } from "../../config/config";
import { ReactVideoPlayer } from "src/components";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import { source_session_recording_detail_api } from "src/DAL/SourceSession/SourceSession";
import { useFirebase } from "src/Hooks/FirebaseContaxt";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function SourceSessionRecordingDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { logPageOnFirebase } = useFirebase();
  const [isLoading, setIsLoading] = useState(true);
  const [recordingData, seRecordingData] = useState({});

  const handleNavigate = () => {
    navigate(`/source-session/${params.sesson_id}/source-session-recordings`);
  };

  const getSessionRecordingDetail = async () => {
    setIsLoading(true);
    const result = await source_session_recording_detail_api(
      params.recording_slug
    );
    if (result.code === 200) {
      logPageOnFirebase({
        event: "sourceSessionRecodingDetail",
        replacementArr: [
          ["{recording_title}", result.source_session_recording.title],
        ],
      });
      seRecordingData(result.source_session_recording);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/source-session/${params.sesson_id}/source-session-recordings`);
    }
  };

  useEffect(() => {
    getSessionRecordingDetail();
  }, [params.recording_slug]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin ">
        <div className="col-12 d-flex">
          <IconButton
            className="back-screen-button"
            onClick={() => handleNavigate()}
          >
            <ArrowBackIcon />
          </IconButton>
          <h2 className="programmes-heading ms-2">
            {htmlDecode(recordingData.title)}
          </h2>
        </div>
      </div>

      <div className="row section-space">
        <div className="col-12"></div>
      </div>
      <div className="row media-margin">
        <div className="col-12">
          {recordingData.video_url ? (
            <ReactVideoPlayer url={recordingData.video_url} />
          ) : (
            <img
              src={
                s3baseUrl + recordingData.session_recording_images.thumbnail_1
              }
              alt=""
              srcSet=""
            />
          )}
        </div>
        {recordingData.audio_recording && (
          <div className="col-12 section-space">
            <audio
              controlsList="nodownload"
              className="w-100"
              src={s3baseUrl + recordingData.audio_recording}
              controls
            />
          </div>
        )}
        <div className="col-12 section-space">
          <p
            dangerouslySetInnerHTML={{
              __html: recordingData.detailed_description,
            }}
          ></p>
        </div>
      </div>
    </div>
  );
}
