import { CircularProgress, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { requestDetailApi } from "src/DAL/Payment/Payment";
import TransactionList from "../Billing/TransactionList";
import RequestCard from "./RequestCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SubscriptionCard from "./SubscriptionCard";
import SubscriptionList from "./SubscriptionList";
import { subscriptionsDetailApi } from "src/DAL/Profile/Profile";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const ActiveSubscriptionList = (props) => {
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const [requestData, setRequestData] = useState({});
  const [transactions, setTransactions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  //Getting lesson detail
  const getRequestDetail = async () => {
    const result = await subscriptionsDetailApi(params.id);
    if (result.code === 200) {
      setRequestData(result.subscription);
      setTransactions(result.transections);
      setIsLoading(false);
    } else {
      // setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(-1);
    }
  };

  useEffect(() => {
    getRequestDetail();
  }, [params.request_slug]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="d-flex mt-3 mb-2">
        <span>
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </span>
        <h2 className="ms-2">Subscriptions Information</h2>
      </div>

      <SubscriptionCard bill={requestData} />
      <div className="col-12">
        <SubscriptionList
          transactions={transactions}
          title={"Subscriptions List"}
        />
      </div>
    </div>
  );
};

export default ActiveSubscriptionList;
