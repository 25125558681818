import { Avatar, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { medal_1, medal_2, medal_3, point } from "src/assets";
import { s3baseUrl } from "src/config/config";
import { makeStyles } from "@mui/styles";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import GeneralModelBox from "src/components/GeneralComponents/GeneralModelBox";
import LeaderBoardForMissionControl from "./LeaderBoardForMissionControl";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import { number_with_comma } from "src/utils/constants";
import CustomAvatarBadgeColor from "src/components/GeneralComponents/CustomAvatarBadgeColor";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const CoinsLeaderboardData = ({
  coins_leader_board,
  isLoading,
  badgeId,
  badge,
  currentUser,
}) => {
  const classes = useStyles();
  const { userInfo, handleViewProfile } = useContentSetting();

  const [showAllPopUp, setshowAllPopUp] = useState(false);
  const [badge_level_id, set_badge_level_id] = useState("");
  const [cardHeight, setCardHeight] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      // Get the height of the card with ID 'mission-card' after a slight delay
      const missionCard = document.getElementById("mission-card");
      if (missionCard) {
        setCardHeight(missionCard.offsetHeight - 50);
      }
    }, 1300); // Delay for 100ms to allow the DOM to fully render

    // Cleanup timeout on unmount
    return () => clearTimeout(timeout);
  }, []);

  if (isLoading || !cardHeight) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const handleClick = () => {
    set_badge_level_id(badgeId);
    setshowAllPopUp(true);
  };

  return (
    <div
      id="mission-card"
      style={{
        height: cardHeight > 0 ? `${cardHeight}px` : "260px", // Use dynamic height if available
        overflow: "scroll",
        backgroundColor: "#1d1c1d",
      }}
    >
      {coins_leader_board.map((item, index) => (
        <div
          key={item._id}
          className="sections d-flex justify-content-between gap-3 p-3 pt-2 pb-2 bg-coins-leaderboard-data"
        >
          <div className="d-flex justify-content-between align-items-center">
            <div>
              {index <= 2 ? (
                <Avatar
                  src={index === 0 ? medal_1 : index === 1 ? medal_2 : medal_3}
                  sx={{ width: 20, height: 20 }}
                />
              ) : (
                <div>
                  <span className="me-2">{index + 1}.</span>
                </div>
              )}
            </div>
            <div
              className="d-flex align-items-center cursor-pointer"
              onClick={() => handleViewProfile(item?._id)}
            >
              <CustomAvatarBadgeColor
                src={s3baseUrl + item.profile_image}
                alt=""
                colorCode={badge?.color_code}
                width={32}
                height={32}
              />
              <div className="user-name ms-2 d-flex align-items-center">
                <span className="badge-title">
                  {item.first_name + " " + item.last_name}
                </span>
                {item?._id === userInfo._id && (
                  <img src={point} className={`pointer_hand`} alt="point" />
                )}
              </div>
            </div>
          </div>
          <div className="coins-content">
            {number_with_comma(item.coins_count)}
          </div>
        </div>
      ))}
      {coins_leader_board.length > 0 &&
        currentUser.membership_level_badge_info.membership_level_badge_id ==
          badgeId &&
        coins_leader_board.length - 1 < currentUser.rank && (
          <>
            <>
              <div
                key={"2232"}
                className="sections d-flex justify-content-between gap-3 p-3 pt-0 pb-0 bg-coins-leaderboard-data"
              >
                <div>
                  <div>
                    <span className="me-2">.</span>
                  </div>
                </div>
              </div>
              <div
                key={"2232"}
                className="sections d-flex justify-content-between gap-3 p-3 pt-0 pb-0 bg-coins-leaderboard-data"
              >
                <div>
                  <div>
                    <span className="me-2">.</span>
                  </div>
                </div>
              </div>
              <div
                key={"2232"}
                className="sections d-flex justify-content-between gap-3 p-3 pt-0 pb-0 bg-coins-leaderboard-data"
              >
                <div>
                  <div>
                    <span className="me-2">.</span>
                  </div>
                </div>
              </div>
            </>
            <div
              key={"2232"}
              className="sections d-flex justify-content-between gap-3 p-3 pt-2 pb-2 bg-coins-leaderboard-data"
            >
              <div
                className="d-flex justify-content-between align-items-center cursor-pointer"
                onClick={() => handleViewProfile(currentUser?._id)}
              >
                <div>
                  <div>
                    <span className="me-2">{currentUser.rank}.</span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <CustomAvatarBadgeColor
                    src={s3baseUrl + currentUser.profile_image}
                    alt="taalh"
                    colorCode={userInfo?.current_badge?.color_code}
                    width={33}
                    height={33}
                  />
                  <div className="user-name ms-2 d-flex align-items-center">
                    <span className="badge-title">
                      {currentUser.first_name + " " + currentUser.last_name}
                    </span>
                    <img src={point} className={`pointer_hand`} alt="point" />
                  </div>
                </div>
              </div>
              <div className="coins-content">
                {number_with_comma(currentUser.coins_count)}
              </div>
            </div>
          </>
        )}
      <div className=" text-center mb-2">
        {coins_leader_board.length > 0 && (
          <span
            style={{
              textDecoration: "underline",
              color: "#f0c26a",
              cursor: "pointer",
            }}
            onClick={() => handleClick()}
          >
            View All
          </span>
        )}
        {coins_leader_board.length === 0 && (
          <div>
            <RecordNotFound title="NO Data Found !" />
          </div>
        )}
        <GeneralModelBox
          open={Boolean(showAllPopUp)}
          setOpen={setshowAllPopUp}
          className={"challenge-video-days-questions-modal"}
          componentToPassDown={
            <LeaderBoardForMissionControl
              handleCross={() => setshowAllPopUp(false)}
              showInfoPopup={showAllPopUp}
              badgeId={badge_level_id}
              badge={badge}
            />
          }
        />
      </div>
    </div>
  );
};

export default CoinsLeaderboardData;
