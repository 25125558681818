import React, { useContext, useEffect, useState } from "react";
import {
  get_consultant_data_localStorage,
  get_project_info_localStorage,
  _get_admin_time_zone_localStorage,
  _get_client_dashboard_setting_localStorage,
  _get_content_setting_localStorage,
  _get_home_event_localStorage,
  _get_navbar_list_localStorage,
  _get_testmonial_localStorage,
  _get_user_from_localStorage,
  _wheel_of_life_setting_localStorage,
  _get_videos_list_localStorage,
} from "src/DAL/localStorage/localStorage";

import io from "socket.io-client";
import { socketBaseUri } from "src/config/config";
import { useLocation, useNavigate } from "react-router-dom";
const CreateContentSetting = React.createContext();
const content_setting = _get_content_setting_localStorage();
const client_dashboard_setting = _get_client_dashboard_setting_localStorage();
const get_testmonial_localStorage = _get_testmonial_localStorage();
const get_home_event_localStorage = _get_home_event_localStorage();
const get_admin_time_zone_localStorage = _get_admin_time_zone_localStorage();
const get_project_info = get_project_info_localStorage();
const _get_user_info = _get_user_from_localStorage();
const _get_consultant_data = get_consultant_data_localStorage();
const get_navbar_list = _get_navbar_list_localStorage();
const wheel_of_life_setting = _wheel_of_life_setting_localStorage();
const get_videos_list = _get_videos_list_localStorage();

let socket = {};
// socket-initialization
socket = io(socketBaseUri + `?user_id=${_get_user_info._id}`);
//----------------------
export const useContentSetting = () => useContext(CreateContentSetting);
export function ContentSettingState({ children }) {
  /* ------------------------------------------------------
  ------------------| States |--------------------
  ------------------------------------------------------- */

  const [adminTimeZone, setAdminTimeZone] = useState(
    get_admin_time_zone_localStorage
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [contentSettingData, setContentSettingData] = useState(content_setting);
  const [jsCode, setJSCode] = useState();
  const [cssCode, setCssCode] = useState();
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [feedSetting, setFeedSetting] = useState(null);
  const [affiliateUrlName, setAffiliateUrlName] = useState("");
  const [stripeKey, setStripeKey] = useState("");
  const [stripeKeyForShop, setStripeKeyForShop] = useState("");
  const [streamLastChunks, setStreamLastChunks] = useState([]);
  const [stripeKeyObject, setStripeKeysObject] = useState({});
  const [siteSetting, setSiteSetting] = useState({});
  const [googleClientId, setGoogleClientId] = useState("");
  const [bankCurrencies, setBankCurrencies] = useState([]);
  const [projectInfo, setProjectInfo] = useState(get_project_info);
  const [userInfo, setUserInfo] = useState(_get_user_info);
  const [notificationSettings, setNotificationSettings] = useState({});
  const [shopContentSettings, setShopContentSettings] = useState({});
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [consultantInfo, setConsultantInfo] = useState(_get_consultant_data);
  const [tinymceKey, settinymceKey] = useState({});
  const [showLogs, setShowLogs] = useState(true);
  const [bookCallButtonSettings, setBookCallButtonSettings] = useState(null);

  const [wheelOfLifeSetting, setWheelOfLifeSetting] = useState(
    wheel_of_life_setting
  );
  const [cartElement, setCardElement] = useState([]);
  const [navbarList, setNavbarList] = useState(get_navbar_list);
  const [loginInfo, setLoginInfo] = useState({});

  const [dashboardEventData, setDashboardEventData] = useState(
    get_home_event_localStorage
  );
  const [roadMapLevels, setRoadMapLevels] = useState([]);
  const [dashboardTestimonialData, setDashboardTestimonialData] = useState(
    get_testmonial_localStorage
  );
  const [dashboardSettingData, setDashboardSettingData] = useState(
    client_dashboard_setting
  );
  const [videoList, setVideoList] = useState(get_videos_list);
  /* ------------------------------------------------------
------------------/ Hooks Functions /-------------------
  ------------------------------------------------------- */
  const handleContentSettingData = (val) => {
    setContentSettingData(val);
  };
  const handleViewProfile = (id) => {
    localStorage.setItem("userPath", location.pathname);
    navigate(`/thesource/profile/${id}`);
  };
  const handleJSCode = (val) => {
    setJSCode(val);
  };
  const handleCSSCode = (val) => {
    setCssCode(val);
  };

  const handleDashboardSettingData = (val) => {
    setDashboardSettingData(val);
  };
  const handleUpdateCard = (val) => {
    setCardElement((old) => [...old, val]);
  };
  const handleDashboardTestimonialData = (val) => {
    setDashboardTestimonialData(val);
  };
  const handleDashboardEventData = (val) => {
    setDashboardEventData(val);
  };
  const handleAdminTimeZone = (val) => {
    setAdminTimeZone(val);
  };
  const handleUserInfo = (val) => {
    setUserInfo(val);
  };
  const handleNotifications = (val) => {
    setNotificationSettings(() => val);
  };
  const handleConsultantInfo = (val) => {
    setConsultantInfo(val);
  };
  const handleNavbarList = (val) => {
    setNavbarList(val);
  };
  const handleWheelOfLifeSetting = (val) => {
    setWheelOfLifeSetting(val);
  };

  const handleProjectInfo = (val) => {
    setProjectInfo(val);
  };

  const handleStripeKey = (val) => {
    setStripeKey(val);
  };

  const handleGoogleId = (val) => {
    setGoogleClientId(val);
  };

  const handleNotificationsCount = (val) => {
    setNotificationsCount(val);
  };

  const handleUnreadMessageCount = (val) => {
    setUnreadMessageCount(val);
  };

  const handleAffiliateUrlName = (val) => {
    setAffiliateUrlName(val);
  };

  const handleFeedSetting = (val) => {
    setFeedSetting(val);
  };

  const START_SOCKET_FORCEFULLY = (user_id) => {
    socket = io(socketBaseUri + `?user_id=${user_id}`);
  };

  const STOP_SOCKET_FORCEFULLY = (user_id) => {
    socket.disconnect();
  };

  const handleVideoList = (val) => {
    setVideoList(val);
  };

  const is_mention_allowed = () => {
    return (
      feedSetting.enable_mention_user ||
      userInfo.is_super_executive ||
      userInfo.is_executive
    );
  };

  useEffect(() => {
    if (userInfo?._id && !isConnected) {
      socket.emit("live_event_room", userInfo._id);
      setIsConnected(true);
    }
    return () => {};
  }, [userInfo, isConnected]);

  const collection = {
    setSiteSetting,
    siteSetting,
    adminTimeZone,
    setCardElement,
    cartElement,
    setRoadMapLevels,
    roadMapLevels,
    cssCode,
    jsCode,
    navbarList,
    consultantInfo,
    userInfo,
    projectInfo,
    dashboardEventData,
    contentSettingData,
    dashboardSettingData,
    dashboardTestimonialData,
    wheelOfLifeSetting,
    notificationsCount,
    unreadMessageCount,
    stripeKey,
    googleClientId,
    affiliateUrlName,
    feedSetting,
    videoList,
    notificationSettings,
    bankCurrencies,
    shopContentSettings,
    loginInfo,
    streamLastChunks,
    handleGoogleId,
    setStreamLastChunks,
    setNotificationSettings,
    handleContentSettingData,
    handleDashboardSettingData,
    handleDashboardTestimonialData,
    handleDashboardEventData,
    handleWheelOfLifeSetting,
    handleAdminTimeZone,
    handleUserInfo,
    setUserInfo,
    handleConsultantInfo,
    handleNavbarList,
    handleProjectInfo,
    handleNotificationsCount,
    handleUnreadMessageCount,
    handleStripeKey,
    handleAffiliateUrlName,
    handleJSCode,
    handleCSSCode,
    handleFeedSetting,
    handleUpdateCard,
    handleNotifications,
    handleVideoList,
    setBankCurrencies,
    setShopContentSettings,
    stripeKeyObject,
    setStripeKeysObject,
    stripeKeyForShop,
    setStripeKeyForShop,
    setLoginInfo,
    is_mention_allowed,
    tinymceKey,
    settinymceKey,
    // socket instant
    socket,
    START_SOCKET_FORCEFULLY,
    STOP_SOCKET_FORCEFULLY,
    showLogs,
    setShowLogs,
    bookCallButtonSettings,
    setBookCallButtonSettings,
    handleViewProfile,
  };
  useEffect(() => {
    if (!showLogs) {
      document.addEventListener("contextmenu", (e) => e.preventDefault());
      const disableInspect = (e) => {
        if (
          e.keyCode === 123 || // F12
          (e.ctrlKey && e.shiftKey && e.keyCode === 73) || // Ctrl+Shift+I
          (e.ctrlKey && e.shiftKey && e.keyCode === 74) || // Ctrl+Shift+J
          (e.ctrlKey && e.keyCode === 85) || // Ctrl+U
          (e.metaKey && e.altKey && e.keyCode === 73) || // Cmd+Option+I (Mac)
          (e.metaKey && e.altKey && e.keyCode === 74) || // Cmd+Option+J (Mac)
          (e.metaKey && e.keyCode === 85) // Cmd+U (Mac)
        ) {
          e.preventDefault();
        }
      };
      document.addEventListener("keydown", disableInspect);
      // Cleanup event listeners on component unmount
      return () => {
        document.removeEventListener("contextmenu", (e) => e.preventDefault());
        document.removeEventListener("keydown", disableInspect);
      };
    }
  }, [showLogs]);

  return (
    <CreateContentSetting.Provider value={collection}>
      {children}
    </CreateContentSetting.Provider>
  );
}
