import {
  Avatar,
  Card,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {
  attchment_icon,
  communityHeart,
  emoji_picker_icon,
  send_chat_icon,
} from "src/assets";
import { _mentorship_video_list_chat } from "src/DAL/Inception/Inception";
import { useSnackbar } from "notistack";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { update_image_on_s3_for_chat } from "src/DAL/LiveEvents/LiveEvents";
import { s3baseUrl } from "src/config/config";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import ShowEventCommentLikes from "../Community/components/LikesPopup/ShowEventCommentLikes";
import { Icon } from "@iconify/react";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { handle_change_page } from "src/utils/constants";
import { useParams } from "react-router-dom";
import { htmlDecode, urlify } from "src/utils/convertHtml";
import CustomAvatarBadgeColor from "src/components/GeneralComponents/CustomAvatarBadgeColor";

const bottom_in = {
  hidden: {
    y: "100px",
    x: "-50%",
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    y: "0",
    transition: { delay: 0, duration: 1 },
  },
};

const scroll_to_bottom = (smooth) => {
  const chat_box = document.getElementsByClassName("live-comments-list")[0];
  const scroll_height = chat_box.scrollHeight;
  if (smooth) {
    chat_box.scrollBy({ top: scroll_height, behavior: "smooth" });
  } else {
    chat_box.scrollBy({ top: scroll_height });
  }
};

const get_video_height = () => {
  if (document.getElementById("video-col")) {
    const height = document.getElementById("video-col").firstChild.offsetHeight;
    if (height > 300) {
      return height;
    } else {
      return 500;
    }
  } else {
    return 500;
  }
};

const get_pinned_image_height = () => {
  if (document.getElementById("pinned-image")) {
    const height = document.getElementById("pinned-image").offsetHeight;
    return height;
  }
};

const handleScrollListener = (setNewChat, setShowScrollArrow) => {
  var scrollTimeout;
  clearTimeout(scrollTimeout);
  scrollTimeout = setTimeout(function () {
    const chat_box = document.getElementsByClassName("live-comments-list")[0];
    const scroll_top = chat_box.scrollTop;
    const scroll_height = chat_box.scrollHeight;
    const offset_height = chat_box.offsetHeight;
    const available_scroll = scroll_height - offset_height - 100;
    if (scroll_top > available_scroll) {
      setNewChat(false);
      setShowScrollArrow(false);
    } else {
      setShowScrollArrow(true);
    }
  }, 100);
};

export default function MentorChatCard(props) {
  const { mainVideo, banner_data, setIsLoadingPage, hide_banner } = props;
  const { link_image, link, is_purchase_link } = banner_data;
  const inputRef = useRef();
  const { socket, userInfo, handleViewProfile } = useContentSetting();

  const { category_slug } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [chatMessage, setChatMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [formType, setFormType] = useState("ADD");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showScrollArrow, setShowScrollArrow] = useState(false);
  const [newChat, setNewChat] = useState(false);
  const [openLikesModal, setOpenLikesModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [parentMessage, setParentMessage] = useState(null);
  const [selectedObject, setSelectedObject] = useState({});
  const [bannerData, setBannerData] = useState({});
  const [chatComments, setChatComments] = useState([]);
  const [chatPinnedComments, setChatPinnedComments] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [image, setImage] = useState("");
  const [oldImage, setOldImage] = useState("");
  const [previews, setPreviews] = useState("");
  const [bannerHeight, setBannerHeight] = useState(null);

  const mentorship_video_list = async () => {
    const result = await _mentorship_video_list_chat(mainVideo._id);
    if (result.code === 200) {
      setChatComments(result.dynamite_event_category_video_chat);
      setChatPinnedComments(result.dynamite_event_category_video_featured_chat);
      setIsLoading(false);
      setTimeout(() => {
        scroll_to_bottom();
      }, 200);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };

  const handleRemove = () => {
    setPreviews("");
    setImage("");
    setOldImage("");
  };

  const handleRemoveAll = () => {
    handleRemove();
    setChatMessage("");
    setIsSubmitting(false);
    setShowEmojiPicker(false);
    setParentMessage(null);
    setSelectedObject({});
    setFormType("ADD");
  };

  const handleOpenReplyBox = (chat) => {
    setParentMessage(chat);
    inputRef.current.focus();
  };

  const UploadImages = async (image) => {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("width", 600);
    formData.append("height", 700);
    const result = await update_image_on_s3_for_chat(formData);
    return result.image_path;
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    let file_url = "";
    if (image) {
      file_url = await UploadImages(image);
    }
    const video_id = mainVideo._id;
    const message = chatMessage;
    const member = {
      _id: userInfo._id,
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      profile_image: userInfo.profile_image,
    };

    let socket_object = { video_id, message, file_url, member };
    if (parentMessage) {
      socket_object.parent_message = parentMessage._id;
    }
    socket.emit("mentorship_video_message_event", socket_object);
    handleRemoveAll();
  };

  const handleMessageUpdate = async (e) => {
    e.preventDefault();
    let file_url = oldImage;
    if (image) {
      file_url = await UploadImages(image);
    }
    const member = {
      _id: userInfo._id,
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      profile_image: userInfo.profile_image,
    };
    const comment_id = selectedObject._id;
    const message = chatMessage;
    let socket_object = { comment_id, message, file_url, member };
    socket.emit("mentorship_video_message_update", socket_object);
    handleRemoveAll();
  };

  const handleDelete = () => {
    setOpenDelete(false);
    socket.emit("mentorship_video_message_delete", {
      comment_id: selectedObject._id,
      video_id: mainVideo._id,
    });
  };

  const handleLikeComment = (comment, parentComment) => {
    const video_id = mainVideo._id;
    const comment_id = comment._id;
    const member = {
      _id: userInfo._id,
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      profile_image: userInfo.profile_image,
    };

    let socket_object = { video_id, member, comment_id };
    if (parentComment) {
      socket_object.parent_message = parentComment._id;
    }
    socket.emit("mentorship_video_message_like", socket_object);
  };

  const handleCommentEdit = (comment) => {
    setSelectedObject(comment);
    setChatMessage(comment.message);
    setOldImage(comment.file_url);
    setFormType("EDIT");
  };

  const handleCommentDelete = (comment) => {
    setSelectedObject(comment);
    setOpenDelete(true);
  };

  const handleOpenLikes = (chat) => {
    setSelectedObject(chat);
    setOpenLikesModal(true);
  };

  const handleEmojiSelect = (emoji) => {
    setChatMessage((msg) => msg + emoji.native);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleCommentEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleCommentDelete,
    },
  ];

  const receive_new_live_message_by_socket = (data) => {
    if (data.parent_message) {
      setChatComments((old) =>
        old.map((item) => {
          let replies = [];
          if (item.replies) {
            replies = [...item.replies];
          }
          if (item._id == data.parent_message) {
            replies = [...replies, data.comment_id];
          }
          return { ...item, replies };
        })
      );
    } else {
      setChatComments((old) => [...old, data.comment_id]);
    }
    setTimeout(() => {
      scroll_to_bottom(true);
    }, 100);
  };

  const mentorship_video_message_update_receiver = (data) => {
    setChatComments((chats) =>
      chats.map((item) => {
        if (item._id == data.comment_id.parent_message) {
          item.replies.map((child) => {
            if (child._id == data.comment_id._id) {
              child.message = data.message;
              child.file_url = data.comment_id.file_url;
            }
            return child;
          });
        } else if (item._id == data.comment_id._id) {
          item.message = data.message;
          item.file_url = data.comment_id.file_url;
        }
        return item;
      })
    );
  };

  const mentorship_video_message_delete_receiver = (data) => {
    setChatComments((chats) =>
      chats.filter((item) => item._id !== data.comment_id)
    );
    setChatComments((chats) =>
      chats.map((comment) => {
        const array_filtered = comment.replies.filter(
          (child) => child._id !== data.comment_id
        );
        return { ...comment, replies: array_filtered };
      })
    );
  };

  const purchase_link_enabled_receiver = (data) => {
    if (category_slug == data.category_slug) {
      setBannerData(data);
    }
  };

  const mentorship_video_message_like_receiver = (data) => {
    let is_liked = false;
    if (data.action == "like") {
      is_liked = true;
    }
    setChatComments((old) =>
      old.map((comment) => {
        if (data.parent_message) {
          let replies = [...comment.replies];
          replies = replies.map((child) => {
            if (child._id == data.comment_id) {
              if (data.action == "like") {
                child.like_count++;
              } else {
                child.like_count--;
              }
              if (data.member._id == userInfo._id) {
                child.is_liked = is_liked;
              }
            }
            return child;
          });
          comment = { ...comment, replies };
        } else {
          if (comment._id == data.comment_id) {
            if (data.action == "like") {
              comment.like_count++;
            } else {
              comment.like_count--;
            }
            if (data.member._id == userInfo._id) {
              comment.is_liked = is_liked;
            }
          }
        }
        return comment;
      })
    );
  };

  useEffect(() => {
    if (mainVideo._id) {
      handleRemoveAll();
      setBannerData(banner_data);
      mentorship_video_list();
      // event handling
      socket.emit("live_event_room_for_mentorship", mainVideo._id);
      socket.on("mentorship_video_message_receiver", (data) => {
        receive_new_live_message_by_socket(data);
      });
      socket.on("purchase_link_enabled_receiver", (data) => {
        purchase_link_enabled_receiver(data.data);
      });
      socket.on("mentorship_video_message_update_receiver", (data) => {
        mentorship_video_message_update_receiver(data);
      });
      socket.on("mentorship_video_message_delete_receiver", (data) => {
        mentorship_video_message_delete_receiver(data);
      });
      socket.on("mentorship_video_message_like_receiver", (data) => {
        mentorship_video_message_like_receiver(data);
      });
    }
    return () => {
      socket.off("mentorship_video_message_receiver");
      socket.off("mentorship_video_message_update_receiver");
      socket.off("mentorship_video_message_delete_receiver");
      socket.off("mentorship_video_message_like_receiver");
    };
  }, [mainVideo]);

  useEffect(() => {
    setBannerHeight(get_pinned_image_height());
  }, [bannerData]);

  return (
    <>
      <div className="col-12 col-lg-4 mt-4 mt-lg-0 mb-3">
        <Card
          style={{
            height: get_video_height() + 10,
          }}
          className={`live-comments`}
        >
          {bannerData.link_image &&
            bannerData.link &&
            !hide_banner &&
            bannerData.is_purchase_link && (
              <div id="pinned-image" className="pinned-comment pinned-image">
                <img
                  src={s3baseUrl + bannerData.link_image}
                  width="100%"
                  onClick={() =>
                    handle_change_page(bannerData.link, setIsLoadingPage)
                  }
                />
              </div>
            )}
          <>
            {isLoading ? (
              <div className="live-chat-loader-box">
                <CircularProgress
                  className="live-chat-loader"
                  color="primary"
                />
              </div>
            ) : (
              <>
                <div
                  className="live-comments-list"
                  onScroll={() => {
                    handleScrollListener(setNewChat, setShowScrollArrow);
                  }}
                  style={{
                    paddingBottom: 156,
                    height: get_video_height(),
                    paddingTop: bannerHeight,
                  }}
                >
                  {chatComments.map((chat) => {
                    let comment_is_self = Boolean(
                      chat.member._id == userInfo._id
                    );
                    return (
                      <div>
                        <div className="profile-comments inception p-2 mb-1 pb-1">
                          <div className="d-flex position-relative">
                            <div className="poster-name-image d-flex w-100">
                              <div
                                className="MuiAvatar-root MuiAvatar-circular css-1r9d7mk cursor-pointer"
                                style={{ width: 30, height: 30 }}
                                onClick={() =>
                                  handleViewProfile(chat?.member?._id)
                                }
                              >
                                <CustomAvatarBadgeColor
                                  src={s3baseUrl + chat.member.profile_image}
                                  alt="photoURL"
                                  colorCode={chat?.badge_info?.color_code}
                                  width={30}
                                  height={30}
                                />
                              </div>

                              <div className="w-100">
                                <div
                                  className="text-start set-title-width poster-name ps-1 w-100 cursor-pointer"
                                  onClick={() =>
                                    handleViewProfile(chat?.member?._id)
                                  }
                                >
                                  <span className="card-title mb-0 fs-12 fw-500">
                                    {chat.member.first_name +
                                      " " +
                                      chat.member.last_name}
                                  </span>
                                </div>
                                <div className="ps-1 pt-0 fw-400 live-event-comment-message">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: urlify(htmlDecode(chat?.message)),
                                    }}
                                    className="text-start mb-0"
                                  ></p>
                                  {chat.like_count > 0 && (
                                    <Tooltip title={`${chat.like_count} Likes`}>
                                      <div
                                        className="d-flex comment-like-heart mt-2"
                                        onClick={() => {
                                          handleOpenLikes(chat);
                                        }}
                                      >
                                        <div className="comment-like-heart-image">
                                          <img src={communityHeart} alt="" />
                                        </div>
                                        {chat.like_count > 0 && (
                                          <div className="comment-like-heart-count">
                                            {chat.like_count}
                                          </div>
                                        )}
                                      </div>
                                    </Tooltip>
                                  )}
                                </div>
                                <div className="col-12">
                                  {chat.file_url && (
                                    <a
                                      href={s3baseUrl + chat.file_url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="comment-view-attachment"
                                    >
                                      <span className="chat-image-preview mt-2">
                                        <img src={s3baseUrl + chat.file_url} />
                                      </span>
                                    </a>
                                  )}
                                </div>
                              </div>
                            </div>
                            {(comment_is_self || userInfo.is_executive) && (
                              <div className="affirmation-dots">
                                <CustomPopover
                                  menu={MENU_OPTIONS}
                                  data={chat}
                                />
                              </div>
                            )}
                          </div>
                          <div className="like-reply-inception-box pt-2 py-1 px-2">
                            <div
                              className="comment-reply action-box"
                              onClick={() => {
                                handleOpenReplyBox(chat);
                              }}
                            >
                              <ChatBubbleOutlineIcon /> Reply
                            </div>

                            <div
                              className="comment-like action-box"
                              onClick={() => {
                                handleLikeComment(chat);
                              }}
                            >
                              {chat.is_liked ? (
                                <>
                                  <img src={communityHeart} alt="" /> Liked
                                </>
                              ) : (
                                <>
                                  <FavoriteBorderIcon /> Like
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        {chat.replies?.length > 0 && (
                          <div
                            className="ps-3"
                            style={{ backgroundColor: "rgb(0, 0, 0)" }}
                          >
                            {chat.replies.map((child) => {
                              let child_is_self = Boolean(
                                child.member._id == userInfo._id
                              );
                              return (
                                <div className="profile-comments inception p-2 mb-1 pb-1">
                                  <div className="d-flex position-relative">
                                    <div className="poster-name-image d-flex w-100">
                                      <div
                                        className="MuiAvatar-root MuiAvatar-circular css-1r9d7mk cursor-pointer"
                                        onClick={() =>
                                          handleViewProfile(child?.member?._id)
                                        }
                                      >
                                        <CustomAvatarBadgeColor
                                          src={
                                            s3baseUrl +
                                            child.member.profile_image
                                          }
                                          alt="photoURL"
                                          colorCode={
                                            child?.badge_info?.color_code
                                          }
                                          width={30}
                                          height={30}
                                        />
                                      </div>
                                      <div className="w-100">
                                        <div
                                          className="text-start set-title-width poster-name ps-1 w-100 cursor-pointer"
                                          onClick={() =>
                                            handleViewProfile(
                                              child?.member?._id
                                            )
                                          }
                                        >
                                          <span className="card-title mb-0 fs-12 fw-500">
                                            {child.member.first_name +
                                              " " +
                                              child.member.last_name}
                                          </span>
                                        </div>
                                        <div className="ps-1 pt-0 fw-400 live-event-comment-message">
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: urlify(
                                                htmlDecode(child?.message)
                                              ),
                                            }}
                                            className="text-start mb-0"
                                          ></p>
                                          {child.like_count > 0 && (
                                            <Tooltip
                                              title={`${child.like_count} Likes`}
                                            >
                                              <div className="event-comment-like-box position-relative mt-3">
                                                <div
                                                  className="d-flex comment-like-heart mt-2"
                                                  onClick={() => {
                                                    handleOpenLikes(child);
                                                  }}
                                                >
                                                  <div className="comment-like-heart-image">
                                                    <img
                                                      src={communityHeart}
                                                      alt=""
                                                    />
                                                  </div>
                                                  {child.like_count > 0 && (
                                                    <div className="comment-like-heart-count">
                                                      {child.like_count}
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </Tooltip>
                                          )}
                                        </div>
                                        <div className="col-12">
                                          {child.file_url && (
                                            <a
                                              href={s3baseUrl + child.file_url}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="comment-view-attachment"
                                            >
                                              <span className="chat-image-preview mt-2">
                                                <img
                                                  src={
                                                    s3baseUrl + child.file_url
                                                  }
                                                />
                                              </span>
                                            </a>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    {(child_is_self ||
                                      userInfo.is_executive) && (
                                      <div className="affirmation-dots">
                                        <CustomPopover
                                          menu={MENU_OPTIONS}
                                          data={child}
                                        />
                                      </div>
                                    )}
                                  </div>
                                  <div className="like-reply-inception-box py-1 pt-2 px-2 mt-1">
                                    <div />
                                    <div
                                      className="comment-like action-box"
                                      onClick={() => {
                                        handleLikeComment(child, chat);
                                      }}
                                    >
                                      {child.is_liked ? (
                                        <>
                                          <img src={communityHeart} alt="" />{" "}
                                          Liked
                                        </>
                                      ) : (
                                        <>
                                          <FavoriteBorderIcon /> Like
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    );
                  })}
                  {showScrollArrow && !newChat && (
                    <motion.div
                      variants={bottom_in}
                      initial="hidden"
                      animate={"visible"}
                      exit="exit"
                      onClick={scroll_to_bottom}
                      className="new-chat-chip"
                    >
                      <span>Scroll to bottom</span>{" "}
                      <Icon icon="akar-icons:arrow-down"></Icon>
                    </motion.div>
                  )}
                </div>
              </>
            )}
            <div id="bottom-add-comment-id" className="bottom-add-comment">
              <form>
                <div className="add-event-comment">
                  <div className="add-event-comment-text-field">
                    <TextField
                      inputRef={inputRef}
                      // ref={inputRef}
                      // className="mt-2 inputs-fields inception-input"
                      className="mt-2 inception-input"
                      id="outlined-multiline-static"
                      label={
                        parentMessage ? "Write a reply…" : "Write a comment…"
                      }
                      multiline
                      rows={2}
                      name="description"
                      variant="outlined"
                      style={{ width: "100%" }}
                      required={true}
                      value={chatMessage}
                      onChange={(e) => setChatMessage(e.target.value)}
                    />
                    {(formType === "EDIT" || parentMessage) && (
                      <IconButton
                        className="comment-cancel-update"
                        onClick={handleRemoveAll}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="text-start mt-3 mb-2 event-chat-emoji-picker-button">
                      <img
                        src={emoji_picker_icon}
                        onClick={() => {
                          setShowEmojiPicker((prevState) => !prevState);
                        }}
                      />
                    </div>
                    <div className="d-flex justify-content-end">
                      {(previews || oldImage) && (
                        <span className="chat-image-preview mt-2 me-auto">
                          <span onClick={handleRemove}>x</span>
                          <img
                            src={previews ? previews : s3baseUrl + oldImage}
                          />
                        </span>
                      )}
                      <div className="mt-2 d-flex">
                        <span className="event-chat-upload-button mt-2">
                          <input
                            color="primary"
                            accept="image/*"
                            type="file"
                            id="event-chat-upload-button"
                            name="affirmationImage"
                            style={{ display: "none" }}
                            onChange={handleUpload}
                          />
                          <label htmlFor="event-chat-upload-button">
                            <img src={attchment_icon} alt="" srcSet="" />
                          </label>
                        </span>
                      </div>

                      {chatMessage ? (
                        <div
                          className="text-end mt-3 ms-1 mb-2 event-chat-send-button"
                          onClick={
                            !isSubmitting && formType === "ADD"
                              ? handleSendMessage
                              : handleMessageUpdate
                          }
                        >
                          <img src={send_chat_icon} alt="" srcSet="" />
                        </div>
                      ) : (
                        <Tooltip title="Write a comment">
                          <div className="text-end mt-3 ms-1 mb-2 event-chat-send-button">
                            <img src={send_chat_icon} alt="" srcSet="" />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </>
        </Card>
        {showEmojiPicker && (
          <div className="col-12  mt-4 mt-lg-0 ms-auto position-relative emoji-picker-box">
            <Picker
              data={data}
              onEmojiSelect={handleEmojiSelect}
              theme="dark"
              dynamicWidth={true}
            />
          </div>
        )}
      </div>

      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete live comment?"}
        handleAgree={handleDelete}
      />
      <ShowEventCommentLikes
        open={openLikesModal}
        setOpen={setOpenLikesModal}
        comment_id={selectedObject._id}
      />
    </>
  );
}
