import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import Pods from "./Pods";
import SearchIcon from "@mui/icons-material/Search";

function MainPods() {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const expire = window.location.pathname.includes("/pods/expire");
  const [query, setQuery] = useState("");

  const handleChangeSearch = (e) => {
    setQuery(e.target.value);
  };

  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setQuery("");
  };

  const handleTabClick = (e, row) => {
    if (row.tab_slug == "expire") {
      navigate(`/pods/expire`);
      setQuery("");
    } else {
      navigate(`/pods`);
      setQuery("");
    }
  };

  const TABS_OPTIONS = [
    {
      title: "Active Pods",
      tab_slug: "pending",
      onClick: handleTabClick,
      component: (
        <Pods
          list_type={"active"}
          query={query}
          setQuery={setQuery}
          tabTitle="Active Pods"
        />
      ),
    },
    {
      title: "EXPIRED PODS",
      tab_slug: "expire",
      onClick: handleTabClick,
      component: (
        <Pods
          list_type={"expired"}
          query={query}
          setQuery={setQuery}
          tabTitle="Active Pods"
        />
      ),
    },
  ];

  useEffect(() => {
    if (expire) setTabValue(1);
  }, [TABS_OPTIONS]);

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-lg-8 col-md-8 col-sm-12">
          <PageDescription parameter="rooms_description" else_title="Pods" />
        </div>
        <div className="col-lg-4 col-md- col-sm-12">
          <div>
            <FormControl variant="outlined" style={{ marginTop: "2px" }}>
              <InputLabel htmlFor="outlined-adornment-password">
                Search
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type="text"
                variant="small"
                value={query}
                onChange={handleChangeSearch}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label="Search"
              />
            </FormControl>
          </div>
        </div>
      </div>

      <div className="row section-space">
        <div className="col-12 section-space">
          <MUICustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}

export default MainPods;
