import { useEffect, useRef, useState } from "react";
import MissionFeedDetailNew from "./MissionFeedDetailNew";
import LeaderBoard from "../MissionConTrollNew/LeaderBoard";
import ApexChartDonat from "./ApexChart";
import { fShortenNumber, get_kmb_number } from "src/utils/formatNumber";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import AnalyticsChartHealth from "./AnalyticsChartHealth";
import AnalyticsChartrecreational from "./AnalyticsChartrecreational";
import AnalyticsChart from "./AnalyticsChart";
import ActionBarComponentProps from "../DynamiteDiary/calender";
import Missioncalender from "./Missioncalender";
import { achieve, cointlogo, MissionCoin, remaining } from "src/assets";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import LeaderBoardCoins from "../MissionConTrollNew/LeaderBoardCoins";
import { get_short_string, handle_change_page } from "src/utils/constants";
import CommunityStoriesTab from "src/components/MissionLevels/CommunityStoriesTab";
import ScheduleDetailActions from "./ScheduleDetailActions";
import SchadulesListDashBoard from "src/components/MissionLevels/SchadulesListDashBoard";
import { s3baseUrl } from "src/config/config";
import PieMissionChart from "./PieMissionChart";
import MissionsJourney from "../MissionConTrollNew/MissionsJourney";
import ReactApexChartDashboard from "./ReactApexChartDashboard";
import GroupedChart from "./GroupedChart";
import { ReactVideoPlayer } from "src/components";
import {
  AddAffirmationsApi,
  allowPermissionToUser,
} from "src/DAL/AffirmationCategories/Affirmations/Affirmations";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useFirebase } from "src/Hooks/FirebaseContaxt";
import { firebaseEventsObj } from "src/utils/firebase_utils";

const MainDashBoard = ({
  missionDashboaerd,
  missionDetail,
  missionLevel,
  loadMorePath,
  setLoadMorePath,
  streakLeaderBoard,
  setStreakLeaderBoard,
  coinLeaderBoard,
  setCoinLeaderBoard,
  loadMorePathCoin,
  setLoadMorePathCoin,
  missonSchedulesList,
  graphData,
  missionCommunity,
  missionSchedules,
  mcqData,
  graphStructure,
  BookACallSettings,
  upgradeButton,
  setUpgradeButton,
  UpGradeButtonSettings,
  userData,
  loadUpgradeButtonNew,
  setloadUpgradeButtonNew,
  mission_status,
  type,
  allowPermissionToShowContent,
  setallowPermissionToShowContent,
}) => {
  const [actionData, setActionData] = useState(
    missionDashboaerd?.current_mission_schedule
  );

  let mssionJourneyData = missonSchedulesList?.filter(
    (item) => item.content_settings?.show_in_mission_journey
  );

  const [leaderboardDatas, setLeaderboardData] = useState(
    missionDashboaerd?.streak_leader_board_stats
  );
  const { mission__id } = useParams();
  const { logOnFirebase } = useFirebase();
  const { enqueueSnackbar } = useSnackbar();
  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    let Data = {
      permission_to_view_content: !allowPermissionToShowContent,
      mission_id: mission__id,
    };
    const result = await allowPermissionToUser(Data);
    if (result.code === 200) {
      setallowPermissionToShowContent(!allowPermissionToShowContent);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  return (
    <>
      {mission_status == "completed" && (
        <div className="mt-2 mb-2 col-lg-12 text-center">
          {missionDetail?.content_settings?.mission_complete_video && (
            <ReactVideoPlayer
              url={missionDetail?.content_settings?.mission_complete_video}
            />
          )}
        </div>
      )}
      {mission_status == "incompleted" && (
        <div className="mt-2 mb-2 col-lg-12 text-center">
          {missionDetail?.content_settings
            ?.mission_complete_video_for_missed_schedule && (
            <ReactVideoPlayer
              url={
                missionDetail?.content_settings
                  ?.mission_complete_video_for_missed_schedule
              }
            />
          )}
        </div>
      )}
      {missionDetail.mission_status == "completed" &&
        missionDetail?.content_settings?.show_permission_checkbox &&
        missionDetail?.content_settings?.permission_checkbox_content && (
          <div className="mt-3 mb-2 col-lg-12" onClick={handleSubmit}>
            <div className={`p-2`}>
              <FormControlLabel
                value={"test"}
                className="answer"
                control={
                  <Checkbox
                    checked={allowPermissionToShowContent ? true : false}
                  />
                }
                label={
                  missionDetail?.content_settings?.permission_checkbox_content
                }
              />
            </div>
          </div>
        )}
      {missonSchedulesList.length > 0 && (
        <div className="mt-2 card pt-3 pb-3">
          <h3
            className="unlock-color mb-3 ms-3 challenge-heading"
            style={{ fontWeight: 500 }}
          >
            {missionDashboaerd?.mission?.content_settings?.schedule_heading}
          </h3>
          <SchadulesListDashBoard
            missionCommunity={missonSchedulesList}
            currentMissionSchedules={missionSchedules}
            type={type}
          />
        </div>
      )}
      <div className="row">
        <div className="col-lg-5 mt-2 pe-0">
          <div className="card d-flex streak-container ">
            <div className="d-flex justify-content-center align-items-centerx">
              <div>
                <div style={{ position: "relative", display: "inline-flex" }}>
                  {/* Background (Gray) CircularProgress */}
                  <CircularProgress
                    variant="determinate"
                    value={100}
                    size={110}
                    style={{
                      color: "#ffd88950",
                    }}
                  />
                  {/* Foreground (Blue) CircularProgress */}
                  <CircularProgress
                    variant="determinate"
                    value={
                      (missionDashboaerd?.started_days * 100) /
                      missionDashboaerd?.mission_duration
                    }
                    size={110}
                    style={{
                      color: "#ffd889",
                      position: "absolute",
                      left: 0,
                    }}
                  />
                  <div className="dashboard-progress-text">
                    <div className="dashboard-progress-text-title">
                      {`${(
                        (missionDashboaerd?.started_days * 100) /
                        missionDashboaerd?.mission_duration
                      ).toFixed(0)}`}
                      %
                    </div>
                  </div>
                </div>
              </div>

              <div className="ms-4">
                <p className=" mb-0">
                  <div className="d-flex">
                    <span style={{ fontSize: "22px" }}>
                      {" "}
                      <img src={achieve} style={{ width: "20px" }} />
                    </span>
                    <div style={{ marginLeft: "8px" }}>
                      <h4 style={{ marginBottom: "0px", fontSize: "15px" }}>
                        {missionDashboaerd?.started_days} Days
                      </h4>
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#ffd889",
                          marginTop: "3px",
                          marginBottom: "0px",
                        }}
                      >
                        Completed
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mt-3">
                    <span style={{ fontSize: "24px" }}>
                      <img src={remaining} style={{ width: "20px" }} />
                    </span>
                    <div style={{ marginLeft: "8px" }}>
                      <h4 style={{ marginBottom: "0px", fontSize: "15px" }}>
                        {missionDashboaerd?.remaining_days}{" "}
                        {missionDashboaerd?.remaining_days > 1 ? "Days" : "Day"}
                      </h4>
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#ffd889",
                          marginBottom: "0px",
                          marginTop: "3px",
                        }}
                      >
                        Remaining
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mt-3">
                    <span style={{ fontSize: "24px" }}>
                      <img src={MissionCoin} style={{ width: "20px" }} />
                    </span>
                    <div style={{ marginLeft: "8px" }}>
                      <h4 style={{ marginBottom: "0px", fontSize: "15px" }}>
                        {fShortenNumber(missionDashboaerd?.attracted_coins)}{" "}
                        coins attracted
                      </h4>
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#ffd889",
                          marginBottom: "0px",
                          marginTop: "3px",
                        }}
                      >
                        {fShortenNumber(
                          missionDashboaerd?.target_coins -
                            missionDashboaerd?.attracted_coins
                        )}{" "}
                        coins achievable
                      </p>
                    </div>
                  </div>
                </p>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <LeaderBoardCoins
              title={"Coins Leaderboard"}
              data={leaderboardDatas}
              streakLeaderBoard={coinLeaderBoard}
              setStreakLeaderBoard={setCoinLeaderBoard}
              loadMorePath={loadMorePathCoin}
              setLoadMorePath={setLoadMorePathCoin}
              type="mission"
              userData={userData}
            />
          </div>
        </div>

        <div className="col-lg-7 mt-2">
          <MissionsJourney title={"Mission Journey"} data={mssionJourneyData} />
        </div>

        {missionCommunity.length > 0 && (
          <div className="mt-3">
            <CommunityStoriesTab missionCommunity={missionCommunity} />
          </div>
        )}
        <div className="col-lg-12 pe-0 h-100">
          <div className="row">
            {graphStructure.length > 0 && (
              <div className="col-lg-12 mt-2">
                <div className="card ps-2 pt-3">
                  <span className="challenge-heading unlock-color">
                    Questions Report
                  </span>
                  {/* <ReactApexChartDashboard graphData={graphData} /> */}
                  <GroupedChart graphStructure={graphStructure} />
                </div>
              </div>
            )}
            {mcqData.length > 0 &&
              mcqData.map((item, index) => {
                const isLastItem = index === mcqData.length - 1;
                const isOddNumber = mcqData.length % 2 !== 0;
                return (
                  <div
                    key={index}
                    className={`${
                      isLastItem && isOddNumber
                        ? "col-lg-12"
                        : mcqData.length > 1
                        ? "col-lg-6"
                        : "col-lg-12"
                    } mt-2 ${index % 2 === 0 && !isLastItem ? "pe-0" : "pe-2"}`}
                  >
                    <div className="card d-flex flex-column h-100">
                      <div className="ps-2 pt-3">
                        <Tooltip
                          title={
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.question_statement,
                              }}
                            />
                          }
                        >
                          <div className="d-flex align-items-baseline">
                            <span
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#ffd889",
                              }}
                            >
                              Q .
                            </span>
                            <h4
                              className="ps-1"
                              style={{ fontSize: "14px", fontWeight: "200" }}
                            >
                              {
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: get_short_string(
                                      item.question_statement,
                                      50
                                    ),
                                  }}
                                ></div>
                              }
                            </h4>
                          </div>
                        </Tooltip>
                      </div>
                      <div className="flex-grow-1 d-flex align-items-center justify-content-center">
                        <PieMissionChart item={item} />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="col-lg-4 hide-in-mobile mt-2">
          {/* {missionDetail?.mission_road_map_level?.badges?.length > 0 && (
            <div className="card mb-2">
              <h3
                className="unlock-color mt-3 ms-2 mb-1 challenge-heading"
                style={{ fontWeight: 500, fontSize: "16px" }}
              >
                Mission Rewards
              </h3>
              <p className="ms-2 mb-2 me-2" style={{ fontSize: "12px" }}>
                {missionDetail.achieveable_rewards_text}
              </p>

              <div className="challenge-video-card mb-2">
                <div className="d-flex align-items-center level-icons mb-2  ms-2 overflow-x-auto">
                  {missionDetail.mission_road_map_level.badges.map(
                    (item, index) => {
                      return (
                        <>
                          {item?.icon?.thumbnail_1 && (
                            <>
                              <Tooltip
                                title={
                                  item.title + " " + item.no_of_badges + "x"
                                }
                              >
                                <img
                                  src={s3baseUrl + item?.icon?.thumbnail_1}
                                  alt=""
                                  style={{ width: "35px", height: "35px" }}
                                />
                              </Tooltip>
                            </>
                          )}
                        </>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          )} */}

          {/* <LeaderBoard
            title={"Mission Leader Board"}
            data={leaderboardDatas}
            streakLeaderBoard={streakLeaderBoard}
            setStreakLeaderBoard={setStreakLeaderBoard}
            loadMorePath={loadMorePath}
            setLoadMorePath={setLoadMorePath}
            type="mission"
          /> */}
        </div>
      </div>{" "}
      <div className="row">
        <div className="col-lg-8 pe-0">
          <div className="row"></div>
        </div>
        <div className="col-lg-4 mt-2"></div>
      </div>
      <div className="col-12 position-relative">
        <div className="fix-button-position">
          {BookACallSettings?.show_book_call_button &&
            BookACallSettings?.book_call_button_url &&
            BookACallSettings.book_call_button_text && (
              <button
                className="mc-button-contained  me-2"
                onClick={() => {
                  logOnFirebase({
                    event: firebaseEventsObj.missionDashboardUpgradeBtn,
                    replacementArr: [["{mission_title}", missionDetail.title]],
                  });
                  handle_change_page(
                    BookACallSettings?.book_call_button_url,
                    setUpgradeButton
                  );
                }}
              >
                {upgradeButton
                  ? "Please Wait..."
                  : BookACallSettings.book_call_button_text}
              </button>
            )}
          {UpGradeButtonSettings?.show_membership_button &&
            UpGradeButtonSettings?.upgrade_button_url &&
            UpGradeButtonSettings.upgrade_button_text && (
              <button
                className="mc-button-contained "
                onClick={() => {
                  logOnFirebase({
                    event: firebaseEventsObj.missionBookCallDashbordBtn,
                    replacementArr: [["{mission_title}", missionDetail.title]],
                  });
                  handle_change_page(
                    UpGradeButtonSettings?.upgrade_button_url,
                    setloadUpgradeButtonNew
                  );
                }}
              >
                {loadUpgradeButtonNew
                  ? "Please Wait..."
                  : UpGradeButtonSettings?.upgrade_button_text}
              </button>
            )}
        </div>
      </div>
    </>
  );
};

export default MainDashBoard;
