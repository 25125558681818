import React from "react";
import { useState } from "react";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import MainPart from "./MainPart";
import VideoList from "./MobileComponents/VideoList";
import Suggestions from "./Suggestions";
import TwitterFeeds from "./TwitterFeeds";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import CurrentPods from "./CurrentPods";
import { last_section_img } from "src/assets";
import AboutSection from "./AboutSection";
import YourRewards from "./MobileComponents/YourRewards";

export default function MobilesTabs({
  showInfoBox,
  setShowInfoBox,
  descriptions,
  feeds_type,
  event_id,
  program_id,
  maximumLikeCount,
  bonusCount,
  feedSetting,
  handleSlideLeft,
  handleSlideRight,
  clientDashboardSetting,
  featureVideo,
  handleChangePage,
  seriesVideos,
  upcomingEvents,
  currenEvents,
  winFeedsList,
  rewardList,
  userType,
  handleChangeUserType,
  userRoadmap,
  livePods,
  startingSoonPods,
  communityData,
  setCommunityData,
  setWinFeedsList,
  firebaseInfo,
}) {
  const [tabValue, setTabValue] = useState(0);
  const { userInfo, roadMapLevels } = useContentSetting();

  let default_level = roadMapLevels.find((level) => level.is_default == true);
  if (default_level) {
    userRoadmap = default_level.alias_title;
  }

  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const TABS_OPTIONS = [
    {
      title: "Feeds",
      component: (
        <>
          <MainPart
            userInfo={userInfo}
            feeds_type={feeds_type}
            event_id={event_id}
            program_id={program_id}
            feedSetting={feedSetting}
            userType={userType}
            userRoadmap={userRoadmap}
            winFeedsList={winFeedsList}
            communityData={communityData}
            setCommunityData={setCommunityData}
            setWinFeedsList={setWinFeedsList}
            firebaseInfo={firebaseInfo}
          />
        </>
      ),
    },
    {
      title: "Wealth Wallet",
      component: (
        <AboutSection
          setShowInfoBox={setShowInfoBox}
          showInfoBox={showInfoBox}
          userInfo={userInfo}
          maximumLikeCount={maximumLikeCount}
          bonusCount={bonusCount}
          descriptions={descriptions}
          feedSetting={feedSetting}
        />
      ),
    },
    // {
    //   title: "Your Rewards",
    //   component: (
    //     <YourRewards
    //       handleSlideLeft={handleSlideLeft}
    //       handleSlideRight={handleSlideRight}
    //       rewardList={rewardList}
    //       clientDashboardSetting={clientDashboardSetting}
    //     />
    //   ),
    // },
    // {
    //   title: "Videos",
    //   component: (
    //     <>
    //       <VideoList
    //         seriesVideos={seriesVideos}
    //         featureVideo={featureVideo}
    //         handleChangePage={handleChangePage}
    //       />
    //     </>
    //   ),
    // },
    {
      title: "Events",
      component: (
        <TwitterFeeds
          upcomingEvents={upcomingEvents}
          currenEvents={currenEvents}
          userInfo={userInfo}
          feeds_type={feeds_type}
        />
      ),
    },
    {
      title: "Pods",
      component: (
        <>
          <div className="mt-3">
            <CurrentPods
              feeds_type={feeds_type}
              livePods={livePods}
              startingSoonPods={startingSoonPods}
            />
            <img className="mt-3" src={last_section_img}></img>
          </div>
        </>
      ),
    },
  ];

  return (
    <MUICustomTabs
      data={TABS_OPTIONS}
      value={tabValue}
      handleChange={handleChange}
      className="mt-4"
    />
  );
}
