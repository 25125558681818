import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import AssessmentResultBox from "src/components/AttitudeAssessment/AssessmentResultBox";
import AttitudeAssessmentFiller from "src/components/AttitudeAssessment/AttitudeAssessmentFiller";
import { cointlogo } from "src/assets";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { useFirebase } from "src/Hooks/FirebaseContaxt";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function AssessmentResults() {
  const [isLoading, setIsLoading] = useState(true);
  const { contentSettingData } = useContentSetting();
  const navigate = useNavigate();
  const { logPageOnFirebase } = useFirebase();
  const classes = useStyles();
  const { state } = useLocation();

  useEffect(() => {
    if (!state) {
      navigate(`/assessment`);
    } else {
      logPageOnFirebase({ event: "attitudeAssessmentResult" });
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="assessment-wrapper">
      <div className="container">
        <div className="text-start pt-4 assessment-wrapper-description">
          <div className="row">
            <div className="col-6">
              <div
                className="dashboard_description"
                dangerouslySetInnerHTML={{
                  __html: contentSettingData.attitude_assessment_description,
                }}
              ></div>
            </div>

            <div className="col-6">
              <div className="d-flex text-align vision-div align-items-center">
                <img className="coint-img img-fluid" src={cointlogo}></img>
                <h4>
                  Total Coins Attracted: {state.attitudeAssessmentCoinsCount}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center pt-5">
          <AttitudeAssessmentFiller
            thoughtsTotalMarks={state.thoughtsTotalMarks}
            thoughtsGainedMarks={state.thoughtsGainedMarks}
            feelingsTotalMarks={state.feelingsTotalMarks}
            feelingsGainedMarks={state.feelingsGainedMarks}
            actionsTotalMarks={state.actionsTotalMarks}
            actionsGainedMarks={state.actionsGainedMarks}
          />
          {state.active_result == "slot_1" ? (
            <AssessmentResultBox
              get_scores={state.get_scores}
              total_scores={state.total_scores}
              resultsData={state.assessmentSetting.slot_1_info}
              percentage_color={
                state.assessmentSetting.general_info.slot_1_percentage_color
              }
              get_coins={state.assessmentSetting.general_info.slot_1_coins}
              state={state}
            />
          ) : state.active_result == "slot_2" ? (
            <AssessmentResultBox
              get_scores={state.get_scores}
              total_scores={state.total_scores}
              resultsData={state.assessmentSetting.slot_2_info}
              percentage_color={
                state.assessmentSetting.general_info.slot_2_percentage_color
              }
              get_coins={state.assessmentSetting.general_info.slot_2_coins}
              state={state}
            />
          ) : (
            <AssessmentResultBox
              get_scores={state.get_scores}
              total_scores={state.total_scores}
              resultsData={state.assessmentSetting.slot_3_info}
              percentage_color={
                state.assessmentSetting.general_info.slot_3_percentage_color
              }
              get_coins={state.assessmentSetting.general_info.slot_3_coins}
              state={state}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default AssessmentResults;
