import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
// material
import { styled } from "@mui/material/styles";
import { Stack, Container, Typography } from "@mui/material";
import { login } from "../DAL/Login/Login";

// components
import Page from "../components/Page";
import { MHidden } from "../components/@material-extend";
import { LoginForm } from "../components/authentication/login";
import { portalUrl, s3baseUrl } from "../config/config";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import {
  get_page_content_localStorage,
  _get_is_seen_video_localStorage,
  get_project_info_localStorage,
} from "src/DAL/localStorage/localStorage";
import { htmlDecode } from "src/utils/convertHtml";
import { decryptToken, encryptToken } from "src/utils/constants";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login({ login_by_email }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { handleUserInfo, handleConsultantInfo, START_SOCKET_FORCEFULLY } =
    useContentSetting();

  const content_data = get_page_content_localStorage();
  const general_setting = get_project_info_localStorage();

  const handleSubmitLogin = async (data) => {
    setLoading(true);
    localStorage.removeItem(`UpgradePopCount`);
    const result = await login(data);
    if (result.code === 200) {
      localStorage.setItem(`user_data`, JSON.stringify(result.member));
      localStorage.setItem("token", encryptToken(result.token));
      if (result.member.consultant) {
        localStorage.setItem(
          `consultant_data`,
          JSON.stringify(result.member.consultant)
        );
        handleConsultantInfo(result.member.consultant);
      } else {
        localStorage.setItem(`consultant_data`, JSON.stringify({}));
      }
      START_SOCKET_FORCEFULLY(result.member._id);
      handleUserInfo(result.member);
      let redirect_path = "/mission-control";
      let move_path = general_setting?.move_after_login_by_email;
      if (login_by_email && move_path) {
        redirect_path = move_path.replace(portalUrl, "/");
      }
      navigate(redirect_path, { replace: true });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoading(false);
    }
  };

  return (
    <div
      className="whole-background"
      style={{
        backgroundImage: `url(${s3baseUrl + content_data?.login_page_image})`,
      }}
    >
      <RootStyle>
        <div className="row w-100">
          <div className="col-12 col-lg-6"></div>
          <div className="col-12 col-lg-6">
            <Container maxWidth="sm">
              <ContentStyle>
                <Stack sx={{ mb: 5 }}>
                  <Typography variant="h4" gutterBottom>
                    {/* {project_name} Client Portal */}
                    {htmlDecode(content_data.login_page_description)}
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    Enter your details below.
                  </Typography>
                </Stack>
                {/* <AuthSocial /> */}

                <LoginForm
                  isLoading={loading}
                  onhandleLogin={handleSubmitLogin}
                  login_by_email={login_by_email}
                />

                <MHidden width="smUp">
                  <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                    Don’t have an account?&nbsp;
                  </Typography>
                </MHidden>
              </ContentStyle>
            </Container>
          </div>
        </div>
      </RootStyle>
    </div>
  );
}
