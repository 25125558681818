import * as React from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { CircularProgress, Divider } from "@mui/material";
import { useEffect } from "react";
import {
  get_notification_setting,
  notification_setting,
} from "src/DAL/Profile/Profile";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import NotificationItem from "src/components/NotificationSetting/NotificationItem";
import DailyDynamiteNotification from "src/components/NotificationSetting/DailyDynamiteNotification";
import MeditationNotification from "src/components/NotificationSetting/MeditationNotification";
import { api_time_format } from "src/utils/constants";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const reminderInit = {
  meditation_reminder_time: "2018-01-01 00:00 am",
  daily_dynamite_reminder_time: "2018-01-01 00:00 am",
  daily_dynamite_reminder_time_evening: "2018-01-01 00:00 am",
};

const notificationsInit = {
  feed_notifications: true,
  support_notifications: false,
  chat_notifications: true,
  daily_dynamite_notification: false,
  meditation_notification: true,
  gratitude_notification: true,
  calendar_notification: true,
};

export default function NotificationsSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [notificationArray, setNotificationArray] = useState([]);
  const { notificationSettings, handleNotifications } = useContentSetting();
  const [reminderTimes, setReminderTimes] = useState(reminderInit);
  const [inputs, setInputs] = useState(notificationsInit);

  const getNotificationSetting = async () => {
    setIsLoading(true);
    const result = await get_notification_setting();
    if (result.code == 200) {
      if (notificationSettings) {
        setInputs(notificationSettings);
      }
      setReminderTimes((prev) => {
        return {
          ...prev,
          daily_dynamite_reminder_time: `2018-01-01 ${result?.data?.daily_dynamite_reminder_time}`,
          daily_dynamite_reminder_time_evening: `2018-01-01 ${result?.data?.daily_dynamite_reminder_time_evening}`,
          meditation_reminder_time: `2018-01-01 ${result?.data?.meditation_reminder_time}`,
        };
      });
      setNotificationArray(result.notification_setting);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);

    let postData = {
      notification_settings: inputs,
      meditation_reminder_time: api_time_format(
        reminderTimes.meditation_reminder_time
      ),
      daily_dynamite_reminder_time_evening: api_time_format(
        reminderTimes.daily_dynamite_reminder_time_evening
      ),
      daily_dynamite_reminder_time: api_time_format(
        reminderTimes.daily_dynamite_reminder_time
      ),
    };

    const result = await notification_setting(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
      handleNotifications(inputs);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  useEffect(() => {
    getNotificationSetting();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex ">
        <div className="col-6">
          <h2>Notification Settings</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div
          className="row track notifications-setting"
          style={{
            backgroundColor: "#1d1c1d",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          {notificationArray.map((notification) => {
            const { notification_for } = notification;
            const notificationData = {
              notification,
              inputs,
              setInputs,
              reminderTimes,
              setReminderTimes,
            };

            return (
              <>
                <NotificationItem {...notificationData} />
                {notification_for === "daily_dynamite_notification" && (
                  <DailyDynamiteNotification {...notificationData} />
                )}
                {notification_for === "meditation_notification" && (
                  <MeditationNotification {...notificationData} />
                )}
                <Divider />
              </>
            );
          })}
        </div>
        <div className="text-end mt-4">
          <button className="small-contained-button">
            {isLoadingForm ? "Updating" : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
