import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import { s3baseUrl } from "src/config/config";
import { mission_levels_list_api_v1 } from "src/DAL/MissionLevels/MissionLevels";
import { htmlDecode } from "src/utils/convertHtml";
import LockIcon from "@mui/icons-material/Lock";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function MissionLevels() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [missionLevels, setMissionLevels] = useState([]);

  const get_mission_levels = async () => {
    const result = await mission_levels_list_api_v1();
    if (result.code === 200) {
      setMissionLevels(result.level_badges);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleDetail = (object) => {
    navigate(`/mission-levels/level-details-new/${object._id}`);
  };

  useEffect(() => {
    get_mission_levels();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container mission-levels mt-3">
      <div className="mission-level-heading">
        <PageDescription
          parameter="mission_levels_description"
          else_title="Mission Levels"
        />
      </div>
      <div className="row">
        <div className="col-12 mb-3">
          <div className="challenge-video-card">
            <div className="row">
              <div className="col-12">
                <div className="d-flex align-items-center level-icons mb-3"></div>
              </div>
              {missionLevels.map((mission) => {
                let note_id = `note_${mission._id}`;
                return (
                  <div
                    className="col-12 col-md-6 col-lg-4 mb-3"
                    key={mission._id}
                  >
                    <div
                      className="card mission-level-card"
                      onClick={() => handleDetail(mission)}
                    >
                      {/* <div className="mission-tagline">{mission.title}</div> */}
                      {mission.badge_locked && (
                        <div className="lock-icon-journey-main-container ">
                          <span className="lock-icon-journey-main">
                            <LockIcon
                              style={{ fontSize: "22px", color: "black" }}
                            />
                          </span>
                        </div>
                      )}
                      <img
                        alt={mission.tagline}
                        src={s3baseUrl + mission.image.thumbnail_1}
                      />
                      <div className="p-3 pb-0">
                        <div className="d-flex">
                          <h3>{htmlDecode(mission.tagline)} </h3>
                          <span>
                            <img
                              style={{ width: "20px", display: "inline-block" }}
                              src={s3baseUrl + mission.icon.thumbnail_1}
                              className="ms-2"
                            />
                          </span>
                        </div>
                        {mission.short_description && (
                          <p className="mui-custom-card-description">
                            {htmlDecode(mission.short_description)}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
