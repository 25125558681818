import React, { useEffect, useRef, useState } from "react";
import { Box, Tooltip } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { htmlDecode, urlify } from "src/utils/convertHtml";
import {
  count_chars,
  FEED_TXET_LIMIT,
  post_description,
  replaceUserNamesWithHTML,
} from "src/utils/constants";
import { FeedComments, VideoStream } from "..";
import { get_app_logo, s3baseUrl } from "src/config/config";
import UserInfoCard from "../LikesPopup/UserInfoCard";
import { styled } from "@mui/material/styles";
import ActionsCount from "../LikesPopup/ActionsCount";
import HandleAction from "../LikesPopup/HandleAction";
import LiveMeeting from "../../../Live-Stream/LiveMeeting";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: "100%",
  position: "relative",
  padding: theme.spacing(1, 1, 0),
  textAlign: "end",
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(2, 2, 0),
    position: "absolute",
  },
}));

let max_height_iterations = 10;
let height_iterations = 0;

export default function VideoStreaming(props) {
  const {
    feedDetail,
    feedSetting,
    topLikedUser,
    likeCount,
    gratitudeCount,
    coinsCount,
    topGratitudeUser,
    isGratituded,
    isLiked,
    handleFeedAction,
    handleOpenGratitudeBox,
    handleOpenLikedBox,
    is_detail_page,
    handleCloseDetailBox,
    liveStream,
    setLiveStream,
    firebaseInfo,
  } = props;

  const [isShowMore, setIsShowMore] = useState(true);
  const logo = get_app_logo();
  const userInfoCard = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { userInfo } = useContentSetting();

  const handle_card_height = () => {
    height_iterations++;
    setTimeout(() => {
      let user_info_card = document.querySelector("#user-info-card");
      let comment_wrapper = document.querySelector("#main-comment-wrapper");
      let add_comment = document.querySelector("#add-comment-box");
      if (user_info_card && comment_wrapper && add_comment) {
        let user_info_height = user_info_card.offsetHeight;
        let add_comment_height = add_comment.offsetHeight;
        comment_wrapper.style.height = `calc(85vh - ${
          user_info_height + add_comment_height
        }px - 40px)`;
        comment_wrapper.style.overflow = "auto";
      } else if (height_iterations < max_height_iterations) {
        handle_card_height();
      }
    }, 300);
  };

  useEffect(() => {
    if (userInfoCard.current) {
      handle_card_height();
    }
  }, [userInfoCard]);

  const streamData = {
    is_detail_page,
    handleCloseDetailBox,
    feedDetail,
    liveStream,
    isFullScreen,
    setLiveStream,
    setIsFullScreen,
    joinType: "participant",
    user_type: "member_user",
    userInfo,
    module_name: "feed",
    onLeaveMeeting: () => {
      handleCloseDetailBox();
    },
  };

  return (
    <>
      <HeaderStyle>
        <RouterLink to="/">
          <Box
            component="img"
            className="live-stream-logo"
            src={logo}
            sx={{ width: 200, margin: "auto" }}
          />
        </RouterLink>
      </HeaderStyle>
      <div
        className={`container-fluid ${is_detail_page ? "p-3" : "p-3 p-lg-5"}`}
      >
        <div className="row">
          <div className="col-12">
            <h2>Live Stream</h2>
          </div>
        </div>
        <div className="row">
          <div className={`col-12 col-lg-8`}>
            {liveStream?.stream_start_time && (
              <>
                {feedDetail.feed_type === "meeting" ? (
                  <LiveMeeting {...streamData} />
                ) : (
                  <VideoStream {...streamData} />
                )}
              </>
            )}
          </div>
          <div className="col-12 col-lg-4">
            <div className="live-stream-comments">
              <div id="user-info-card" ref={userInfoCard}>
                <div className="d-flex w-100 justify-content-between">
                  <UserInfoCard
                    profile_image={
                      s3baseUrl + feedDetail?.action_info?.profile_image
                    }
                    user_name={htmlDecode(feedDetail?.action_info?.name)}
                    avatar_char={feedDetail?.action_info?.name[0]}
                    date={feedDetail?.createdAt}
                    activity_type={null}
                    badge_level_info={feedDetail.badge_level_info}
                  />

                  <div className="poster-logo text-end">
                    <span className="d-flex feed-level">
                      {feedDetail.badge_level_info && (
                        <Tooltip title={feedDetail.badge_level_info.title}>
                          <img
                            src={
                              s3baseUrl +
                              feedDetail.badge_level_info.icon?.thumbnail_1
                            }
                            className="dynamite-level-image"
                          />
                        </Tooltip>
                      )}
                    </span>
                  </div>
                </div>
                {feedDetail.description && (
                  <div className="mt-2 post-description">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: urlify(
                          count_chars(feedDetail.description) >
                            FEED_TXET_LIMIT && isShowMore
                            ? post_description(
                                replaceUserNamesWithHTML(
                                  feedDetail.description,
                                  feedDetail.mentioned_users
                                ),
                                FEED_TXET_LIMIT
                              )
                            : replaceUserNamesWithHTML(
                                feedDetail.description,
                                feedDetail.mentioned_users
                              )
                        ),
                      }}
                    ></p>
                    {count_chars(feedDetail.description) > FEED_TXET_LIMIT && (
                      <span
                        className="ms-2"
                        onClick={() => {
                          setIsShowMore(!isShowMore);
                        }}
                      >
                        {isShowMore ? "See More" : "See Less"}
                      </span>
                    )}
                  </div>
                )}
                <ActionsCount
                  handleOpenLikedBox={handleOpenLikedBox}
                  likeCount={likeCount}
                  topLikedUser={topLikedUser}
                  handleOpenGratitudeBox={handleOpenGratitudeBox}
                  gratitudeCount={gratitudeCount}
                  topGratitudeUser={topGratitudeUser}
                  coinsCount={coinsCount}
                  feeds_type="general"
                />
                <hr />
                <HandleAction
                  isLiked={isLiked}
                  handleFeedAction={handleFeedAction}
                  feeds_type="general"
                  setShowAddComment={() => {}}
                  isGratituded={isGratituded}
                  feed_id={feedDetail._id}
                />
                <hr />
              </div>
              <div>
                <FeedComments
                  feed_id={feedDetail._id}
                  feed={feedDetail}
                  getFeedsListingOnAddFeed={() => {}}
                  setTotalCommentCount={() => {}}
                  feedSetting={feedSetting}
                  is_stream_page={true}
                  firebaseInfo={firebaseInfo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
