import { TextareaAutosize } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { report_post_api } from "src/DAL/Community/Community";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { firebaseEventsObj } from "src/utils/firebase_utils";
import { useFirebase } from "src/Hooks/FirebaseContaxt";

export default function ReportPost({
  feed,
  setFeedsList,
  setOpenReoprt,
  onReport,
  firebaseInfo,
}) {
  const [inputs, setInputs] = useState({ report_reason: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState("1");
  const { logOnFirebase } = useFirebase();

  const handleChangeRadios = (event) => {
    setSelectedValue(event.target.value);
  };
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const postData = {
      report_reason:
        selectedValue == "Other" ? inputs.report_reason : selectedValue,
      feed_id: feed._id,
    };
    const result = await report_post_api(postData);
    if (result.code === 200) {
      let firebase_event = "communityPostReport";
      logOnFirebase({
        event: firebaseEventsObj[firebase_event],
        obj: firebaseInfo.info,
      });

      setFeedsList((old) => old.filter((f) => f._id !== feed._id));
      setIsLoading(false);
      setOpenReoprt(false);
      onReport();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  let arr = [
    "I just don't like it",
    "Bullying or unwanted contact",
    "Suicide, self-injury or eating disorders",
    "Violence, hate or exploitation",
    "Selling or promoting restricted items",
    "Nudity or sexual activity",
    "Scam, fraud or spam",
    "False information",
    "Other",
  ];

  return (
    <>
      <div className="container">
        <form className="row" onSubmit={handleSubmit}>
          <div className="col-12">
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={selectedValue}
                name="radio-buttons-group"
                onChange={handleChangeRadios}
              >
                {arr.map((item, index) => {
                  return (
                    <>
                      <FormControlLabel
                        value={item}
                        control={<Radio size="small" />}
                        label={item}
                      />
                    </>
                  );
                })}
              </RadioGroup>
            </FormControl>
            {selectedValue == "Other" && (
              <TextareaAutosize
                aria-label="empty textarea"
                placeholder="Report Reason *"
                className="text-color mt-2"
                sx={{ color: "white" }}
                minRows={4}
                required
                name="report_reason"
                value={inputs.report_reason}
                onChange={handleChange}
                style={{
                  width: "100%",
                  borderRadius: "8px",
                  background: "#141717",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "15px",
                  color: "white",
                  fontSize: "15px",
                  border: "2px solid rgba(71, 66, 34, 0.3)",
                }}
              />
            )}
          </div>

          <div class="col-12 mt-3">
            <button class="small-contained-button w-100">
              {isLoading ? "Reporting..." : "Report"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
