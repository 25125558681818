import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
// material
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import LockIcon from "@mui/icons-material/Lock";

import { _list_of_member_for_chat_against_event } from "src/DAL/LiveEvents/LiveEvents";
import { s3baseUrl } from "src/config/config";
import CustomMUICard from "src/components/GeneralComponents/CustomMUICard";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import { Shop_Catagory_list } from "src/DAL/Shop/ShopApi";
import SearchIcon from "@mui/icons-material/Search";

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

// ----------------------------------------------------------------------

export default function ShopCatagory() {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [eventsList, setEventsList] = useState({});
  const [query, setQuery] = useState("");
  const handleChangeSearch = (e) => {
    setQuery(e.target.value);
    localStorage.setItem("shopCatagaory", e.target.value);
  };
  const getFilteredData = (query) => {
    let dataToFilter = [...eventsList];
    if (query) {
      let resultFilter = dataToFilter.filter((post) =>
        post.title.toLowerCase().includes(query.toLowerCase())
      );
      return resultFilter;
    } else {
      return dataToFilter;
    }
  };
  const handleClick = (object) => {
    delete object.card_button;
    navigate(`/shop/product/${object._id}`);
  };
  const getLiveEventsList = async () => {
    const result = await Shop_Catagory_list();
    if (result.code === 200) {
      let CARD_OPTIONS = [];
      result.categories.map((dynamite_event) => {
        CARD_OPTIONS.push({
          ...dynamite_event,
          card_title: dynamite_event.title,
          card_short_description: dynamite_event.short_description,
          card_image: s3baseUrl + dynamite_event.image.thumbnail_2,
          card_classes: "col-lg-4 col-md-6 col-sm-12 mt-4",
        });
      });
      setEventsList(CARD_OPTIONS);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    let shopCatagaory = localStorage.getItem("shopCatagaory");
    if (shopCatagaory) {
      setQuery(shopCatagaory);
    }
    getLiveEventsList();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-8">
            <h2>Categories</h2>
          </div>
          <div className="col-4">
            <div>
              <FormControl variant="outlined" style={{ marginTop: "2px" }}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Search
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type="text"
                  variant="small"
                  value={query}
                  onChange={handleChangeSearch}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Search"
                />
              </FormControl>
            </div>
          </div>
        </div>
        {getFilteredData(query).length > 0 ? (
          <CustomMUICard
            data={getFilteredData(query)}
            handleClick={handleClick}
          />
        ) : (
          <RecordNotFound title="Categories Not Found" />
        )}
      </div>
    </>
  );
}
