import React, { useEffect, useState } from "react";
import { useFirebase } from "src/Hooks/FirebaseContaxt";

import { handle_change_page } from "src/utils/constants";
import { firebaseEventsObj } from "src/utils/firebase_utils";

function BookCallPopUp({
  membershipUpgradePopupSettings,
  handleCLloseUpGradePopup,
}) {
  const [upgradeButton, setUpgradeButton] = useState(false);
  const { logOnFirebase } = useFirebase();

  useEffect(() => {
    logOnFirebase({ event: firebaseEventsObj.onBookCallModalShown });
  }, []);

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: membershipUpgradePopupSettings.description,
        }}
        className="mt-3 mb-2"
      ></div>
      {membershipUpgradePopupSettings &&
        membershipUpgradePopupSettings.show_book_call_button &&
        membershipUpgradePopupSettings?.book_call_button_url &&
        membershipUpgradePopupSettings?.book_call_button_text && (
          <div className="col-12 mb-3 text-center">
            <button
              className="mc-button-contained"
              onClick={() => {
                logOnFirebase({
                  event: firebaseEventsObj.onBookCallModalButtonClick,
                });
                handle_change_page(
                  membershipUpgradePopupSettings?.book_call_button_url,
                  setUpgradeButton
                );
                handleCLloseUpGradePopup();
              }}
            >
              {upgradeButton
                ? "Please Wait..."
                : membershipUpgradePopupSettings?.book_call_button_text}
            </button>
          </div>
        )}
    </>
  );
}

export default BookCallPopUp;
